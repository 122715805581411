export const timings = {
    "/GMV/GMV-01-GEN.html": {
        "ch1":[],
        "ch2":[],
        "ch3":[],
        "ch4":[],
        "ch5":[],
        "ch6":[],
        "ch7":[],
        "ch8":[],
        "ch9":[],
        "ch10":[],
        "ch11":[],
        "ch12":[],
        "ch13":[],
        "ch14":[],
        "ch15":[],
        "ch16":[],
        "ch17":[],
        "ch18":[],
        "ch19":[],
        "ch20":[],
        "ch21":[],
        "ch22":[],
        "ch23":[],
        "ch24":[],
        "ch25":[],
        "ch26":[],
        "ch27":[],
        "ch28":[],
        "ch29":[],
        "ch30":[],
        "ch31":[],
        "ch32":[],
        "ch33":[],
        "ch34":[],
        "ch35":[],
        "ch36":[],
        "ch37":[],
        "ch38":[],
        "ch39":[],
        "ch40":[],
        "ch41":[],
        "ch42":[],
        "ch43":[],
        "ch44":[],
        "ch45":[],
        "ch46":[],
        "ch47":[],
        "ch48":[],
        "ch49":[],
        "ch50":[]
    },
    "/GMV/GMV-08-RUT.html" : { 
        "ch1":[
            { label: "s1", start: 5.2, end: 8.03 },
            { label: "1", start: 8.28, end: 36.05 },
            { label: "2a", start: 36.3, end: 46.79 },
            { label: "2b", start: 47.04, end: 60.19 },
            { label: "3", start: 60.44, end: 69.93 },
            { label: "4a", start: 70.18, end: 79.27 },
            { label: "4b", start: 79.52, end: 82.01 },
            { label: "5", start: 82.26, end: 89.27 },
            { label: "s2", start: 89.52, end: 96.45 },
            { label: "6", start: 96.7, end: 111.49 },
            { label: "7", start: 111.74, end: 121.55 },
            { label: "8a", start: 121.8, end: 125.89 },
            { label: "8b", start: 126.14, end: 129.23 },
            { label: "8c", start: 129.48, end: 136.85 },
            { label: "9a", start: 137.1, end: 149.79 },
            { label: "9b", start: 150.04, end: 154.65 },
            { label: "9c", start: 154.9, end: 158.75 },
            { label: "10a", start: 159, end: 159.93 },
            { label: "10b", start: 160.18, end: 164.17 },
            { label: "11a", start: 164.42, end: 167.07 },
            { label: "11b", start: 167.32, end: 171.77 },
            { label: "11c", start: 172.02, end: 174.83 },
            { label: "11d", start: 175.08, end: 181.99 },
            { label: "12a", start: 182.24, end: 185.95 },
            { label: "12b", start: 186.2, end: 189.59 },
            { label: "12c", start: 189.84, end: 195.99 },
            { label: "13a", start: 196.24, end: 201.17 },
            { label: "13b", start: 201.42, end: 212.23 },
            { label: "14", start: 212.48, end: 225.99 },
            { label: "15a", start: 226.24, end: 228.81 },
            { label: "15b", start: 229.06, end: 235.95 },
            { label: "15c", start: 236.2, end: 238.71 },
            { label: "16a", start: 238.96, end: 241.77 },
            { label: "16b", start: 242.02, end: 245.39 },
            { label: "16c", start: 245.64, end: 248.53 },
            { label: "16d", start: 248.78, end: 251.79 },
            { label: "16e", start: 252.04, end: 255.49 },
            { label: "17a", start: 255.74, end: 260.13 },
            { label: "17b", start: 260.38, end: 265.57 },
            { label: "18", start: 265.82, end: 276.49 },
            { label: "s3", start: 276.74, end: 282.83 },
            { label: "19a", start: 283.08, end: 290.69 },
            { label: "19b", start: 290.94, end: 299.87 },
            { label: "19c", start: 300.12, end: 303.47 },
            { label: "20a", start: 303.72, end: 306.57 },
            { label: "20b", start: 306.82, end: 309.07 },
            { label: "20c", start: 309.32, end: 315.03 },
            { label: "21a", start: 315.28, end: 321.43 },
            { label: "21b", start: 321.68, end: 324.23 },
            { label: "21c", start: 324.48, end: 335.23 },
            { label: "22a", start: 335.48, end: 344.31 },
            { label: "22b", start: 344.56, end: 352.51 }
        ],
        "ch2": [
            { label: "s1", start: 3.6, end: 8.49 },
            { label: "1", start: 8.74, end: 18.91 },
            { label: "2a", start: 19.16, end: 22.89 },
            { label: "2b", start: 23.14, end: 34.51 },
            { label: "2c", start: 34.76, end: 36.97 },
            { label: "2d", start: 37.22, end: 39.47 },
            { label: "3a", start: 39.72, end: 47.45 },
            { label: "3b", start: 47.7, end: 52.37 },
            { label: "4a", start: 52.62, end: 61.95 },
            { label: "4b", start: 62.2, end: 63.83 },
            { label: "4c", start: 64.08, end: 66.65 },
            { label: "4d", start: 66.9, end: 69.83 },
            { label: "5a", start: 70.08, end: 72.65 },
            { label: "5b", start: 72.9, end: 76.77 },
            { label: "6a", start: 77.02, end: 79.45 },
            { label: "6b", start: 79.7, end: 85.73 },
            { label: "7a", start: 85.98, end: 88.75 },
            { label: "7b", start: 89, end: 96.47 },
            { label: "7c", start: 96.72, end: 106.29 },
            { label: "8a", start: 106.54, end: 110.51 },
            { label: "8b", start: 110.76, end: 114.69 },
            { label: "8c", start: 114.94, end: 118.69 },
            { label: "8d", start: 118.94, end: 125.17 },
            { label: "9a", start: 125.42, end: 132.81 },
            { label: "9b", start: 133.06, end: 137.37 },
            { label: "9c", start: 137.62, end: 143.43 },
            { label: "10a", start: 143.68, end: 150.23 },
            { label: "10b", start: 150.48, end: 155.27 },
            { label: "11a", start: 155.52, end: 157.29 },
            { label: "11b", start: 157.54, end: 173.55 },
            { label: "12a", start: 173.8, end: 177.79 },
            { label: "12b", start: 178.04, end: 187.77 },
            { label: "13a", start: 188.02, end: 191.47 },
            { label: "13b", start: 191.72, end: 196.07 },
            { label: "13c", start: 196.32, end: 198.97 },
            { label: "13d", start: 199.22, end: 204.03 },
            { label: "14a", start: 204.28, end: 209.59 },
            { label: "14b", start: 209.84, end: 215.27 },
            { label: "14c", start: 215.52, end: 221.57 },
            { label: "14d", start: 221.82, end: 226.57 },
            { label: "15a", start: 226.82, end: 230.51 },
            { label: "15b", start: 230.76, end: 234.31 },
            { label: "15c", start: 234.56, end: 242.91 },
            { label: "15d", start: 243.16, end: 245.21 },
            { label: "16a", start: 245.46, end: 254.37 },
            { label: "16b", start: 254.62, end: 257.29 },
            { label: "17a", start: 257.54, end: 264.07 },
            { label: "17b", start: 264.32, end: 271.13 },
            { label: "18a", start: 271.38, end: 274.65 },
            { label: "18b", start: 274.9, end: 285.95 },
            { label: "19a", start: 286.2, end: 289.55 },
            { label: "19b", start: 289.8, end: 293.81 },
            { label: "19c", start: 294.06, end: 294.77 },
            { label: "19d", start: 295.02, end: 299.51 },
            { label: "19e", start: 299.76, end: 307.99 },
            { label: "19f", start: 308.24, end: 310.05 },
            { label: "19g", start: 310.3, end: 316.11 },
            { label: "20a", start: 316.36, end: 320.07 },
            { label: "20b", start: 320.32, end: 326.41 },
            { label: "20c", start: 326.66, end: 333.07 },
            { label: "21a", start: 333.32, end: 336.99 },
            { label: "21b", start: 337.24, end: 339.99 },
            { label: "21c", start: 340.24, end: 345.55 },
            { label: "22a", start: 345.8, end: 349.13 },
            { label: "22b", start: 349.38, end: 360.95 },
            { label: "23", start: 361.2, end: 376.19 }
        ],
        "ch3": [
            { label: "s1", start: 3.6, end: 7.35 },
            { label: "1a", start: 7.6, end: 12.97 },
            { label: "1b", start: 13.22, end: 20.43 },
            { label: "2a", start: 20.68, end: 28.03 },
            { label: "2b", start: 28.28, end: 33.73 },
            { label: "3a", start: 33.98, end: 40.97 },
            { label: "3b", start: 41.22, end: 45.61 },
            { label: "4a", start: 45.86, end: 49.23 },
            { label: "4b", start: 49.48, end: 53.13 },
            { label: "4c", start: 53.38, end: 59.83 },
            { label: "4d", start: 60.08, end: 63.53 },
            { label: "5a", start: 63.78, end: 66.21 },
            { label: "5b", start: 66.46, end: 69.39 },
            { label: "6", start: 69.64, end: 77.31 },
            { label: "7a", start: 77.56, end: 86.11 },
            { label: "7b", start: 86.36, end: 97.81 },
            { label: "8", start: 98.06, end: 107.65 },
            { label: "9a", start: 107.9, end: 109.09 },
            { label: "9b", start: 109.34, end: 111.07 },
            { label: "9c", start: 111.32, end: 114.09 },
            { label: "9d", start: 114.34, end: 116.43 },
            { label: "9e", start: 116.68, end: 123.73 },
            { label: "9f", start: 123.98, end: 129.95 },
            { label: "10a", start: 130.2, end: 132.59 },
            { label: "10b", start: 132.84, end: 135.91 },
            { label: "10c", start: 136.16, end: 148.67 },
            { label: "11a", start: 148.92, end: 151.07 },
            { label: "11b", start: 151.32, end: 154.27 },
            { label: "11c", start: 154.52, end: 160.13 },
            { label: "12", start: 160.38, end: 168.93 },
            { label: "13a", start: 169.18, end: 171.83 },
            { label: "13b", start: 172.08, end: 184.35 },
            { label: "13c", start: 184.6, end: 188.95 },
            { label: "14a", start: 189.2, end: 193.77 },
            { label: "14b", start: 194.02, end: 199.87 },
            { label: "14c", start: 200.12, end: 202.29 },
            { label: "14d", start: 202.54, end: 206.15 },
            { label: "15a", start: 206.4, end: 208.17 },
            { label: "15b", start: 208.42, end: 210.83 },
            { label: "15c", start: 211.08, end: 214.55 },
            { label: "15d", start: 214.8, end: 221.49 },
            { label: "15e", start: 221.74, end: 224.05 },
            { label: "16a", start: 224.3, end: 229.45 },
            { label: "16b", start: 229.7, end: 232.87 },
            { label: "16c", start: 233.12, end: 238.87 },
            { label: "17a", start: 239.12, end: 240.23 },
            { label: "17b", start: 240.48, end: 245.11 },
            { label: "17c", start: 245.36, end: 248.07 },
            { label: "18a", start: 248.32, end: 250.49 },
            { label: "18b", start: 250.74, end: 264.15 }
        ],
        "ch4": [
            { label: "s1", start: 3.4, end: 7.47 },
            { label: "1a", start: 7.72, end: 20.41 },
            { label: "1b", start: 20.66, end: 23.87 },
            { label: "1c", start: 24.12, end: 27.15 },
            { label: "1d", start: 27.4, end: 29.09 },
            { label: "2a", start: 29.34, end: 35.27 },
            { label: "2b", start: 35.52, end: 39.51 },
            { label: "3a", start: 39.76, end: 43.23 },
            { label: "3b", start: 43.48, end: 47.49 },
            { label: "3c", start: 47.74, end: 53.55 },
            { label: "4a", start: 53.8, end: 70.55 },
            { label: "4b", start: 70.8, end: 78.37 },
            { label: "4c", start: 78.62, end: 83.99 },
            { label: "4d", start: 84.24, end: 86.55 },
            { label: "4e", start: 86.8, end: 91.31 },
            { label: "5a", start: 91.56, end: 93.91 },
            { label: "5b", start: 94.16, end: 107.35 },
            { label: "6a", start: 107.6, end: 110.93 },
            { label: "6b", start: 111.18, end: 118.25 },
            { label: "6c", start: 118.5, end: 122.67 },
            { label: "6d", start: 122.92, end: 125.75 },
            { label: "7a", start: 126, end: 141.29 },
            { label: "7b", start: 141.54, end: 149.23 },
            { label: "8a", start: 149.48, end: 154.13 },
            { label: "8b", start: 154.38, end: 156.27 },
            { label: "8c", start: 156.52, end: 159.49 },
            { label: "9a", start: 159.74, end: 166.33 },
            { label: "9b", start: 166.58, end: 174.63 },
            { label: "10a", start: 174.88, end: 191.05 },
            { label: "10b", start: 191.3, end: 195.05 },
            { label: "11a", start: 195.3, end: 203.03 },
            { label: "11b", start: 203.28, end: 206.33 },
            { label: "11c", start: 206.58, end: 218.09 },
            { label: "11d", start: 218.34, end: 223.57 },
            { label: "12", start: 223.82, end: 233.97 },
            { label: "s2", start: 234.22, end: 238.69 },
            { label: "13", start: 238.94, end: 250.51 },
            { label: "14a", start: 250.76, end: 256.49 },
            { label: "14b", start: 256.74, end: 261.39 },
            { label: "14c", start: 261.64, end: 268.67 },
            { label: "15", start: 268.92, end: 282.17 },
            { label: "16", start: 282.42, end: 291.07 },
            { label: "17a", start: 291.32, end: 294.27 },
            { label: "17b", start: 294.52, end: 297.19 },
            { label: "17c", start: 297.44, end: 301.23 },
            { label: "17d", start: 301.48, end: 307.35 },
            { label: "18a", start: 307.6, end: 311.91 },
            { label: "18b", start: 312.16, end: 315.27 },
            { label: "19a", start: 315.52, end: 318.67 },
            { label: "19b", start: 318.92, end: 322.03 },
            { label: "20a", start: 322.28, end: 326.93 },
            { label: "20b", start: 327.18, end: 330.33 },
            { label: "21a", start: 330.58, end: 333.69 },
            { label: "21b", start: 333.94, end: 336.85 },
            { label: "22a", start: 337.1, end: 340.27 },
            { label: "22b", start: 340.52, end: 342.95 }
        ],
    },
    "/GMV/GMV-17-EST.html" : {
        "ch1":[],
        "ch2":[],
        "ch3":[],
        "ch4":[],
        "ch5":[],
        "ch6":[],
        "ch7":[],
        "ch8":[],
        "ch9":[],
        "ch10":[],
    },
    "/GMV/GMV-32-JON.html" : {
        "ch1":[
            { label: "1", start: 0, end: 9.33 },
            { label: "2", start: 9.58, end: 20.57 },
            { label: "3a", start: 20.82, end: 28.87 },
            { label: "3b", start: 29.12, end: 34.95 },
            { label: "3c", start: 35.2, end: 46.37 },
            { label: "4", start: 46.62, end: 55.67 },
            { label: "5a", start: 55.92, end: 63.25 },
            { label: "5b", start: 63.5, end: 69.95 },
            { label: "5c", start: 70.2, end: 77.59 },
            { label: "6a", start: 77.84, end: 80.89 },
            { label: "6b", start: 81.14, end: 83.25 },
            { label: "6c", start: 83.5, end: 91.15 },
            { label: "7a", start: 91.4, end: 94.21 },
            { label: "7b", start: 94.46, end: 100.19 },
            { label: "7c", start: 100.44, end: 105.17 },
            { label: "8a", start: 105.42, end: 108.59 },
            { label: "8b", start: 108.84, end: 111.85 },
            { label: "8c", start: 112.1, end: 113.45 },
            { label: "8d", start: 113.7, end: 115.21 },
            { label: "8e", start: 115.46, end: 118.71 },
            { label: "9a", start: 118.96, end: 120.47 },
            { label: "9b", start: 120.72, end: 129.87 },
            { label: "10a", start: 130.12, end: 133.99 },
            { label: "10b", start: 134.24, end: 136.39 },
            { label: "10c", start: 136.64, end: 143.57 },
            { label: "11a", start: 143.82, end: 148.37 },
            { label: "11b", start: 148.62, end: 151.93 },
            { label: "11c", start: 152.18, end: 155.89 },
            { label: "12a", start: 156.14, end: 157.69 },
            { label: "12b", start: 157.94, end: 169.07 },
            { label: "13", start: 169.32, end: 179.73 },
            { label: "14a", start: 179.98, end: 183.21 },
            { label: "14b", start: 183.46, end: 193.39 },
            { label: "14c", start: 193.64, end: 196.01 },
            { label: "15", start: 196.26, end: 206.69 },
            { label: "16", start: 206.94, end: 216.13 },
            { label: "17", start: 216.38, end: 228.27 }
        ],
        "ch2":[
            { label: "1", start: 0, end: 7.21 },
            { label: "2a", start: 7.46, end: 8.79 },
            { label: "2b", start: 9.04, end: 14.97 },
            { label: "2c", start: 15.22, end: 22.79 },
            { label: "3a", start: 23.04, end: 25.67 },
            { label: "3b", start: 25.92, end: 34.65 },
            { label: "4a", start: 34.9, end: 36.59 },
            { label: "4b", start: 36.84, end: 39.07 },
            { label: "4c", start: 39.32, end: 44.17 },
            { label: "5", start: 44.42, end: 55.59 },
            { label: "6a", start: 55.84, end: 64.17 },
            { label: "6b", start: 64.42, end: 71.93 },
            { label: "7a", start: 72.18, end: 76.15 },
            { label: "7b", start: 76.4, end: 82.09 },
            { label: "8", start: 82.34, end: 89.07 },
            { label: "9a", start: 89.32, end: 96.95 },
            { label: "9b", start: 97.2, end: 100.59 },
            { label: "10", start: 100.84, end: 108.15 }
        ],
        "ch3":[
            { label: "1", start: 0, end: 3.77 },
            { label: "2", start: 4.02, end: 13.41 },
            { label: "3a", start: 13.66, end: 18.55 },
            { label: "3b", start: 18.8, end: 26.77 },
            { label: "4a", start: 27.02, end: 31.43 },
            { label: "4b", start: 31.68, end: 33.91 },
            { label: "4c", start: 34.16, end: 38.49 },
            { label: "5a", start: 38.74, end: 44.91 },
            { label: "5b", start: 45.16, end: 49.07 },
            { label: "6", start: 49.32, end: 60.43 },
            { label: "7a", start: 60.68, end: 64.87 },
            { label: "7b", start: 65.12, end: 76.73 },
            { label: "8", start: 76.98, end: 86.03 },
            { label: "9", start: 86.28, end: 94.51 },
            { label: "10", start: 94.76, end: 108.19 }
        ],
        "ch4":[
            { label: "1", start: 0, end: 5.53 },
            { label: "2a", start: 5.78, end: 8.79 },
            { label: "2b", start: 9.04, end: 14.19 },
            { label: "2c", start: 14.44, end: 18.93 },
            { label: "2d", start: 19.18, end: 29.77 },
            { label: "3", start: 30.02, end: 37.09 },
            { label: "4a", start: 37.34, end: 40.41 },
            { label: "4b", start: 40.66, end: 42.89 },
            { label: "5", start: 43.14, end: 57.65 },
            { label: "6a", start: 57.9, end: 69.73 },
            { label: "6b", start: 69.98, end: 72.97 },
            { label: "7a", start: 73.22, end: 80.77 },
            { label: "7b", start: 81.02, end: 87.07 },
            { label: "8a", start: 87.32, end: 102.81 },
            { label: "8b", start: 103.06, end: 104.69 },
            { label: "8c", start: 104.94, end: 108.23 },
            { label: "9a", start: 108.48, end: 110.75 },
            { label: "9b", start: 111, end: 117.05 },
            { label: "9c", start: 117.3, end: 118.61 },
            { label: "9d", start: 118.86, end: 123.39 },
            { label: "10a", start: 123.64, end: 125.53 },
            { label: "10b", start: 125.78, end: 132.05 },
            { label: "10c", start: 132.3, end: 137.39 },
            { label: "11a", start: 137.64, end: 141.63 },
            { label: "11b", start: 141.88, end: 157.39 }
        ],
    },
    "/GMV/GMV-41-MAT.html": {
        "ch1":[
            { label: "s1", start: 7.6, end: 11.05 },
            { label: "1", start: 11.3, end: 19.59 },
            { label: "2a", start: 19.84, end: 25.05 },
            { label: "2b", start: 25.3, end: 29.43 },
            { label: "3a", start: 29.68, end: 33.35 },
            { label: "3b", start: 33.6, end: 36.75 },
            { label: "3c", start: 37, end: 41.33 },
            { label: "4a", start: 41.58, end: 46.09 },
            { label: "4b", start: 46.34, end: 47.07 },
            { label: "5a", start: 47.32, end: 56.15 },
            { label: "5b", start: 56.4, end: 61.65 },
            { label: "5c", start: 61.9, end: 64.25 },
            { label: "6a", start: 64.5, end: 66.67 },
            { label: "6b", start: 66.92, end: 69.75 },
            { label: "6c", start: 70, end: 73.93 },
            { label: "7a", start: 74.18, end: 79.19 },
            { label: "7b", start: 79.44, end: 81.77 },
            { label: "8a", start: 82.02, end: 87.19 },
            { label: "8b", start: 87.44, end: 90.01 },
            { label: "9a", start: 90.26, end: 94.95 },
            { label: "9b", start: 95.2, end: 97.97 },
            { label: "10a", start: 98.22, end: 103.09 },
            { label: "10b", start: 103.34, end: 106.03 },
            { label: "11a", start: 106.28, end: 110.55 },
            { label: "11b", start: 110.8, end: 115.47 },
            { label: "12a", start: 115.72, end: 119.17 },
            { label: "12b", start: 119.42, end: 122.43 },
            { label: "12c", start: 122.68, end: 125.83 },
            { label: "13a", start: 126.08, end: 131.45 },
            { label: "13b", start: 131.7, end: 133.63 },
            { label: "14a", start: 133.88, end: 138.73 },
            { label: "14b", start: 138.98, end: 141.67 },
            { label: "15a", start: 141.92, end: 146.05 },
            { label: "15b", start: 146.3, end: 149.05 },
            { label: "16a", start: 149.3, end: 151.53 },
            { label: "16b", start: 151.78, end: 153.83 },
            { label: "16c", start: 154.08, end: 156.43 },
            { label: "16d", start: 156.68, end: 159.63 },
            { label: "17", start: 159.88, end: 175.33 },
            { label: "s2", start: 175.58, end: 180.19 },
            { label: "18a", start: 180.44, end: 185.49 },
            { label: "18b", start: 185.74, end: 196.11 },
            { label: "19", start: 196.36, end: 208.23 },
            { label: "20a", start: 208.48, end: 217.13 },
            { label: "20b", start: 217.38, end: 231.11 },
            { label: "21", start: 231.36, end: 244.05 },
            { label: "22", start: 244.3, end: 252.77 },
            { label: "23a", start: 253.02, end: 257.97 },
            { label: "23b", start: 258.22, end: 261.87 },
            { label: "23c", start: 262.12, end: 265.49 },
            { label: "24", start: 265.74, end: 277.07 },
            { label: "25", start: 277.32, end: 286.31 }
        ],
        "ch2":[
            { label: "s1", start: 3.2, end: 7.49 },
            { label: "1a", start: 7.74, end: 14.73 },
            { label: "1b", start: 14.98, end: 19.89 },
            { label: "2a", start: 20.14, end: 21.49 },
            { label: "2b", start: 21.74, end: 24.73 },
            { label: "2c", start: 24.98, end: 29.99 },
            { label: "3", start: 30.24, end: 38.45 },
            { label: "4a", start: 38.7, end: 45.67 },
            { label: "4b", start: 45.92, end: 48.71 },
            { label: "5a", start: 48.96, end: 50.43 },
            { label: "5b", start: 50.68, end: 56.35 },
            { label: "6a", start: 56.6, end: 60.73 },
            { label: "6b", start: 60.98, end: 65.55 },
            { label: "6c", start: 65.8, end: 69.07 },
            { label: "6d", start: 69.32, end: 73.37 },
            { label: "7", start: 73.62, end: 83.27 },
            { label: "8a", start: 83.52, end: 88.59 },
            { label: "8b", start: 88.84, end: 93.41 },
            { label: "8c", start: 93.66, end: 100.53 },
            { label: "9", start: 100.78, end: 116.89 },
            { label: "10", start: 117.14, end: 122.97 },
            { label: "11a", start: 123.22, end: 132.79 },
            { label: "11b", start: 133.04, end: 141.11 },
            { label: "12", start: 141.36, end: 153.21 },
            { label: "s2", start: 153.46, end: 159.87 },
            { label: "13a", start: 160.12, end: 168.09 },
            { label: "13b", start: 168.34, end: 178.55 },
            { label: "14", start: 178.8, end: 186.19 },
            { label: "15a", start: 186.44, end: 189.85 },
            { label: "15b", start: 190.1, end: 199.81 },
            { label: "16", start: 200.06, end: 221.57 },
            { label: "17", start: 221.82, end: 228.67 },
            { label: "18a", start: 228.92, end: 231.93 },
            { label: "18b", start: 232.18, end: 235.25 },
            { label: "18c", start: 235.5, end: 237.87 },
            { label: "18d", start: 238.12, end: 239.87 },
            { label: "18e", start: 240.12, end: 243.17 },
            { label: "s3", start: 243.42, end: 250.01 },
            { label: "19", start: 250.26, end: 257.17 },
            { label: "20a", start: 257.42, end: 259.25 },
            { label: "20b", start: 259.5, end: 268.29 },
            { label: "21", start: 268.54, end: 274.49 },
            { label: "22", start: 274.74, end: 288.83 },
            { label: "23a", start: 289.08, end: 293.35 },
            { label: "23b", start: 293.6, end: 299.05 },
            { label: "23c", start: 299.3, end: 302.43 }
        ],
        "ch3":[
            { label: "s1", start: 3.68, end: 7.55 },
            { label: "1", start: 7.8, end: 15.63 },
            { label: "2a", start: 15.88, end: 16.93 },
            { label: "2b", start: 17.18, end: 22.67 },
            { label: "3a", start: 22.92, end: 27.17 },
            { label: "3b", start: 27.42, end: 30.77 },
            { label: "3c", start: 31.02, end: 33.21 },
            { label: "3d", start: 33.46, end: 36.25 },
            { label: "4", start: 36.5, end: 45.73 },
            { label: "5", start: 45.98, end: 54.21 },
            { label: "6", start: 54.46, end: 61.79 },
            { label: "7a", start: 62.04, end: 71.63 },
            { label: "7b", start: 71.88, end: 73.57 },
            { label: "7c", start: 73.82, end: 78.85 },
            { label: "8", start: 79.1, end: 83.97 },
            { label: "9a", start: 84.22, end: 86.85 },
            { label: "9b", start: 87.1, end: 96.05 },
            { label: "10a", start: 96.3, end: 99.81 },
            { label: "10b", start: 100.06, end: 105.73 },
            { label: "11a", start: 105.98, end: 110.61 },
            { label: "11b", start: 110.86, end: 115.45 },
            { label: "11c", start: 115.7, end: 119.31 },
            { label: "11d", start: 119.56, end: 123.59 },
            { label: "12a", start: 123.84, end: 131.13 },
            { label: "12b", start: 131.38, end: 138.73 },
            { label: "s2", start: 138.98, end: 142.21 },
            { label: "13", start: 142.46, end: 149.89 },
            { label: "14a", start: 150.14, end: 155.47 },
            { label: "14b", start: 155.72, end: 158.81 },
            { label: "14c", start: 159.06, end: 161.31 },
            { label: "15a", start: 161.56, end: 163.61 },
            { label: "15b", start: 163.86, end: 171.15 },
            { label: "15c", start: 171.4, end: 174.81 },
            { label: "16", start: 175.06, end: 190.03 },
            { label: "17a", start: 190.28, end: 193.71 },
            { label: "17b", start: 193.96, end: 198.79 }
        ],
        "ch4":[
            { label: "s1", start: 3.8, end: 6.55 },
            { label: "1", start: 6.8, end: 14.07 },
            { label: "2", start: 14.32, end: 22.17 },
            { label: "3a", start: 22.42, end: 26.33 },
            { label: "3b", start: 26.58, end: 30.75 },
            { label: "4a", start: 31, end: 33.13 },
            { label: "4b", start: 33.38, end: 36.77 },
            { label: "4c", start: 37.02, end: 40.43 },
            { label: "4d", start: 40.68, end: 44.03 },
            { label: "5", start: 44.28, end: 50.55 },
            { label: "6a", start: 50.8, end: 51.85 },
            { label: "6b", start: 52.1, end: 59.39 },
            { label: "6c", start: 59.64, end: 69.55 },
            { label: "7a", start: 69.8, end: 72.31 },
            { label: "7b", start: 72.56, end: 80.97 },
            { label: "8", start: 81.22, end: 92.79 },
            { label: "9a", start: 93.04, end: 94.51 },
            { label: "9b", start: 94.76, end: 100.37 },
            { label: "10a", start: 100.62, end: 103.03 },
            { label: "10b", start: 103.28, end: 105.37 },
            { label: "10c", start: 105.62, end: 107.37 },
            { label: "10d", start: 107.62, end: 110.69 },
            { label: "10e", start: 110.94, end: 112.83 },
            { label: "11", start: 113.08, end: 120.85 },
            { label: "s2", start: 121.1, end: 126.19 },
            { label: "12", start: 126.44, end: 133.83 },
            { label: "13", start: 134.08, end: 147.37 },
            { label: "14", start: 147.62, end: 154.39 },
            { label: "15a", start: 154.64, end: 157.13 },
            { label: "15b", start: 157.38, end: 162.69 },
            { label: "15c", start: 162.94, end: 166.19 },
            { label: "16a", start: 166.44, end: 168.69 },
            { label: "16b", start: 168.94, end: 172.53 },
            { label: "16c", start: 172.78, end: 178.63 },
            { label: "17a", start: 178.88, end: 182.81 },
            { label: "17b", start: 183.06, end: 188.03 },
            { label: "s3", start: 188.28, end: 192.07 },
            { label: "18a", start: 192.32, end: 204.47 },
            { label: "18b", start: 204.72, end: 211.01 },
            { label: "19a", start: 211.26, end: 214.33 },
            { label: "19b", start: 214.58, end: 219.03 },
            { label: "20", start: 219.28, end: 224.89 },
            { label: "21a", start: 225.14, end: 237.83 },
            { label: "21b", start: 238.08, end: 240.91 },
            { label: "22", start: 241.16, end: 248.39 },
            { label: "s4", start: 248.64, end: 252.47 },
            { label: "23", start: 252.72, end: 269.69 },
            { label: "24", start: 269.94, end: 286.51 },
            { label: "25", start: 286.76, end: 300.47 }
        ],
        "ch5":[
            { label: "s1", start: 3.4, end: 7.53 },
            { label: "1", start: 7.78, end: 18.07 },
            { label: "2", start: 18.32, end: 22.21 },
            { label: "3a", start: 22.46, end: 27.91 },
            { label: "3b", start: 28.16, end: 31.91 },
            { label: "4a", start: 32.16, end: 35.25 },
            { label: "4b", start: 35.5, end: 38.37 },
            { label: "5a", start: 38.62, end: 41.01 },
            { label: "5b", start: 41.26, end: 43.93 },
            { label: "6a", start: 44.18, end: 48.39 },
            { label: "6b", start: 48.64, end: 50.65 },
            { label: "7a", start: 50.9, end: 54.43 },
            { label: "7b", start: 54.68, end: 58.21 },
            { label: "8a", start: 58.46, end: 61.31 },
            { label: "8b", start: 61.56, end: 65.21 },
            { label: "9a", start: 65.46, end: 68.29 },
            { label: "9b", start: 68.54, end: 71.53 },
            { label: "10a", start: 71.78, end: 77.29 },
            { label: "10b", start: 77.54, end: 81.05 },
            { label: "11", start: 81.3, end: 90.89 },
            { label: "12", start: 91.14, end: 103.81 },
            { label: "s2", start: 104.06, end: 108.53 },
            { label: "13a", start: 108.78, end: 112.47 },
            { label: "13b", start: 112.72, end: 117.35 },
            { label: "13c", start: 117.6, end: 124.59 },
            { label: "14a", start: 124.84, end: 127.47 },
            { label: "14b", start: 127.72, end: 132.37 },
            { label: "15", start: 132.62, end: 143.37 },
            { label: "16", start: 143.62, end: 153.71 },
            { label: "s3", start: 153.96, end: 160.15 },
            { label: "17a", start: 160.4, end: 167.59 },
            { label: "17b", start: 167.84, end: 172.55 },
            { label: "18", start: 172.8, end: 185.09 },
            { label: "19a", start: 185.34, end: 198.23 },
            { label: "19b", start: 198.48, end: 209.97 },
            { label: "20", start: 210.22, end: 219.61 },
            { label: "s4", start: 219.86, end: 222.55 },
            { label: "21a", start: 222.8, end: 226.49 },
            { label: "21b", start: 226.74, end: 232.71 },
            { label: "22a", start: 232.96, end: 239.15 },
            { label: "22b", start: 239.4, end: 248.93 },
            { label: "23", start: 249.18, end: 257.09 },
            { label: "24a", start: 257.34, end: 260.41 },
            { label: "24b", start: 260.66, end: 267.13 },
            { label: "25", start: 267.38, end: 281.39 },
            { label: "26", start: 281.64, end: 288.63 },
            { label: "s5", start: 288.88, end: 291.29 },
            { label: "27", start: 291.54, end: 294.87 },
            { label: "28", start: 295.12, end: 303.31 },
            { label: "29", start: 303.56, end: 316.37 },
            { label: "30", start: 316.62, end: 328.25 },
            { label: "s6", start: 328.5, end: 332.95 },
            { label: "31", start: 333.2, end: 338.71 },
            { label: "32", start: 338.96, end: 353.49 },
            { label: "s7", start: 353.74, end: 356.51 },
            { label: "33a", start: 356.76, end: 360.75 },
            { label: "33b", start: 361, end: 369.67 },
            { label: "34", start: 369.92, end: 375.77 },
            { label: "35", start: 376.02, end: 384.75 },
            { label: "36", start: 385, end: 392.19 },
            { label: "37", start: 392.44, end: 401.11 },
            { label: "s8", start: 401.36, end: 404.17 },
            { label: "38a", start: 404.42, end: 406.69 },
            { label: "38b", start: 406.94, end: 411.33 },
            { label: "39a", start: 411.58, end: 416.23 },
            { label: "39b", start: 416.48, end: 422.79 },
            { label: "40", start: 423.04, end: 430.57 },
            { label: "41", start: 430.82, end: 438.73 },
            { label: "42", start: 438.98, end: 449.17 },
            { label: "s9", start: 449.42, end: 453.99 },
            { label: "43a", start: 454.24, end: 456.75 },
            { label: "43b", start: 457, end: 462.53 },
            { label: "44", start: 462.78, end: 472.27 },
            { label: "45", start: 472.52, end: 489.39 },
            { label: "46a", start: 489.64, end: 496.23 },
            { label: "46b", start: 496.48, end: 499.01 },
            { label: "47a", start: 499.26, end: 504.91 },
            { label: "47b", start: 505.16, end: 509.31 },
            { label: "48", start: 509.56, end: 516.75 }
        ],
        "ch6":[
            { label: "s1", start: 3.48, end: 5.33 },
            { label: "1a", start: 5.58, end: 12.29 },
            { label: "1b", start: 12.54, end: 17.75 },
            { label: "2a", start: 18, end: 26.95 },
            { label: "2b", start: 27.2, end: 32.67 },
            { label: "3", start: 32.92, end: 40.59 },
            { label: "4a", start: 40.84, end: 43.83 },
            { label: "4b", start: 44.08, end: 51.33 },
            { label: "s2", start: 51.58, end: 54.25 },
            { label: "5a", start: 54.5, end: 65.43 },
            { label: "5b", start: 65.68, end: 70.59 },
            { label: "6", start: 70.84, end: 82.33 },
            { label: "7a", start: 82.58, end: 88.65 },
            { label: "7b", start: 88.9, end: 94.07 },
            { label: "8", start: 94.32, end: 100.39 },
            { label: "9a", start: 100.64, end: 104.11 },
            { label: "9b", start: 104.36, end: 107.05 },
            { label: "9c", start: 107.3, end: 109.91 },
            { label: "10a", start: 110.16, end: 112.63 },
            { label: "10b", start: 112.88, end: 116.57 },
            { label: "10c", start: 116.82, end: 119.23 },
            { label: "11", start: 119.48, end: 123.33 },
            { label: "12a", start: 123.58, end: 125.57 },
            { label: "12b", start: 125.82, end: 131.81 },
            { label: "13a", start: 132.06, end: 135.21 },
            { label: "13b", start: 135.46, end: 137.57 },
            { label: "13c", start: 137.82, end: 143.19 },
            { label: "13d", start: 143.44, end: 144.77 },
            { label: "14", start: 145.02, end: 151.45 },
            { label: "15", start: 151.7, end: 158.35 },
            { label: "s3", start: 158.6, end: 161.17 },
            { label: "16a", start: 161.42, end: 169.09 },
            { label: "16b", start: 169.34, end: 175.81 },
            { label: "16c", start: 176.06, end: 180.83 },
            { label: "17", start: 181.08, end: 187.19 },
            { label: "18a", start: 187.44, end: 194.83 },
            { label: "18b", start: 195.08, end: 198.35 },
            { label: "s4", start: 198.6, end: 201.57 },
            { label: "19", start: 201.82, end: 211.15 },
            { label: "20", start: 211.4, end: 220.23 },
            { label: "21", start: 220.48, end: 224.97 },
            { label: "22a", start: 225.22, end: 227.55 },
            { label: "22b", start: 227.8, end: 231.95 },
            { label: "23a", start: 232.2, end: 237.47 },
            { label: "23b", start: 237.72, end: 247.11 },
            { label: "24a", start: 247.36, end: 260.29 },
            { label: "24b", start: 260.54, end: 265.99 },
            { label: "s5", start: 266.24, end: 270.59 },
            { label: "25a", start: 270.84, end: 278.21 },
            { label: "25b", start: 278.46, end: 286.89 },
            { label: "26a", start: 287.14, end: 298.35 },
            { label: "26b", start: 298.6, end: 303.09 },
            { label: "27", start: 303.34, end: 310.53 },
            { label: "28a", start: 310.78, end: 314.53 },
            { label: "28b", start: 314.78, end: 318.45 },
            { label: "28c", start: 318.7, end: 321.75 },
            { label: "29", start: 322, end: 330.65 },
            { label: "30", start: 330.9, end: 344.27 },
            { label: "31a", start: 344.52, end: 348.39 },
            { label: "31b", start: 348.64, end: 349.95 },
            { label: "31c", start: 350.2, end: 350.95 },
            { label: "32", start: 351.2, end: 361.95 },
            { label: "33", start: 362.2, end: 372.91 },
            { label: "34a", start: 373.16, end: 379.79 },
            { label: "34b", start: 380.04, end: 384.07 }
        ],
        "ch7":[
            { label: "s1", start: 3.84, end: 7.19 },
            { label: "1", start: 7.44, end: 13.03 },
            { label: "2", start: 13.28, end: 24.27 },
            { label: "3", start: 24.52, end: 32.51 },
            { label: "4a", start: 32.76, end: 34.79 },
            { label: "4b", start: 35.04, end: 41.11 },
            { label: "5", start: 41.36, end: 52.09 },
            { label: "6a", start: 52.34, end: 58.83 },
            { label: "6b", start: 59.08, end: 67.75 },
            { label: "s2", start: 68, end: 72.07 },
            { label: "7a", start: 72.32, end: 75.43 },
            { label: "7b", start: 75.68, end: 78.29 },
            { label: "7c", start: 78.54, end: 81.95 },
            { label: "8", start: 82.2, end: 95.89 },
            { label: "9", start: 96.14, end: 102.77 },
            { label: "10", start: 103.02, end: 106.19 },
            { label: "11", start: 106.44, end: 118.27 },
            { label: "12a", start: 118.52, end: 126.09 },
            { label: "12b", start: 126.34, end: 131.25 },
            { label: "s3", start: 131.5, end: 134.89 },
            { label: "13", start: 135.14, end: 149.79 },
            { label: "14", start: 150.04, end: 159.73 },
            { label: "s4", start: 159.98, end: 165.33 },
            { label: "15a", start: 165.58, end: 168.53 },
            { label: "15b", start: 168.78, end: 176.93 },
            { label: "16a", start: 177.18, end: 180.43 },
            { label: "16b", start: 180.68, end: 186.25 },
            { label: "17", start: 186.5, end: 194.25 },
            { label: "18", start: 194.5, end: 201.63 },
            { label: "19", start: 201.88, end: 207.59 },
            { label: "20", start: 207.84, end: 211.65 },
            { label: "21", start: 211.9, end: 223.01 },
            { label: "22a", start: 223.26, end: 226.85 },
            { label: "22b", start: 227.1, end: 232.15 },
            { label: "22c", start: 232.4, end: 238.25 },
            { label: "23a", start: 238.5, end: 240.57 },
            { label: "23b", start: 240.82, end: 247.53 },
            { label: "s5", start: 247.78, end: 253.21 },
            { label: "24", start: 253.46, end: 262.45 },
            { label: "25", start: 262.7, end: 273.51 },
            { label: "26", start: 273.76, end: 281.35 },
            { label: "27", start: 281.6, end: 292.27 },
            { label: "28", start: 292.52, end: 298.87 },
            { label: "29", start: 299.12, end: 305.03 }
        ],
        "ch8":[
            { label: "s1", start: 2.6, end: 5.85 },
            { label: "1", start: 6.1, end: 12.69 },
            { label: "2a", start: 12.94, end: 19.67 },
            { label: "2b", start: 19.92, end: 23.67 },
            { label: "3a", start: 23.92, end: 28.95 },
            { label: "3b", start: 29.2, end: 31.21 },
            { label: "3c", start: 31.46, end: 35.35 },
            { label: "4a", start: 35.6, end: 38.57 },
            { label: "4b", start: 38.82, end: 40.73 },
            { label: "4c", start: 40.98, end: 52.79 },
            { label: "s2", start: 53.04, end: 57.31 },
            { label: "5", start: 57.56, end: 67.05 },
            { label: "6a", start: 67.3, end: 68.59 },
            { label: "6b", start: 68.84, end: 76.91 },
            { label: "7a", start: 77.16, end: 78.83 },
            { label: "7b", start: 79.08, end: 82.05 },
            { label: "8a", start: 82.3, end: 85.19 },
            { label: "8b", start: 85.44, end: 89.77 },
            { label: "8c", start: 90.02, end: 94.09 },
            { label: "9a", start: 94.34, end: 100.09 },
            { label: "9b", start: 100.34, end: 101.89 },
            { label: "9c", start: 102.14, end: 107.33 },
            { label: "9d", start: 107.58, end: 111.93 },
            { label: "10a", start: 112.18, end: 119.97 },
            { label: "10b", start: 120.22, end: 126.01 },
            { label: "11", start: 126.26, end: 137.19 },
            { label: "12", start: 137.44, end: 150.13 },
            { label: "13a", start: 150.38, end: 153.27 },
            { label: "13b", start: 153.52, end: 154.57 },
            { label: "13c", start: 154.82, end: 158.33 },
            { label: "13d", start: 158.58, end: 163.11 },
            { label: "s3", start: 163.36, end: 170.53 },
            { label: "14", start: 170.78, end: 181.07 },
            { label: "15a", start: 181.32, end: 187.03 },
            { label: "15b", start: 187.28, end: 190.99 },
            { label: "16", start: 191.24, end: 205.17 },
            { label: "17a", start: 205.42, end: 211.79 },
            { label: "17b", start: 212.04, end: 214.49 },
            { label: "17c", start: 214.74, end: 218.05 },
            { label: "s4", start: 218.3, end: 223.17 },
            { label: "18", start: 223.42, end: 233.21 },
            { label: "19a", start: 233.46, end: 238.45 },
            { label: "19b", start: 238.7, end: 242.59 },
            { label: "20a", start: 242.84, end: 244.97 },
            { label: "20b", start: 245.22, end: 252.01 },
            { label: "21a", start: 252.26, end: 255.67 },
            { label: "21b", start: 255.92, end: 259.57 },
            { label: "22a", start: 259.82, end: 261.89 },
            { label: "22b", start: 262.14, end: 268.35 },
            { label: "s5", start: 268.6, end: 274.57 },
            { label: "23", start: 274.82, end: 281.13 },
            { label: "24a", start: 281.38, end: 289.61 },
            { label: "24b", start: 289.86, end: 292.09 },
            { label: "25a", start: 292.34, end: 297.69 },
            { label: "25b", start: 297.94, end: 302.07 },
            { label: "26a", start: 302.32, end: 304.67 },
            { label: "26b", start: 304.92, end: 308.79 },
            { label: "26c", start: 309.04, end: 313.55 },
            { label: "27a", start: 313.8, end: 320.37 },
            { label: "27b", start: 320.62, end: 326.65 },
            { label: "s6", start: 326.9, end: 332.75 },
            { label: "28a", start: 333, end: 346.11 },
            { label: "28b", start: 346.36, end: 351.31 },
            { label: "29a", start: 351.56, end: 353.49 },
            { label: "29b", start: 353.74, end: 356.89 },
            { label: "29c", start: 357.14, end: 361.73 },
            { label: "30", start: 361.98, end: 366.29 },
            { label: "31a", start: 366.54, end: 369.47 },
            { label: "31b", start: 369.72, end: 374.29 },
            { label: "32a", start: 374.54, end: 376.43 },
            { label: "32b", start: 376.68, end: 377.93 },
            { label: "32c", start: 378.18, end: 390.61 },
            { label: "33", start: 390.86, end: 401.13 },
            { label: "34", start: 401.38, end: 413.43 }
        ],
        "ch9":[
            { label: "s1", start: 3.92, end: 6.67 },
            { label: "1", start: 6.92, end: 14.15 },
            { label: "2a", start: 14.4, end: 22.03 },
            { label: "2b", start: 22.28, end: 27.61 },
            { label: "2c", start: 27.86, end: 34.63 },
            { label: "3a", start: 34.88, end: 40.35 },
            { label: "3b", start: 40.6, end: 42.67 },
            { label: "4a", start: 42.92, end: 46.59 },
            { label: "4b", start: 46.84, end: 50.37 },
            { label: "5a", start: 50.62, end: 53.21 },
            { label: "5b", start: 53.46, end: 58.83 },
            { label: "6a", start: 59.08, end: 66.67 },
            { label: "6b", start: 66.92, end: 69.89 },
            { label: "6c", start: 70.14, end: 73.23 },
            { label: "7", start: 73.48, end: 77.39 },
            { label: "8", start: 77.64, end: 87.85 },
            { label: "s2", start: 88.1, end: 93.29 },
            { label: "9a", start: 93.54, end: 102.05 },
            { label: "9b", start: 102.3, end: 105.89 },
            { label: "9c", start: 106.14, end: 108.01 },
            { label: "9d", start: 108.26, end: 110.29 },
            { label: "9e", start: 110.54, end: 115.33 },
            { label: "10", start: 115.58, end: 126.57 },
            { label: "11a", start: 126.82, end: 131.77 },
            { label: "11b", start: 132.02, end: 136.61 },
            { label: "12a", start: 136.86, end: 140.25 },
            { label: "12b", start: 140.5, end: 147.39 },
            { label: "13a", start: 147.64, end: 151.19 },
            { label: "13b", start: 151.44, end: 162.99 },
            { label: "s3", start: 163.24, end: 168.07 },
            { label: "14a", start: 168.32, end: 173.79 },
            { label: "14b", start: 174.04, end: 179.99 },
            { label: "15a", start: 180.24, end: 182.59 },
            { label: "15b", start: 182.84, end: 189.41 },
            { label: "15c", start: 189.66, end: 193.87 },
            { label: "15d", start: 194.12, end: 195.91 },
            { label: "16", start: 196.16, end: 206.29 },
            { label: "17", start: 206.54, end: 222.71 },
            { label: "s4", start: 222.96, end: 229.63 },
            { label: "18a", start: 229.88, end: 239.47 },
            { label: "18b", start: 239.72, end: 241.83 },
            { label: "18c", start: 242.08, end: 246.31 },
            { label: "19", start: 246.56, end: 253.51 },
            { label: "20", start: 253.76, end: 264.37 },
            { label: "21a", start: 264.62, end: 266.85 },
            { label: "21b", start: 267.1, end: 271.49 },
            { label: "22a", start: 271.74, end: 276.01 },
            { label: "22b", start: 276.26, end: 279.89 },
            { label: "22c", start: 280.14, end: 282.59 },
            { label: "22d", start: 282.84, end: 287.39 },
            { label: "23", start: 287.64, end: 297.49 },
            { label: "24a", start: 297.74, end: 298.97 },
            { label: "24b", start: 299.22, end: 301.67 },
            { label: "24c", start: 301.92, end: 303.43 },
            { label: "24d", start: 303.68, end: 307.07 },
            { label: "25", start: 307.32, end: 315.49 },
            { label: "26", start: 315.74, end: 321.27 },
            { label: "s5", start: 321.52, end: 327.41 },
            { label: "27a", start: 327.66, end: 338.95 },
            { label: "27b", start: 339.2, end: 342.71 },
            { label: "28a", start: 342.96, end: 348.29 },
            { label: "28b", start: 348.54, end: 350.61 },
            { label: "28c", start: 350.86, end: 353.81 },
            { label: "28d", start: 354.06, end: 355.45 },
            { label: "28e", start: 355.7, end: 357.09 },
            { label: "29a", start: 357.34, end: 361.35 },
            { label: "29b", start: 361.6, end: 365.61 },
            { label: "30a", start: 365.86, end: 369.59 },
            { label: "30b", start: 369.84, end: 373.43 },
            { label: "30c", start: 373.68, end: 378.07 },
            { label: "31", start: 378.32, end: 385.31 },
            { label: "32", start: 385.56, end: 392.89 },
            { label: "33a", start: 393.14, end: 399.65 },
            { label: "33b", start: 399.9, end: 402.83 },
            { label: "33c", start: 403.08, end: 407.25 },
            { label: "34a", start: 407.5, end: 409.91 },
            { label: "34b", start: 410.16, end: 415.69 },
            { label: "s6", start: 415.94, end: 421.37 },
            { label: "35", start: 421.62, end: 435.85 },
            { label: "36", start: 436.1, end: 448.11 },
            { label: "37a", start: 448.36, end: 451.01 },
            { label: "37b", start: 451.26, end: 454.39 },
            { label: "38", start: 454.64, end: 461.23 }
        ],
        "ch10":[
            { label: "s1", start: 2.94, end: 6.53 },
            { label: "1", start: 6.78, end: 19.33 },
            { label: "2a", start: 19.58, end: 22.67 },
            { label: "2b", start: 22.92, end: 28.27 },
            { label: "2c", start: 28.52, end: 32.81 },
            { label: "3a", start: 33.06, end: 35.71 },
            { label: "3b", start: 35.96, end: 39.29 },
            { label: "3c", start: 39.54, end: 43.33 },
            { label: "4", start: 43.58, end: 50.45 },
            { label: "5a", start: 50.7, end: 57.03 },
            { label: "5b", start: 57.28, end: 64.13 },
            { label: "6", start: 64.38, end: 69.03 },
            { label: "7", start: 69.28, end: 75.71 },
            { label: "8a", start: 75.96, end: 82.69 },
            { label: "8b", start: 82.94, end: 86.33 },
            { label: "9-10", start: 86.58, end: 103.09 },
            { label: "11", start: 103.34, end: 114.25 },
            { label: "12a", start: 114.5, end: 117.87 },
            { label: "12b", start: 118.12, end: 119.81 },
            { label: "13", start: 120.06, end: 131.35 },
            { label: "14", start: 131.6, end: 142.91 },
            { label: "15", start: 143.16, end: 152.97 },
            { label: "16a", start: 153.22, end: 157.71 },
            { label: "16b", start: 157.96, end: 162.91 },
            { label: "17", start: 163.16, end: 171.01 },
            { label: "18", start: 171.26, end: 183.13 },
            { label: "19a", start: 183.38, end: 190.25 },
            { label: "19b", start: 190.5, end: 194.87 },
            { label: "20", start: 195.12, end: 203.73 },
            { label: "21a", start: 203.98, end: 209.03 },
            { label: "21b", start: 209.28, end: 215.05 },
            { label: "22", start: 215.3, end: 224.55 },
            { label: "23a", start: 224.8, end: 231.77 },
            { label: "23b", start: 232.02, end: 241.01 },
            { label: "24", start: 241.26, end: 247.09 },
            { label: "25a", start: 247.34, end: 253.75 },
            { label: "25b", start: 254, end: 260.69 },
            { label: "26a", start: 260.94, end: 263.67 },
            { label: "26b", start: 263.92, end: 270.73 },
            { label: "27", start: 270.98, end: 282.73 },
            { label: "28a", start: 282.98, end: 290.59 },
            { label: "28b", start: 290.84, end: 298.29 },
            { label: "29a", start: 298.54, end: 303.63 },
            { label: "29b", start: 303.88, end: 309.85 },
            { label: "30", start: 310.1, end: 315.19 },
            { label: "31", start: 315.44, end: 322.37 },
            { label: "32", start: 322.62, end: 332.11 },
            { label: "33", start: 332.36, end: 341.15 },
            { label: "34a", start: 341.4, end: 345.45 },
            { label: "34b", start: 345.7, end: 350.77 },
            { label: "35a", start: 351.02, end: 353.23 },
            { label: "35b", start: 353.48, end: 354.59 },
            { label: "35c", start: 354.84, end: 356.05 },
            { label: "35d", start: 356.3, end: 359.47 },
            { label: "36", start: 359.72, end: 364.31 },
            { label: "37a", start: 364.56, end: 369.85 },
            { label: "37b", start: 370.1, end: 375.93 },
            { label: "38", start: 376.18, end: 381.67 },
            { label: "39", start: 381.92, end: 391.03 },
            { label: "40", start: 391.28, end: 400.39 },
            { label: "41a", start: 400.64, end: 408.33 },
            { label: "41b", start: 408.58, end: 415.85 },
            { label: "42", start: 416.1, end: 428.99 }
        ],
        "ch11":[
            { label: "s1", start: 3.2, end: 7.03 },
            { label: "1", start: 7.28, end: 20.51 },
            { label: "2", start: 20.76, end: 28.63 },
            { label: "3a", start: 28.88, end: 30.09 },
            { label: "3b", start: 30.34, end: 36.17 },
            { label: "4a", start: 36.42, end: 38.35 },
            { label: "4b", start: 38.6, end: 43.47 },
            { label: "5", start: 43.72, end: 55.35 },
            { label: "6", start: 55.6, end: 60.59 },
            { label: "7a", start: 60.84, end: 69.17 },
            { label: "7b", start: 69.42, end: 74.23 },
            { label: "7c", start: 74.48, end: 76.59 },
            { label: "8a", start: 76.84, end: 79.53 },
            { label: "8b", start: 79.78, end: 84.43 },
            { label: "8c", start: 84.68, end: 92.25 },
            { label: "9a", start: 92.5, end: 94.47 },
            { label: "9b", start: 94.72, end: 96.13 },
            { label: "9c", start: 96.38, end: 100.95 },
            { label: "10a", start: 101.2, end: 107.13 },
            { label: "10b", start: 107.38, end: 110.63 },
            { label: "10c", start: 110.88, end: 114.79 },
            { label: "11", start: 115.04, end: 128.83 },
            { label: "12", start: 129.08, end: 139.25 },
            { label: "13", start: 139.5, end: 145.01 },
            { label: "14", start: 145.26, end: 151.93 },
            { label: "15", start: 152.18, end: 155.91 },
            { label: "16a", start: 156.16, end: 159.65 },
            { label: "16b", start: 159.9, end: 166.61 },
            { label: "17a", start: 166.86, end: 169.93 },
            { label: "17b", start: 170.18, end: 173.83 },
            { label: "18a", start: 174.08, end: 179.67 },
            { label: "18b", start: 179.92, end: 180.79 },
            { label: "19a", start: 181.04, end: 185.89 },
            { label: "19b", start: 186.14, end: 186.49 },
            { label: "19c", start: 186.74, end: 192.81 },
            { label: "19d", start: 193.06, end: 198.05 },
            { label: "s2", start: 198.3, end: 205.31 },
            { label: "20", start: 205.56, end: 218.21 },
            { label: "21a", start: 218.46, end: 221.19 },
            { label: "21b", start: 221.44, end: 224.07 },
            { label: "21c", start: 224.32, end: 237.11 },
            { label: "22", start: 237.36, end: 246.35 },
            { label: "23a", start: 246.6, end: 252.67 },
            { label: "23b", start: 252.92, end: 257.01 },
            { label: "23c", start: 257.26, end: 266.63 },
            { label: "24", start: 266.88, end: 277.27 },
            { label: "s3", start: 277.52, end: 281.41 },
            { label: "25a", start: 281.66, end: 283.85 },
            { label: "25b", start: 284.1, end: 298.47 },
            { label: "26", start: 298.72, end: 303.37 },
            { label: "27a", start: 303.62, end: 307.17 },
            { label: "27b", start: 307.42, end: 320.07 },
            { label: "28a", start: 320.32, end: 324.79 },
            { label: "28b", start: 325.04, end: 327.37 },
            { label: "29", start: 327.62, end: 338.91 },
            { label: "30", start: 339.16, end: 343.83 }
        ],
        "ch12":[
            { label: "s1", start: 4, end: 7.57 },
            { label: "1a", start: 7.82, end: 13.97 },
            { label: "1b", start: 14.22, end: 22.05 },
            { label: "2a", start: 22.3, end: 27.49 },
            { label: "2b", start: 27.74, end: 33.39 },
            { label: "3", start: 33.64, end: 43.51 },
            { label: "4", start: 43.76, end: 60.35 },
            { label: "5", start: 60.6, end: 71.93 },
            { label: "6", start: 72.18, end: 77.01 },
            { label: "7a", start: 77.26, end: 80.65 },
            { label: "7b", start: 80.9, end: 86.19 },
            { label: "8", start: 86.44, end: 94.27 },
            { label: "s2", start: 94.52, end: 97.61 },
            { label: "9", start: 97.86, end: 103.49 },
            { label: "10a", start: 103.74, end: 107.91 },
            { label: "10b", start: 108.16, end: 117.67 },
            { label: "10c", start: 117.92, end: 121.71 },
            { label: "11a", start: 121.96, end: 124.21 },
            { label: "11b", start: 124.46, end: 134.11 },
            { label: "12a", start: 134.36, end: 138.93 },
            { label: "12b", start: 139.18, end: 143.63 },
            { label: "13a", start: 143.88, end: 146.35 },
            { label: "13b", start: 146.6, end: 148.59 },
            { label: "13c", start: 148.84, end: 155.55 },
            { label: "14", start: 155.8, end: 164.65 },
            { label: "s3", start: 164.9, end: 170.31 },
            { label: "15a", start: 170.56, end: 175.59 },
            { label: "15b", start: 175.84, end: 183.79 },
            { label: "16", start: 184.04, end: 190.15 },
            { label: "17", start: 190.4, end: 197.27 },
            { label: "18a", start: 197.52, end: 201.59 },
            { label: "18b", start: 201.84, end: 205.77 },
            { label: "18c", start: 206.02, end: 209.03 },
            { label: "18d", start: 209.28, end: 213.91 },
            { label: "19a", start: 214.16, end: 218.57 },
            { label: "19b", start: 218.82, end: 223.03 },
            { label: "20a", start: 223.28, end: 225.83 },
            { label: "20b", start: 226.08, end: 229.49 },
            { label: "20c", start: 229.74, end: 234.31 },
            { label: "21", start: 234.56, end: 242.23 },
            { label: "s4", start: 242.48, end: 247.87 },
            { label: "22", start: 248.12, end: 260.17 },
            { label: "23a", start: 260.42, end: 264.69 },
            { label: "23b", start: 264.94, end: 268.73 },
            { label: "24a", start: 268.98, end: 273.53 },
            { label: "24b", start: 273.78, end: 279.89 },
            { label: "25a", start: 280.14, end: 284.83 },
            { label: "25b", start: 285.08, end: 296.77 },
            { label: "26a", start: 297.02, end: 303.27 },
            { label: "26b", start: 303.52, end: 307.41 },
            { label: "27a", start: 307.66, end: 316.45 },
            { label: "27b", start: 316.7, end: 321.15 },
            { label: "28", start: 321.4, end: 332.45 },
            { label: "29", start: 332.7, end: 346.69 },
            { label: "30", start: 346.94, end: 354.73 },
            { label: "31a", start: 354.98, end: 360.61 },
            { label: "31b", start: 360.86, end: 364.39 },
            { label: "32a", start: 364.64, end: 369.15 },
            { label: "32b", start: 369.4, end: 377.59 },
            { label: "s5", start: 377.84, end: 382.17 },
            { label: "33a", start: 382.42, end: 388.99 },
            { label: "33b", start: 389.24, end: 392.41 },
            { label: "34a", start: 392.66, end: 398.87 },
            { label: "34b", start: 399.12, end: 403.95 },
            { label: "35", start: 404.2, end: 416.37 },
            { label: "36", start: 416.62, end: 425.51 },
            { label: "37", start: 425.76, end: 431.39 },
            { label: "s6", start: 431.64, end: 436.43 },
            { label: "38a", start: 436.68, end: 442.31 },
            { label: "38b", start: 442.56, end: 446.79 },
            { label: "39a", start: 447.04, end: 449.23 },
            { label: "39b", start: 449.48, end: 453.15 },
            { label: "39c", start: 453.4, end: 458.59 },
            { label: "40", start: 458.84, end: 469.65 },
            { label: "41", start: 469.9, end: 486.79 },
            { label: "42", start: 487.04, end: 503.63 },
            { label: "43", start: 503.88, end: 513.97 },
            { label: "44a", start: 514.22, end: 516.33 },
            { label: "44b", start: 516.58, end: 525.79 },
            { label: "45a", start: 526.04, end: 534.89 },
            { label: "45b", start: 535.14, end: 539.09 },
            { label: "45c", start: 539.34, end: 543.43 },
            { label: "s7", start: 543.68, end: 548.17 },
            { label: "46", start: 548.42, end: 556.89 },
            { label: "47a", start: 557.14, end: 559.65 },
            { label: "47b", start: 559.9, end: 564.29 },
            { label: "48a", start: 564.54, end: 566.05 },
            { label: "48b", start: 566.3, end: 567.71 },
            { label: "48c", start: 567.96, end: 569.97 },
            { label: "49a", start: 570.22, end: 574.19 },
            { label: "49b", start: 574.44, end: 577.49 },
            { label: "50", start: 577.74, end: 584.51 }
        ],
        "ch13":[
            { label: "s1", start: 3.32, end: 5.77 },
            { label: "1", start: 6.02, end: 12.05 },
            { label: "2", start: 12.3, end: 23.29 },
            { label: "3a", start: 23.54, end: 29.03 },
            { label: "3b", start: 29.28, end: 35.27 },
            { label: "4", start: 35.52, end: 44.69 },
            { label: "5a", start: 44.94, end: 50.15 },
            { label: "5b", start: 50.4, end: 55.69 },
            { label: "6", start: 55.94, end: 61.27 },
            { label: "7a", start: 61.52, end: 64.77 },
            { label: "7b", start: 65.02, end: 69.37 },
            { label: "8a", start: 69.62, end: 75.61 },
            { label: "8b", start: 75.86, end: 80.91 },
            { label: "9", start: 81.16, end: 85.11 },
            { label: "10a", start: 85.36, end: 89.83 },
            { label: "10b", start: 90.08, end: 92.93 },
            { label: "11a", start: 93.18, end: 95.43 },
            { label: "11b", start: 95.68, end: 101.63 },
            { label: "12", start: 101.88, end: 110.91 },
            { label: "13a", start: 111.16, end: 114.95 },
            { label: "13b", start: 115.2, end: 117.85 },
            { label: "13c", start: 118.1, end: 121.53 },
            { label: "14a", start: 121.78, end: 126.21 },
            { label: "14b", start: 126.46, end: 130.67 },
            { label: "14c", start: 130.92, end: 135.47 },
            { label: "15a", start: 135.72, end: 139.49 },
            { label: "15b", start: 139.74, end: 142.79 },
            { label: "15c", start: 143.04, end: 146.27 },
            { label: "15d", start: 146.52, end: 149.57 },
            { label: "15e", start: 149.82, end: 152.31 },
            { label: "15f", start: 152.56, end: 156.41 },
            { label: "15g", start: 156.66, end: 160.61 },
            { label: "16", start: 160.86, end: 167.93 },
            { label: "17", start: 168.18, end: 181.63 },
            { label: "18", start: 181.88, end: 186.11 },
            { label: "19a", start: 186.36, end: 198.35 },
            { label: "19b", start: 198.6, end: 202.61 },
            { label: "20", start: 202.86, end: 211.35 },
            { label: "21", start: 211.6, end: 223.99 },
            { label: "22", start: 224.24, end: 236.77 },
            { label: "23", start: 237.02, end: 249.97 },
            { label: "s2", start: 250.22, end: 254.45 },
            { label: "24a", start: 254.7, end: 259.07 },
            { label: "24b", start: 259.32, end: 265.79 },
            { label: "25", start: 266.04, end: 274.03 },
            { label: "26", start: 274.28, end: 281.21 },
            { label: "27a", start: 281.46, end: 286.27 },
            { label: "27b", start: 286.52, end: 291.31 },
            { label: "27c", start: 291.56, end: 294.55 },
            { label: "28a", start: 294.8, end: 296.99 },
            { label: "28b", start: 297.24, end: 299.39 },
            { label: "28c", start: 299.64, end: 302.71 },
            { label: "28d", start: 302.96, end: 306.13 },
            { label: "29a", start: 306.38, end: 307.15 },
            { label: "29b", start: 307.4, end: 314.03 },
            { label: "30a", start: 314.28, end: 318.73 },
            { label: "30b", start: 318.98, end: 326.45 },
            { label: "30c", start: 326.7, end: 331.79 },
            { label: "s3", start: 332.04, end: 337.13 },
            { label: "31a", start: 337.38, end: 342.13 },
            { label: "31b", start: 342.38, end: 350.91 },
            { label: "32", start: 351.16, end: 369.41 },
            { label: "s4", start: 369.66, end: 373.41 },
            { label: "33a", start: 373.66, end: 379.53 },
            { label: "33b", start: 379.78, end: 391.65 },
            { label: "34", start: 391.9, end: 400.83 },
            { label: "35a", start: 401.08, end: 406.75 },
            { label: "35b", start: 407, end: 411.49 },
            { label: "35c", start: 411.74, end: 415.87 },
            { label: "35d", start: 416.12, end: 418.87 },
            { label: "s5", start: 419.12, end: 426.09 },
            { label: "36a", start: 426.34, end: 431.09 },
            { label: "36b", start: 431.34, end: 436.33 },
            { label: "36c", start: 436.58, end: 441.41 },
            { label: "37a", start: 441.66, end: 443.63 },
            { label: "37b", start: 443.88, end: 449.23 },
            { label: "38-39a", start: 449.48, end: 452.23 },
            { label: "38-39b", start: 452.48, end: 456.99 },
            { label: "38-39c", start: 457.24, end: 466.27 },
            { label: "38-39d", start: 466.52, end: 475.17 },
            { label: "40", start: 475.42, end: 485.13 },
            { label: "41", start: 485.38, end: 496.77 },
            { label: "42", start: 497.02, end: 504.69 },
            { label: "43a", start: 504.94, end: 511.75 },
            { label: "43b", start: 512, end: 516.95 },
            { label: "s6", start: 517.2, end: 523.75 },
            { label: "44", start: 524, end: 539.83 },
            { label: "45", start: 540.08, end: 546.63 },
            { label: "46", start: 546.88, end: 555.13 },
            { label: "s7", start: 555.38, end: 559.23 },
            { label: "47a", start: 559.48, end: 561.79 },
            { label: "47b", start: 562.04, end: 569.97 },
            { label: "48a", start: 570.22, end: 575.55 },
            { label: "48b", start: 575.8, end: 582.87 },
            { label: "49a", start: 583.12, end: 586.09 },
            { label: "49b", start: 586.34, end: 591.05 },
            { label: "50", start: 591.3, end: 599.01 },
            { label: "51a", start: 599.26, end: 600.89 },
            { label: "51b", start: 601.14, end: 603.61 },
            { label: "51c", start: 603.86, end: 604.83 },
            { label: "51d", start: 605.08, end: 605.63 },
            { label: "52a", start: 605.88, end: 607.35 },
            { label: "52b", start: 607.6, end: 619.77 },
            { label: "s8", start: 620.02, end: 624.73 },
            { label: "53", start: 624.98, end: 629.47 },
            { label: "54a", start: 629.72, end: 633.99 },
            { label: "54b", start: 634.24, end: 637.35 },
            { label: "54c", start: 637.6, end: 644.07 },
            { label: "55a", start: 644.32, end: 647.61 },
            { label: "55b", start: 647.86, end: 650.97 },
            { label: "55c", start: 651.22, end: 655.35 },
            { label: "56a", start: 655.6, end: 659.17 },
            { label: "56b", start: 659.42, end: 662.21 },
            { label: "57a", start: 662.46, end: 666.01 },
            { label: "57b", start: 666.26, end: 668.85 },
            { label: "57c", start: 669.1, end: 675.83 },
            { label: "58", start: 676.08, end: 682.95 }
        ],
        "ch14":[
            { label: "s1", start: 3.56, end: 7.49 },
            { label: "1", start: 7.74, end: 11.91 },
            { label: "2a", start: 12.16, end: 14.81 },
            { label: "2b", start: 15.06, end: 24.03 },
            { label: "3", start: 24.28, end: 34.37 },
            { label: "4a", start: 34.62, end: 36.53 },
            { label: "4b", start: 36.78, end: 39.81 },
            { label: "5", start: 40.06, end: 49.23 },
            { label: "6", start: 49.48, end: 59.87 },
            { label: "7", start: 60.12, end: 65.33 },
            { label: "8a", start: 65.58, end: 69.55 },
            { label: "8b", start: 69.8, end: 75.09 },
            { label: "9", start: 75.34, end: 84.39 },
            { label: "10", start: 84.64, end: 89.19 },
            { label: "11", start: 89.44, end: 96.57 },
            { label: "12", start: 96.82, end: 104.63 },
            { label: "s2", start: 104.88, end: 109.39 },
            { label: "13a", start: 109.64, end: 117.73 },
            { label: "13b", start: 117.98, end: 125.51 },
            { label: "14", start: 125.76, end: 136.91 },
            { label: "15a", start: 137.16, end: 141.87 },
            { label: "15b", start: 142.12, end: 146.37 },
            { label: "15c", start: 146.62, end: 152.69 },
            { label: "16a", start: 152.94, end: 154.73 },
            { label: "16b", start: 154.98, end: 156.77 },
            { label: "16c", start: 157.02, end: 159.61 },
            { label: "17a", start: 159.86, end: 161.51 },
            { label: "17b", start: 161.76, end: 165.85 },
            { label: "18a", start: 166.1, end: 167.87 },
            { label: "18b", start: 168.12, end: 170.25 },
            { label: "19a", start: 170.5, end: 174.75 },
            { label: "19b", start: 175, end: 187.97 },
            { label: "20", start: 188.22, end: 198.33 },
            { label: "21", start: 198.58, end: 206.81 },
            { label: "s3", start: 207.06, end: 211.41 },
            { label: "22", start: 211.66, end: 225.95 },
            { label: "23a", start: 226.2, end: 233.15 },
            { label: "23b", start: 233.4, end: 237.61 },
            { label: "24", start: 237.86, end: 247.61 },
            { label: "25", start: 247.86, end: 255.73 },
            { label: "26a", start: 255.98, end: 262.23 },
            { label: "26b", start: 262.48, end: 262.93 },
            { label: "26c", start: 263.18, end: 265.21 },
            { label: "27a", start: 265.46, end: 268.15 },
            { label: "27b", start: 268.4, end: 270.25 },
            { label: "27c", start: 270.5, end: 272.11 },
            { label: "28a", start: 272.36, end: 274.11 },
            { label: "28b", start: 274.36, end: 280.35 },
            { label: "29a", start: 280.6, end: 282.51 },
            { label: "29b", start: 282.76, end: 283.75 },
            { label: "29c", start: 284, end: 290.67 },
            { label: "30a", start: 290.92, end: 297.19 },
            { label: "30b", start: 297.44, end: 300.21 },
            { label: "31a", start: 300.46, end: 305.09 },
            { label: "31b", start: 305.34, end: 309.21 },
            { label: "32", start: 309.46, end: 313.57 },
            { label: "33a", start: 313.82, end: 319.19 },
            { label: "33b", start: 319.44, end: 322.71 },
            { label: "s4", start: 322.96, end: 328.75 },
            { label: "34", start: 329, end: 336.05 },
            { label: "35", start: 336.3, end: 348.05 },
            { label: "36", start: 348.3, end: 359.19 }
        ],
        "ch15":[
            { label: "s1", start: 3.24, end: 6.21 },
            { label: "1", start: 6.46, end: 14.85 },
            { label: "2a", start: 15.1, end: 19.03 },
            { label: "2b", start: 19.28, end: 23.31 },
            { label: "3a", start: 23.56, end: 25.09 },
            { label: "3b", start: 25.34, end: 30.47 },
            { label: "4a", start: 30.72, end: 32.73 },
            { label: "4b", start: 32.98, end: 42.99 },
            { label: "5a", start: 43.24, end: 47.29 },
            { label: "5b", start: 47.54, end: 51.93 },
            { label: "6a", start: 52.18, end: 56.93 },
            { label: "6b", start: 57.18, end: 62.75 },
            { label: "7a", start: 63, end: 64.57 },
            { label: "7b", start: 64.82, end: 70.21 },
            { label: "8a", start: 70.46, end: 74.51 },
            { label: "8b", start: 74.76, end: 77.91 },
            { label: "9a", start: 78.16, end: 81.33 },
            { label: "9b", start: 81.58, end: 86.27 },
            { label: "10a", start: 86.52, end: 91.47 },
            { label: "10b", start: 91.72, end: 94.45 },
            { label: "11", start: 94.7, end: 105.97 },
            { label: "12a", start: 106.22, end: 110.61 },
            { label: "12b", start: 110.86, end: 114.97 },
            { label: "13a", start: 115.22, end: 117.43 },
            { label: "13b", start: 117.68, end: 123.21 },
            { label: "14a", start: 123.46, end: 125.73 },
            { label: "14b", start: 125.98, end: 129.87 },
            { label: "14c", start: 130.12, end: 136.83 },
            { label: "15a", start: 137.08, end: 138.91 },
            { label: "15b", start: 139.16, end: 141.37 },
            { label: "16a", start: 141.62, end: 143.31 },
            { label: "16b", start: 143.56, end: 145.81 },
            { label: "17", start: 146.06, end: 152.03 },
            { label: "18", start: 152.28, end: 160.05 },
            { label: "19", start: 160.3, end: 170.79 },
            { label: "20", start: 171.04, end: 178.81 },
            { label: "s2", start: 179.06, end: 183.93 },
            { label: "21", start: 184.18, end: 189.31 },
            { label: "22a", start: 189.56, end: 196.81 },
            { label: "22b", start: 197.06, end: 201.33 },
            { label: "22c", start: 201.58, end: 205.75 },
            { label: "23a", start: 206, end: 214.91 },
            { label: "23b", start: 215.16, end: 220.69 },
            { label: "24a", start: 220.94, end: 223.03 },
            { label: "24b", start: 223.28, end: 227.93 },
            { label: "25a", start: 228.18, end: 233.21 },
            { label: "25b", start: 233.46, end: 236.35 },
            { label: "26a", start: 236.6, end: 239.01 },
            { label: "26b", start: 239.26, end: 244.41 },
            { label: "27a", start: 244.66, end: 246.53 },
            { label: "27b", start: 246.78, end: 252.51 },
            { label: "28a", start: 252.76, end: 255.21 },
            { label: "28b", start: 255.46, end: 261.93 },
            { label: "28c", start: 262.18, end: 266.93 },
            { label: "s3", start: 267.18, end: 272.29 },
            { label: "29", start: 272.54, end: 281.15 },
            { label: "30", start: 281.4, end: 296.85 },
            { label: "31", start: 297.1, end: 314.49 },
            { label: "32a", start: 314.74, end: 318.77 },
            { label: "32b", start: 319.02, end: 326.69 },
            { label: "32c", start: 326.94, end: 332.81 },
            { label: "33a", start: 333.06, end: 334.75 },
            { label: "33b", start: 335, end: 341.59 },
            { label: "34a", start: 341.84, end: 343.63 },
            { label: "34b", start: 343.88, end: 346.01 },
            { label: "34c", start: 346.26, end: 348.03 },
            { label: "34d", start: 348.28, end: 351.61 },
            { label: "35", start: 351.86, end: 356.59 },
            { label: "36", start: 356.84, end: 368.51 },
            { label: "37", start: 368.76, end: 379.25 },
            { label: "38", start: 379.5, end: 385.91 },
            { label: "39", start: 386.16, end: 394.43 }
        ],
        "ch16":[
            { label: "s1", start: 3.88, end: 6.97 },
            { label: "1a", start: 7.22, end: 14.35 },
            { label: "1b", start: 14.6, end: 21.09 },
            { label: "2a", start: 21.34, end: 24.79 },
            { label: "2b", start: 25.04, end: 31.09 },
            { label: "3a", start: 31.34, end: 38.07 },
            { label: "3b", start: 38.32, end: 48.21 },
            { label: "4a", start: 48.46, end: 59.69 },
            { label: "4b", start: 59.94, end: 64.17 },
            { label: "s2", start: 64.42, end: 70.29 },
            { label: "5", start: 70.54, end: 78.01 },
            { label: "6a", start: 78.26, end: 80.91 },
            { label: "6b", start: 81.16, end: 87.47 },
            { label: "7a", start: 87.72, end: 92.59 },
            { label: "7b", start: 92.84, end: 97.19 },
            { label: "8a", start: 97.44, end: 100.43 },
            { label: "8b", start: 100.68, end: 106.57 },
            { label: "9a", start: 106.82, end: 108.77 },
            { label: "9b", start: 109.02, end: 116.17 },
            { label: "10", start: 116.42, end: 121.43 },
            { label: "11a", start: 121.68, end: 126.65 },
            { label: "11b", start: 126.9, end: 132.05 },
            { label: "12", start: 132.3, end: 141.95 },
            { label: "s3", start: 142.2, end: 147.69 },
            { label: "13a", start: 147.94, end: 155.03 },
            { label: "13b", start: 155.28, end: 159.51 },
            { label: "14a", start: 159.76, end: 162.55 },
            { label: "14b", start: 162.8, end: 166.65 },
            { label: "14c", start: 166.9, end: 176.55 },
            { label: "15a", start: 176.8, end: 178.83 },
            { label: "15b", start: 179.08, end: 182.97 },
            { label: "16a", start: 183.22, end: 186.13 },
            { label: "16b", start: 186.38, end: 189.95 },
            { label: "17a", start: 190.2, end: 192.25 },
            { label: "17b", start: 192.5, end: 197.03 },
            { label: "17c", start: 197.28, end: 205.69 },
            { label: "18", start: 205.94, end: 216.53 },
            { label: "19a", start: 216.78, end: 220.51 },
            { label: "19b", start: 220.76, end: 231.39 },
            { label: "20", start: 231.64, end: 238.47 },
            { label: "s4", start: 238.72, end: 245.83 },
            { label: "21", start: 246.08, end: 268.13 },
            { label: "22a", start: 268.38, end: 273.93 },
            { label: "22b", start: 274.18, end: 278.83 },
            { label: "23a", start: 279.08, end: 281.39 },
            { label: "23b", start: 281.64, end: 284.43 },
            { label: "23c", start: 284.68, end: 289.69 },
            { label: "24a", start: 289.94, end: 293.33 },
            { label: "24b", start: 293.58, end: 302.55 },
            { label: "25a", start: 302.8, end: 308.89 },
            { label: "25b", start: 309.14, end: 313.93 },
            { label: "26a", start: 314.18, end: 321.09 },
            { label: "26b", start: 321.34, end: 325.29 },
            { label: "27", start: 325.54, end: 335.31 },
            { label: "28", start: 335.56, end: 346.23 }
        ],
        "ch17":[
            { label: "s1", start: 3.84, end: 6.47 },
            { label: "1", start: 6.72, end: 20.69 },
            { label: "2a", start: 20.94, end: 26.17 },
            { label: "2b", start: 26.42, end: 34.17 },
            { label: "3", start: 34.42, end: 44.21 },
            { label: "4a", start: 44.46, end: 47.43 },
            { label: "4b", start: 47.68, end: 51.57 },
            { label: "4c", start: 51.82, end: 59.69 },
            { label: "5a", start: 59.94, end: 71.25 },
            { label: "5b", start: 71.5, end: 76.01 },
            { label: "5c", start: 76.26, end: 79.03 },
            { label: "6", start: 79.28, end: 88.31 },
            { label: "7a", start: 88.56, end: 94.19 },
            { label: "7b", start: 94.44, end: 97.61 },
            { label: "8", start: 97.86, end: 104.25 },
            { label: "9a", start: 104.5, end: 110.37 },
            { label: "9b", start: 110.62, end: 120.73 },
            { label: "10a", start: 120.98, end: 124.17 },
            { label: "10b", start: 124.42, end: 129.23 },
            { label: "11a", start: 129.48, end: 131.73 },
            { label: "11b", start: 131.98, end: 137.15 },
            { label: "12", start: 137.4, end: 152.51 },
            { label: "13", start: 152.76, end: 161.09 },
            { label: "s2", start: 161.34, end: 168.15 },
            { label: "14", start: 168.4, end: 178.45 },
            { label: "15a", start: 178.7, end: 182.21 },
            { label: "15b", start: 182.46, end: 186.29 },
            { label: "15c", start: 186.54, end: 191.85 },
            { label: "16", start: 192.1, end: 198.03 },
            { label: "17a", start: 198.28, end: 200.69 },
            { label: "17b", start: 200.94, end: 204.05 },
            { label: "17c", start: 204.3, end: 208.75 },
            { label: "17d", start: 209, end: 211.29 },
            { label: "18a", start: 211.54, end: 217.63 },
            { label: "18b", start: 217.88, end: 220.93 },
            { label: "19a", start: 221.18, end: 227.01 },
            { label: "19b", start: 227.26, end: 230.77 },
            { label: "20a", start: 231.02, end: 232.97 },
            { label: "20b", start: 233.22, end: 236.19 },
            { label: "20c", start: 236.44, end: 247.57 },
            { label: "20d", start: 247.82, end: 252.33 },
            { label: "21", start: 252.58, end: 258.25 },
            { label: "s3", start: 258.5, end: 264.85 },
            { label: "22a", start: 265.1, end: 273.05 },
            { label: "22b", start: 273.3, end: 277.57 },
            { label: "23", start: 277.82, end: 290.83 },
            { label: "s4", start: 291.08, end: 295.69 },
            { label: "24a", start: 295.94, end: 308.93 },
            { label: "24b", start: 309.18, end: 313.39 },
            { label: "25a", start: 313.64, end: 315.27 },
            { label: "25b", start: 315.52, end: 317.33 },
            { label: "25c", start: 317.58, end: 320.41 },
            { label: "25d", start: 320.66, end: 324.43 },
            { label: "25e", start: 324.68, end: 330.15 },
            { label: "25f", start: 330.4, end: 335.53 },
            { label: "25g", start: 335.78, end: 338.19 },
            { label: "26a", start: 338.44, end: 340.25 },
            { label: "26b", start: 340.5, end: 341.79 },
            { label: "26c", start: 342.04, end: 343.89 },
            { label: "26d", start: 344.14, end: 346.75 },
            { label: "27a", start: 347, end: 355.15 },
            { label: "27b", start: 355.4, end: 368.31 }
        ],
        "ch18":[
            { label: "s1", start: 2.88, end: 7.67 },
            { label: "1a", start: 7.92, end: 13.23 },
            { label: "1b", start: 13.48, end: 17.07 },
            { label: "2", start: 17.32, end: 22.47 },
            { label: "3a", start: 22.72, end: 24.27 },
            { label: "3b", start: 24.52, end: 34.97 },
            { label: "4", start: 35.22, end: 43.87 },
            { label: "5", start: 44.12, end: 52.29 },
            { label: "6", start: 52.54, end: 68.23 },
            { label: "7a", start: 68.48, end: 71.95 },
            { label: "7b", start: 72.2, end: 79.63 },
            { label: "8a", start: 79.88, end: 86.53 },
            { label: "8b", start: 86.78, end: 98.83 },
            { label: "9", start: 99.08, end: 114.81 },
            { label: "s2", start: 115.06, end: 119.39 },
            { label: "10", start: 119.64, end: 135.23 },
            { label: "11", start: 135.48, end: 141.29 },
            { label: "12a", start: 141.54, end: 143.19 },
            { label: "12b", start: 143.44, end: 156.79 },
            { label: "13", start: 157.04, end: 167.93 },
            { label: "14", start: 168.18, end: 175.27 },
            { label: "s3", start: 175.52, end: 183.67 },
            { label: "15a", start: 183.92, end: 192.49 },
            { label: "15b", start: 192.74, end: 197.71 },
            { label: "16", start: 197.96, end: 209.71 },
            { label: "17a", start: 209.96, end: 214.61 },
            { label: "17b", start: 214.86, end: 223.41 },
            { label: "18", start: 223.66, end: 235.99 },
            { label: "19", start: 236.24, end: 250.23 },
            { label: "20", start: 250.48, end: 261.67 },
            { label: "s4", start: 261.92, end: 263.89 },
            { label: "21a", start: 264.14, end: 268.85 },
            { label: "21b", start: 269.1, end: 276.43 },
            { label: "21c", start: 276.68, end: 278.39 },
            { label: "22a", start: 278.64, end: 280.89 },
            { label: "22b", start: 281.14, end: 286.65 },
            { label: "23", start: 286.9, end: 296.39 },
            { label: "24", start: 296.64, end: 305.65 },
            { label: "25", start: 305.9, end: 318.67 },
            { label: "26a", start: 318.92, end: 324.99 },
            { label: "26b", start: 325.24, end: 330.41 },
            { label: "27", start: 330.66, end: 337.33 },
            { label: "28a", start: 337.58, end: 347.35 },
            { label: "28b", start: 347.6, end: 351.03 },
            { label: "28c", start: 351.28, end: 353.25 },
            { label: "29a", start: 353.5, end: 358.51 },
            { label: "29b", start: 358.76, end: 363.37 },
            { label: "30", start: 363.62, end: 370.87 },
            { label: "31", start: 371.12, end: 382.93 },
            { label: "32a", start: 383.18, end: 388.11 },
            { label: "32b", start: 388.36, end: 390.35 },
            { label: "32c", start: 390.6, end: 395.67 },
            { label: "33", start: 395.92, end: 401.77 },
            { label: "34", start: 402.02, end: 409.91 },
            { label: "35", start: 410.16, end: 420.35 }
        ],
        "ch19":[
            { label: "s1", start: 3.28, end: 8.37 },
            { label: "1", start: 8.62, end: 19.55 },
            { label: "2", start: 19.8, end: 28.43 },
            { label: "3a", start: 28.68, end: 35.11 },
            { label: "3b", start: 35.36, end: 38.37 },
            { label: "3c", start: 38.62, end: 46.13 },
            { label: "4a", start: 46.38, end: 48.53 },
            { label: "4b", start: 48.78, end: 54.91 },
            { label: "5a", start: 55.16, end: 56.33 },
            { label: "5b", start: 56.58, end: 64.83 },
            { label: "6a", start: 65.08, end: 70.33 },
            { label: "6b", start: 70.58, end: 75.79 },
            { label: "7a", start: 76.04, end: 77.33 },
            { label: "7b", start: 77.58, end: 88.01 },
            { label: "8a", start: 88.26, end: 90.07 },
            { label: "8b", start: 90.32, end: 99.13 },
            { label: "9", start: 99.38, end: 108.75 },
            { label: "10a", start: 109, end: 111.65 },
            { label: "10b", start: 111.9, end: 117.35 },
            { label: "11a", start: 117.6, end: 119.13 },
            { label: "11b", start: 119.38, end: 125.13 },
            { label: "12a", start: 125.38, end: 142.49 },
            { label: "12b", start: 142.74, end: 148.11 },
            { label: "s2", start: 148.36, end: 152.91 },
            { label: "13a", start: 153.16, end: 161.79 },
            { label: "13b", start: 162.04, end: 166.49 },
            { label: "14a", start: 166.74, end: 168.75 },
            { label: "14b", start: 169, end: 172.89 },
            { label: "14c", start: 173.14, end: 177.49 },
            { label: "15", start: 177.74, end: 183.43 },
            { label: "s3", start: 183.68, end: 187.15 },
            { label: "16a", start: 187.4, end: 192.01 },
            { label: "16b", start: 192.26, end: 198.79 },
            { label: "17a", start: 199.04, end: 201.57 },
            { label: "17b", start: 201.82, end: 205.39 },
            { label: "17c", start: 205.64, end: 207.85 },
            { label: "17d", start: 208.1, end: 214.19 },
            { label: "18a", start: 214.44, end: 216.27 },
            { label: "18b", start: 216.52, end: 217.75 },
            { label: "18c", start: 218, end: 219.55 },
            { label: "18d", start: 219.8, end: 225.93 },
            { label: "19", start: 226.18, end: 232.73 },
            { label: "20a", start: 232.98, end: 234.73 },
            { label: "20b", start: 234.98, end: 237.03 },
            { label: "20c", start: 237.28, end: 238.81 },
            { label: "21a", start: 239.06, end: 240.93 },
            { label: "21b", start: 241.18, end: 250.41 },
            { label: "21c", start: 250.66, end: 253.77 },
            { label: "22", start: 254.02, end: 264.97 },
            { label: "23a", start: 265.22, end: 268.77 },
            { label: "23b", start: 269.02, end: 278.39 },
            { label: "24", start: 278.64, end: 289.77 },
            { label: "25a", start: 290.02, end: 296.61 },
            { label: "25b", start: 296.86, end: 299.45 },
            { label: "26a", start: 299.7, end: 302.55 },
            { label: "26b", start: 302.8, end: 309.73 },
            { label: "27a", start: 309.98, end: 311.85 },
            { label: "27b", start: 312.1, end: 316.43 },
            { label: "27c", start: 316.68, end: 319.05 },
            { label: "28a", start: 319.3, end: 321.85 },
            { label: "28b", start: 322.1, end: 343.31 },
            { label: "29", start: 343.56, end: 361.73 },
            { label: "30", start: 361.98, end: 370.39 }
        ],
        "ch20":[
            { label: "s1", start: 3.36, end: 7.39 },
            { label: "1", start: 7.64, end: 17.95 },
            { label: "2", start: 18.2, end: 29.71 },
            { label: "3", start: 29.96, end: 39.33 },
            { label: "4a", start: 39.58, end: 42.49 },
            { label: "4b", start: 42.74, end: 47.93 },
            { label: "5a", start: 48.18, end: 50.33 },
            { label: "5b", start: 50.58, end: 59.03 },
            { label: "6a", start: 59.28, end: 68.15 },
            { label: "6b", start: 68.4, end: 71.25 },
            { label: "7a", start: 71.5, end: 73.39 },
            { label: "7b", start: 73.64, end: 75.93 },
            { label: "7c", start: 76.18, end: 77.83 },
            { label: "7d", start: 78.08, end: 81.13 },
            { label: "8a", start: 81.38, end: 84.55 },
            { label: "8b", start: 84.8, end: 90.27 },
            { label: "9", start: 90.52, end: 98.07 },
            { label: "10a", start: 98.32, end: 107.17 },
            { label: "10b", start: 107.42, end: 112.35 },
            { label: "11", start: 112.6, end: 118.57 },
            { label: "12", start: 118.82, end: 130.35 },
            { label: "13a", start: 130.6, end: 134.39 },
            { label: "13b", start: 134.64, end: 138.53 },
            { label: "13c", start: 138.78, end: 142.05 },
            { label: "14a", start: 142.3, end: 144.77 },
            { label: "14b", start: 145.02, end: 148.73 },
            { label: "15a", start: 148.98, end: 152.75 },
            { label: "15b", start: 153, end: 157.05 },
            { label: "16", start: 157.3, end: 164.17 },
            { label: "s2", start: 164.42, end: 171.67 },
            { label: "17", start: 171.92, end: 183.33 },
            { label: "18a", start: 183.58, end: 185.89 },
            { label: "18b", start: 186.14, end: 192.61 },
            { label: "18c", start: 192.86, end: 195.99 },
            { label: "19a", start: 196.24, end: 203.15 },
            { label: "19b", start: 203.4, end: 210.07 },
            { label: "s3", start: 210.32, end: 216.81 },
            { label: "20", start: 217.06, end: 230.79 },
            { label: "21a", start: 231.04, end: 232.81 },
            { label: "21b", start: 233.06, end: 234.73 },
            { label: "21c", start: 234.98, end: 236.09 },
            { label: "21d", start: 236.34, end: 245.69 },
            { label: "22a", start: 245.94, end: 248.79 },
            { label: "22b", start: 249.04, end: 252.73 },
            { label: "22c", start: 252.98, end: 259.03 },
            { label: "22d", start: 259.28, end: 261.09 },
            { label: "22e", start: 261.34, end: 262.99 },
            { label: "23a", start: 263.24, end: 265.01 },
            { label: "23b", start: 265.26, end: 275.29 },
            { label: "23c", start: 275.54, end: 281.95 },
            { label: "24", start: 282.2, end: 291.83 },
            { label: "25a", start: 292.08, end: 295.37 },
            { label: "25b", start: 295.62, end: 304.55 },
            { label: "26", start: 304.8, end: 313.73 },
            { label: "27", start: 313.98, end: 318.71 },
            { label: "28", start: 318.96, end: 330.89 },
            { label: "s4", start: 331.14, end: 338.07 },
            { label: "29", start: 338.32, end: 347.83 },
            { label: "30a", start: 348.08, end: 353.25 },
            { label: "30b", start: 353.5, end: 359.85 },
            { label: "30c", start: 360.1, end: 365.03 },
            { label: "31a", start: 365.28, end: 372.81 },
            { label: "31b", start: 373.06, end: 377.51 },
            { label: "32a", start: 377.76, end: 382.21 },
            { label: "32b", start: 382.46, end: 385.49 },
            { label: "33a", start: 385.74, end: 387.59 },
            { label: "33b", start: 387.84, end: 392.59 },
            { label: "34", start: 392.84, end: 403.39 }
        ],
        "ch21":[
            { label: "s1", start: 3, end: 9.91 },
            { label: "1", start: 10.16, end: 26.07 },
            { label: "2a", start: 26.32, end: 28.09 },
            { label: "2b", start: 28.34, end: 32.23 },
            { label: "2c", start: 32.48, end: 38.69 },
            { label: "2d", start: 38.94, end: 42.83 },
            { label: "3a", start: 43.08, end: 46.69 },
            { label: "3b", start: 46.94, end: 53.47 },
            { label: "4", start: 53.72, end: 62.49 },
            { label: "5a", start: 62.74, end: 65.15 },
            { label: "5b", start: 65.4, end: 67.51 },
            { label: "5c", start: 67.76, end: 70.73 },
            { label: "5d", start: 70.98, end: 73.17 },
            { label: "5e", start: 73.42, end: 76.07 },
            { label: "6", start: 76.32, end: 81.59 },
            { label: "7", start: 81.84, end: 92.91 },
            { label: "8", start: 93.16, end: 104.63 },
            { label: "9a", start: 104.88, end: 112.35 },
            { label: "9b", start: 112.6, end: 116.03 },
            { label: "9c", start: 116.28, end: 120.71 },
            { label: "9d", start: 120.96, end: 125.31 },
            { label: "10a", start: 125.56, end: 132.79 },
            { label: "10b", start: 133.04, end: 135.15 },
            { label: "11a", start: 135.4, end: 139.59 },
            { label: "11b", start: 139.84, end: 144.77 },
            { label: "s2", start: 145.02, end: 149.29 },
            { label: "12", start: 149.54, end: 164.13 },
            { label: "13a", start: 164.38, end: 167.15 },
            { label: "13b", start: 167.4, end: 178.15 },
            { label: "14", start: 178.4, end: 185.51 },
            { label: "15a", start: 185.76, end: 197.11 },
            { label: "15b", start: 197.36, end: 201.59 },
            { label: "16a", start: 201.84, end: 203.67 },
            { label: "16b", start: 203.92, end: 206.37 },
            { label: "16c", start: 206.62, end: 208.21 },
            { label: "16d", start: 208.46, end: 209.21 },
            { label: "16e", start: 209.46, end: 210.99 },
            { label: "16f", start: 211.24, end: 214.25 },
            { label: "16g", start: 214.5, end: 216.85 },
            { label: "17", start: 217.1, end: 225.57 },
            { label: "s3", start: 225.82, end: 230.95 },
            { label: "18", start: 231.2, end: 238.79 },
            { label: "19a", start: 239.04, end: 249.21 },
            { label: "19b", start: 249.46, end: 252.19 },
            { label: "19c", start: 252.44, end: 255.63 },
            { label: "19d", start: 255.88, end: 259.27 },
            { label: "20a", start: 259.52, end: 265.61 },
            { label: "20b", start: 265.86, end: 269.93 },
            { label: "21a", start: 270.18, end: 272.33 },
            { label: "21b", start: 272.58, end: 290.15 },
            { label: "22", start: 290.4, end: 296.89 },
            { label: "s4", start: 297.14, end: 303.59 },
            { label: "23a", start: 303.84, end: 316.59 },
            { label: "23b", start: 316.84, end: 320.01 },
            { label: "23c", start: 320.26, end: 322.95 },
            { label: "24a", start: 323.2, end: 325.03 },
            { label: "24b", start: 325.28, end: 327.93 },
            { label: "24c", start: 328.18, end: 334.63 },
            { label: "25a", start: 334.88, end: 339.31 },
            { label: "25b", start: 339.56, end: 342.73 },
            { label: "25c", start: 342.98, end: 346.87 },
            { label: "25d", start: 347.12, end: 352.27 },
            { label: "26", start: 352.52, end: 359.37 },
            { label: "27a", start: 359.62, end: 362.37 },
            { label: "27b", start: 362.62, end: 363.95 },
            { label: "27c", start: 364.2, end: 365.75 },
            { label: "27d", start: 366, end: 369.93 },
            { label: "s5", start: 370.18, end: 374.39 },
            { label: "28a", start: 374.64, end: 378.31 },
            { label: "28b", start: 378.56, end: 381.47 },
            { label: "28c", start: 381.72, end: 385.03 },
            { label: "28d", start: 385.28, end: 389.13 },
            { label: "29a", start: 389.38, end: 390.73 },
            { label: "29b", start: 390.98, end: 391.43 },
            { label: "29c", start: 391.68, end: 395.61 },
            { label: "30a", start: 395.86, end: 401.99 },
            { label: "30b", start: 402.24, end: 403.61 },
            { label: "30c", start: 403.86, end: 405.65 },
            { label: "31a", start: 405.9, end: 410.21 },
            { label: "31b", start: 410.46, end: 412.63 },
            { label: "31c", start: 412.88, end: 414.15 },
            { label: "31d", start: 414.4, end: 416.49 },
            { label: "31e", start: 416.74, end: 424.17 },
            { label: "32a", start: 424.42, end: 435.99 },
            { label: "32b", start: 436.24, end: 443.51 },
            { label: "s6", start: 443.76, end: 448.45 },
            { label: "33a", start: 448.7, end: 452.19 },
            { label: "33b", start: 452.44, end: 463.69 },
            { label: "33c", start: 463.94, end: 468.33 },
            { label: "33d", start: 468.58, end: 474.65 },
            { label: "34", start: 474.9, end: 484.57 },
            { label: "35", start: 484.82, end: 493.79 },
            { label: "36", start: 494.04, end: 504.77 },
            { label: "37", start: 505.02, end: 514.19 },
            { label: "38a", start: 514.44, end: 518.49 },
            { label: "38b", start: 518.74, end: 523.35 },
            { label: "38c", start: 523.6, end: 527.43 },
            { label: "39", start: 527.68, end: 533.43 },
            { label: "40", start: 533.68, end: 539.11 },
            { label: "41a", start: 539.36, end: 541.39 },
            { label: "41b", start: 541.64, end: 553.83 },
            { label: "42a", start: 554.08, end: 557.11 },
            { label: "42b", start: 557.36, end: 560.39 },
            { label: "42c", start: 560.64, end: 562.93 },
            { label: "42d", start: 563.18, end: 565.65 },
            { label: "42e", start: 565.9, end: 568.45 },
            { label: "42f", start: 568.7, end: 570.91 },
            { label: "43", start: 571.16, end: 579.59 },
            { label: "44", start: 579.84, end: 589.07 },
            { label: "45", start: 589.32, end: 599.49 },
            { label: "46", start: 599.74, end: 610.39 }
        ],
        "ch22":[],
        "ch23":[],
        "ch24":[],
        "ch25":[],
        "ch26":[],
        "ch27":[],
        "ch28":[]
    },
    "/GMV/GMV-43-LUK.html": {
        "ch1":[],
        "ch2":[],
        "ch3":[],
        "ch4":[],
        "ch5":[],
        "ch6":[],
        "ch7":[],
        "ch8":[],
        "ch9":[],
        "ch10":[],
        "ch11":[],
        "ch12":[],
        "ch13":[],
        "ch14":[],
        "ch15":[],
        "ch16":[],
        "ch17":[],
        "ch18":[],
        "ch19":[],
        "ch20":[],
        "ch21":[],
        "ch22":[],
        "ch23":[],
        "ch24":[],
    },
    "/GMV/GMV-44-JHN.html": {
        "ch1":[
            { label: "s1", start: 0, end: 3.01 },
            { label: "1", start: 3.26, end: 10.59 },
            { label: "2", start: 10.84, end: 13.01 },
            { label: "3", start: 13.26, end: 21.95 },
            { label: "4", start: 22.2, end: 26.59 },
            { label: "5", start: 26.84, end: 32.95 },
            { label: "6a", start: 33.2, end: 36.89 },
            { label: "6b", start: 37.14, end: 39.17 },
            { label: "7", start: 39.42, end: 46.77 },
            { label: "8", start: 47.02, end: 54.91 },
            { label: "9", start: 55.16, end: 61.73 },
            { label: "10", start: 61.98, end: 69.15 },
            { label: "11", start: 69.4, end: 73.45 },
            { label: "12", start: 73.7, end: 83.97 },
            { label: "13", start: 84.22, end: 96.41 },
            { label: "14", start: 96.66, end: 111.45 },
            { label: "15a", start: 111.7, end: 115.59 },
            { label: "15b", start: 115.84, end: 119.47 },
            { label: "15c", start: 119.72, end: 127.21 },
            { label: "16", start: 127.46, end: 133.59 },
            { label: "17", start: 133.84, end: 141.25 },
            { label: "18a", start: 141.5, end: 143.71 },
            { label: "18b", start: 143.96, end: 152.89 },
            { label: "s2", start: 153.14, end: 156.05 },
            { label: "19a", start: 156.3, end: 165.29 },
            { label: "19b", start: 165.54, end: 166.53 },
            { label: "20a", start: 166.78, end: 171.99 },
            { label: "20b", start: 172.24, end: 173.87 },
            { label: "21a", start: 174.12, end: 175.05 },
            { label: "21b", start: 175.3, end: 175.79 },
            { label: "21c", start: 176.04, end: 177.11 },
            { label: "21d", start: 177.36, end: 178.11 },
            { label: "21e", start: 178.36, end: 178.75 },
            { label: "21f", start: 179, end: 180.15 },
            { label: "21g", start: 180.4, end: 181.99 },
            { label: "21h", start: 182.24, end: 182.75 },
            { label: "21i", start: 183, end: 183.49 },
            { label: "22a", start: 183.74, end: 185.27 },
            { label: "22b", start: 185.52, end: 186.39 },
            { label: "22c", start: 186.64, end: 191.13 },
            { label: "22d", start: 191.38, end: 193.23 },
            { label: "23a", start: 193.48, end: 198.31 },
            { label: "23b", start: 198.56, end: 202.31 },
            { label: "23c", start: 202.56, end: 205.29 },
            { label: "24", start: 205.54, end: 209.47 },
            { label: "25a", start: 209.72, end: 211.01 },
            { label: "25b", start: 211.26, end: 217.65 },
            { label: "26a", start: 217.9, end: 220.05 },
            { label: "26b", start: 220.3, end: 226.55 },
            { label: "27", start: 226.8, end: 234.45 },
            { label: "28", start: 234.7, end: 241.01 },
            { label: "s3", start: 241.26, end: 243.59 },
            { label: "29a", start: 243.84, end: 249.79 },
            { label: "29b", start: 250.04, end: 253.53 },
            { label: "30a", start: 253.78, end: 256.51 },
            { label: "30b", start: 256.76, end: 263.55 },
            { label: "31", start: 263.8, end: 271.75 },
            { label: "32a", start: 272, end: 274.81 },
            { label: "32b", start: 275.06, end: 280.89 },
            { label: "33a", start: 281.14, end: 288.33 },
            { label: "33b", start: 288.58, end: 298.19 },
            { label: "34", start: 298.44, end: 303.63 },
            { label: "s4", start: 303.88, end: 306.77 },
            { label: "35", start: 307.02, end: 313.45 },
            { label: "36a", start: 313.7, end: 317.77 },
            { label: "36b", start: 318.02, end: 319.69 },
            { label: "37", start: 319.94, end: 326.37 },
            { label: "38a", start: 326.62, end: 331.11 },
            { label: "38b", start: 331.36, end: 332.53 },
            { label: "38c", start: 332.78, end: 333.65 },
            { label: "38d", start: 333.9, end: 334.71 },
            { label: "38e", start: 334.96, end: 337.31 },
            { label: "39a", start: 337.56, end: 338.45 },
            { label: "39b", start: 338.7, end: 340.27 },
            { label: "39c", start: 340.52, end: 347.01 },
            { label: "39d", start: 347.26, end: 350.97 },
            { label: "40", start: 351.22, end: 360.61 },
            { label: "41a", start: 360.86, end: 364.93 },
            { label: "41b", start: 365.18, end: 370.77 },
            { label: "42a", start: 371.02, end: 374.03 },
            { label: "42b", start: 374.28, end: 376.89 },
            { label: "42c", start: 377.14, end: 386.07 },
            { label: "s5", start: 386.32, end: 392.21 },
            { label: "43a", start: 392.46, end: 396.15 },
            { label: "43b", start: 396.4, end: 398.73 },
            { label: "43c", start: 398.98, end: 400.25 },
            { label: "44", start: 400.5, end: 405.65 },
            { label: "45a", start: 405.9, end: 408.37 },
            { label: "45b", start: 408.62, end: 415.65 },
            { label: "45c", start: 415.9, end: 419.81 },
            { label: "46a", start: 420.06, end: 421.71 },
            { label: "46b", start: 421.96, end: 425.21 },
            { label: "46c", start: 425.46, end: 426.53 },
            { label: "46d", start: 426.78, end: 428.15 },
            { label: "47a", start: 428.4, end: 432.81 },
            { label: "47b", start: 433.06, end: 440.01 },
            { label: "48a", start: 440.26, end: 441.59 },
            { label: "48b", start: 441.84, end: 443.13 },
            { label: "48c", start: 443.38, end: 444.71 },
            { label: "48d", start: 444.96, end: 450.15 },
            { label: "49a", start: 450.4, end: 451.69 },
            { label: "49b", start: 451.94, end: 453.59 },
            { label: "49c", start: 453.84, end: 455.81 },
            { label: "50a", start: 456.06, end: 457.87 },
            { label: "50b", start: 458.12, end: 462.69 },
            { label: "50c", start: 462.94, end: 465.15 },
            { label: "51a", start: 465.4, end: 466.59 },
            { label: "51b", start: 466.84, end: 477.35 }
        ],
        "ch2":[
            { label: "s1", start: 0, end: 2.33 },
            { label: "1", start: 2.58, end: 8.33 },
            { label: "2", start: 8.58, end: 12.15 },
            { label: "3a", start: 12.4, end: 16.25 },
            { label: "3b", start: 16.5, end: 18.03 },
            { label: "4a", start: 18.28, end: 19.61 },
            { label: "4b", start: 19.86, end: 21.67 },
            { label: "4c", start: 21.92, end: 23.51 },
            { label: "5a", start: 23.76, end: 25.61 },
            { label: "5b", start: 25.86, end: 27.65 },
            { label: "6", start: 27.9, end: 37.81 },
            { label: "7a", start: 38.06, end: 40.35 },
            { label: "7b", start: 40.6, end: 42.83 },
            { label: "7c", start: 43.08, end: 45.65 },
            { label: "8a", start: 45.9, end: 47.47 },
            { label: "8b", start: 47.72, end: 51.07 },
            { label: "8c", start: 51.32, end: 53.75 },
            { label: "9a", start: 54, end: 59.67 },
            { label: "9b", start: 59.92, end: 64.57 },
            { label: "10a", start: 64.82, end: 65.71 },
            { label: "10b", start: 65.96, end: 73.57 },
            { label: "10c", start: 73.82, end: 77.23 },
            { label: "11", start: 77.48, end: 86.67 },
            { label: "12", start: 86.92, end: 93.99 },
            { label: "s2", start: 94.24, end: 98.95 },
            { label: "13", start: 99.2, end: 104.33 },
            { label: "14", start: 104.58, end: 113.45 },
            { label: "15", start: 113.7, end: 124.35 },
            { label: "16a", start: 124.6, end: 126.21 },
            { label: "16b", start: 126.46, end: 130.95 },
            { label: "17a", start: 131.2, end: 135.57 },
            { label: "17b", start: 135.82, end: 139.35 },
            { label: "18a", start: 139.6, end: 142.79 },
            { label: "18b", start: 143.04, end: 147.55 },
            { label: "19a", start: 147.8, end: 149.67 },
            { label: "19b", start: 149.92, end: 154.45 },
            { label: "20a", start: 154.7, end: 155.71 },
            { label: "20b", start: 155.96, end: 162.51 },
            { label: "21", start: 162.76, end: 166.41 },
            { label: "22", start: 166.66, end: 176.53 },
            { label: "23", start: 176.78, end: 186.75 },
            { label: "24", start: 187, end: 192.09 },
            { label: "25", start: 192.34, end: 200.31 }
        ],
        "ch3":[
            { label: "s1", start: 0, end: 3.49 },
            { label: "1", start: 3.74, end: 9.23 },
            { label: "2a", start: 9.48, end: 12.91 },
            { label: "2b", start: 13.16, end: 25.21 },
            { label: "3a", start: 25.46, end: 27.01 },
            { label: "3b", start: 27.26, end: 33.63 },
            { label: "4a", start: 33.88, end: 35.65 },
            { label: "4b", start: 35.9, end: 39.13 },
            { label: "4c", start: 39.38, end: 43.49 },
            { label: "5a", start: 43.74, end: 44.83 },
            { label: "5b", start: 45.08, end: 52.57 },
            { label: "6", start: 52.82, end: 58.91 },
            { label: "7", start: 59.16, end: 62.47 },
            { label: "8a", start: 62.72, end: 70.63 },
            { label: "8b", start: 70.88, end: 74.15 },
            { label: "9a", start: 74.4, end: 76.65 },
            { label: "9b", start: 76.9, end: 78.63 },
            { label: "10a", start: 78.88, end: 80.55 },
            { label: "10b", start: 80.8, end: 83.99 },
            { label: "11", start: 84.24, end: 93.31 },
            { label: "12", start: 93.56, end: 101.69 },
            { label: "13", start: 101.94, end: 108.99 },
            { label: "14", start: 109.24, end: 122.57 },
            { label: "15", start: 122.82, end: 127.13 },
            { label: "16", start: 127.38, end: 138.37 },
            { label: "17", start: 138.62, end: 148.47 },
            { label: "18", start: 148.72, end: 159.15 },
            { label: "19", start: 159.4, end: 168.49 },
            { label: "20", start: 168.74, end: 177.91 },
            { label: "21", start: 178.16, end: 187.13 },
            { label: "s2", start: 187.38, end: 192.03 },
            { label: "22", start: 192.28, end: 201.61 },
            { label: "23", start: 201.86, end: 211.73 },
            { label: "24", start: 211.98, end: 216.09 },
            { label: "25", start: 216.34, end: 222.59 },
            { label: "26a", start: 222.84, end: 225.81 },
            { label: "26b", start: 226.06, end: 235.81 },
            { label: "27a", start: 236.06, end: 237.57 },
            { label: "27b", start: 237.82, end: 242.45 },
            { label: "28", start: 242.7, end: 249.67 },
            { label: "29a", start: 249.92, end: 259.41 },
            { label: "29b", start: 259.66, end: 261.75 },
            { label: "30", start: 262, end: 265.87 },
            { label: "31a", start: 266.12, end: 274.29 },
            { label: "31b", start: 274.54, end: 277.55 },
            { label: "32", start: 277.8, end: 284.11 },
            { label: "33", start: 284.36, end: 289.31 },
            { label: "34", start: 289.56, end: 297.91 },
            { label: "35", start: 298.16, end: 302.23 },
            { label: "36", start: 302.48, end: 315.19 }
        ],
        "ch4":[
            { label: "s1", start: 0, end: 2.39 },
            { label: "1", start: 2.64, end: 10.89 },
            { label: "2", start: 11.14, end: 14.35 },
            { label: "3", start: 14.6, end: 18.69 },
            { label: "4", start: 18.94, end: 20.85 },
            { label: "5a", start: 21.1, end: 25.99 },
            { label: "5b", start: 26.24, end: 30.99 },
            { label: "6a", start: 31.24, end: 37.35 },
            { label: "6b", start: 37.6, end: 40.85 },
            { label: "7a", start: 41.1, end: 45.43 },
            { label: "7b", start: 45.68, end: 47.21 },
            { label: "7c", start: 47.46, end: 48.95 },
            { label: "8", start: 49.2, end: 53.23 },
            { label: "9a", start: 53.48, end: 55.35 },
            { label: "9b", start: 55.6, end: 60.17 },
            { label: "9c", start: 60.42, end: 64.55 },
            { label: "10a", start: 64.8, end: 66.61 },
            { label: "10b", start: 66.86, end: 77.51 },
            { label: "11a", start: 77.76, end: 79.19 },
            { label: "11b", start: 79.44, end: 84.63 },
            { label: "12", start: 84.88, end: 94.85 },
            { label: "13a", start: 95.1, end: 96.19 },
            { label: "13b", start: 96.44, end: 99.07 },
            { label: "14a", start: 99.32, end: 103.95 },
            { label: "14b", start: 104.2, end: 111.59 },
            { label: "15a", start: 111.84, end: 112.75 },
            { label: "15b", start: 113, end: 118.41 },
            { label: "16a", start: 118.66, end: 119.73 },
            { label: "16b", start: 119.98, end: 121.93 },
            { label: "17a", start: 122.18, end: 123.05 },
            { label: "17b", start: 123.3, end: 124.35 },
            { label: "17c", start: 124.6, end: 125.85 },
            { label: "17d", start: 126.1, end: 127.53 },
            { label: "18a", start: 127.78, end: 132.21 },
            { label: "18b", start: 132.46, end: 134.69 },
            { label: "19a", start: 134.94, end: 136.31 },
            { label: "19b", start: 136.56, end: 139.79 },
            { label: "20", start: 140.04, end: 148.13 },
            { label: "21a", start: 148.38, end: 149.83 },
            { label: "21b", start: 150.08, end: 157.33 },
            { label: "22", start: 157.58, end: 167.95 },
            { label: "23", start: 168.2, end: 180.51 },
            { label: "24", start: 180.76, end: 186.49 },
            { label: "25a", start: 186.74, end: 188.27 },
            { label: "25b", start: 188.52, end: 192.29 },
            { label: "25c", start: 192.54, end: 195.25 },
            { label: "26a", start: 195.5, end: 196.65 },
            { label: "26b", start: 196.9, end: 199.97 },
            { label: "27a", start: 200.22, end: 207.77 },
            { label: "27b", start: 208.02, end: 209.57 },
            { label: "27c", start: 209.82, end: 210.57 },
            { label: "27d", start: 210.82, end: 213.67 },
            { label: "28", start: 213.92, end: 217.61 },
            { label: "29a", start: 217.86, end: 221.51 },
            { label: "29b", start: 221.76, end: 223.69 },
            { label: "30", start: 223.94, end: 227.93 },
            { label: "31a", start: 228.18, end: 231.75 },
            { label: "31b", start: 232, end: 233.37 },
            { label: "32a", start: 233.62, end: 235.13 },
            { label: "32b", start: 235.38, end: 239.05 },
            { label: "33a", start: 239.3, end: 241.57 },
            { label: "33b", start: 241.82, end: 244.35 },
            { label: "34a", start: 244.6, end: 246.51 },
            { label: "34b", start: 246.76, end: 253.85 },
            { label: "35a", start: 254.1, end: 256.19 },
            { label: "35b", start: 256.44, end: 259.73 },
            { label: "35c", start: 259.98, end: 266.55 },
            { label: "36", start: 266.8, end: 276.49 },
            { label: "37a", start: 276.74, end: 278.49 },
            { label: "37b", start: 278.74, end: 282.13 },
            { label: "38a", start: 282.38, end: 287.47 },
            { label: "38b", start: 287.72, end: 293.31 },
            { label: "39a", start: 293.56, end: 296.39 },
            { label: "39b", start: 296.64, end: 304.45 },
            { label: "40a", start: 304.7, end: 310.23 },
            { label: "40b", start: 310.48, end: 312.25 },
            { label: "41", start: 312.5, end: 316.91 },
            { label: "42a", start: 317.16, end: 318.67 },
            { label: "42b", start: 318.92, end: 329.07 },
            { label: "s2", start: 329.32, end: 332.31 },
            { label: "43", start: 332.56, end: 336.05 },
            { label: "44a", start: 336.3, end: 337.79 },
            { label: "44b", start: 338.04, end: 340.73 },
            { label: "45", start: 340.98, end: 350.85 },
            { label: "s3", start: 351.1, end: 356.75 },
            { label: "46a", start: 357, end: 362.91 },
            { label: "46b", start: 363.16, end: 369.75 },
            { label: "47", start: 370, end: 378.83 },
            { label: "48a", start: 379.08, end: 380.39 },
            { label: "48b", start: 380.64, end: 384.49 },
            { label: "49a", start: 384.74, end: 385.89 },
            { label: "49b", start: 386.14, end: 389.43 },
            { label: "50a", start: 389.68, end: 391.05 },
            { label: "50b", start: 391.3, end: 393.15 },
            { label: "50c", start: 393.4, end: 396.91 },
            { label: "51a", start: 397.16, end: 401.27 },
            { label: "51b", start: 401.52, end: 403.49 },
            { label: "52a", start: 403.74, end: 404.79 },
            { label: "52b", start: 405.04, end: 406.91 },
            { label: "52c", start: 407.16, end: 407.51 },
            { label: "52d", start: 407.76, end: 412.01 },
            { label: "53a", start: 412.26, end: 417.31 },
            { label: "53b", start: 417.56, end: 419.19 },
            { label: "53c", start: 419.44, end: 422.83 },
            { label: "54", start: 423.08, end: 430.23 }
        ],
        "ch5":[
            { label: "s1", start: 0, end: 2.41 },
            { label: "1", start: 2.66, end: 7.95 },
            { label: "2", start: 8.2, end: 18.99 },
            { label: "3", start: 19.24, end: 26.61 },
            { label: "4a", start: 26.86, end: 33.83 },
            { label: "4b", start: 34.08, end: 43.19 },
            { label: "5", start: 43.44, end: 47.41 },
            { label: "6a", start: 47.66, end: 52.99 },
            { label: "6b", start: 53.24, end: 54.39 },
            { label: "6c", start: 54.64, end: 58.49 },
            { label: "7a", start: 58.74, end: 60.25 },
            { label: "7b", start: 60.5, end: 66.07 },
            { label: "7c", start: 66.32, end: 71.63 },
            { label: "8a", start: 71.88, end: 73.33 },
            { label: "8b", start: 73.58, end: 76.93 },
            { label: "9a", start: 77.18, end: 85.03 },
            { label: "9b", start: 85.28, end: 87.71 },
            { label: "10a", start: 87.96, end: 92.65 },
            { label: "10b", start: 92.9, end: 96.91 },
            { label: "11a", start: 97.16, end: 98.21 },
            { label: "11b", start: 98.46, end: 102.67 },
            { label: "12a", start: 102.92, end: 104.21 },
            { label: "12b", start: 104.46, end: 108.03 },
            { label: "13a", start: 108.28, end: 112.49 },
            { label: "13b", start: 112.74, end: 116.37 },
            { label: "14a", start: 116.62, end: 121.71 },
            { label: "14b", start: 121.96, end: 128.33 },
            { label: "15a", start: 128.58, end: 131.73 },
            { label: "15b", start: 131.98, end: 135.11 },
            { label: "s2", start: 135.36, end: 140.47 },
            { label: "16", start: 140.72, end: 147.03 },
            { label: "17a", start: 147.28, end: 148.27 },
            { label: "17b", start: 148.52, end: 151.05 },
            { label: "18", start: 151.3, end: 163.61 },
            { label: "19a", start: 163.86, end: 165.59 },
            { label: "19b", start: 165.84, end: 175.07 },
            { label: "19c", start: 175.32, end: 178.79 },
            { label: "20", start: 179.04, end: 189.19 },
            { label: "21", start: 189.44, end: 197.37 },
            { label: "22", start: 197.62, end: 204.69 },
            { label: "23a", start: 204.94, end: 208.49 },
            { label: "23b", start: 208.74, end: 214.23 },
            { label: "24", start: 214.48, end: 230.27 },
            { label: "25", start: 230.52, end: 240.47 },
            { label: "26", start: 240.72, end: 249.59 },
            { label: "27", start: 249.84, end: 254.81 },
            { label: "28", start: 255.06, end: 263.73 },
            { label: "29a", start: 263.98, end: 270.13 },
            { label: "29b", start: 270.38, end: 276.17 },
            { label: "30", start: 276.42, end: 292.19 },
            { label: "s3", start: 292.44, end: 293.27 },
            { label: "31", start: 293.52, end: 298.29 },
            { label: "32", start: 298.54, end: 306.63 },
            { label: "33", start: 306.88, end: 312.11 },
            { label: "34", start: 312.36, end: 319.63 },
            { label: "35", start: 319.88, end: 326.85 },
            { label: "36", start: 327.1, end: 339.83 },
            { label: "37a", start: 340.08, end: 344.27 },
            { label: "37b", start: 344.52, end: 349.09 },
            { label: "38a", start: 349.34, end: 352.41 },
            { label: "38b", start: 352.66, end: 355.81 },
            { label: "39", start: 356.06, end: 368.47 },
            { label: "40", start: 368.72, end: 373.77 },
            { label: "41", start: 374.02, end: 376.03 },
            { label: "42", start: 376.28, end: 381.35 },
            { label: "43a", start: 381.6, end: 386.31 },
            { label: "43b", start: 386.56, end: 390.97 },
            { label: "44a", start: 391.22, end: 395.85 },
            { label: "44b", start: 396.1, end: 400.95 },
            { label: "45a", start: 401.2, end: 405.05 },
            { label: "45b", start: 405.3, end: 410.81 },
            { label: "46", start: 411.06, end: 417.55 },
            { label: "47", start: 417.8, end: 423.99 }
        ],
        "ch6":[
            { label: "s1", start: 0, end: 4.01 },
            { label: "1", start: 4.26, end: 11.41 },
            { label: "2", start: 11.66, end: 18.47 },
            { label: "3", start: 18.72, end: 23.85 },
            { label: "4", start: 24.1, end: 26.89 },
            { label: "5a", start: 27.14, end: 33.89 },
            { label: "5b", start: 34.14, end: 36.47 },
            { label: "6", start: 36.72, end: 42.35 },
            { label: "7a", start: 42.6, end: 44.21 },
            { label: "7b", start: 44.46, end: 50.69 },
            { label: "7c", start: 50.94, end: 56.11 },
            { label: "8", start: 56.36, end: 62.75 },
            { label: "9", start: 63, end: 70.85 },
            { label: "10a", start: 71.1, end: 72.71 },
            { label: "10b", start: 72.96, end: 74.05 },
            { label: "10c", start: 74.3, end: 76.55 },
            { label: "10d", start: 76.8, end: 80.33 },
            { label: "11", start: 80.58, end: 94.03 },
            { label: "12a", start: 94.28, end: 96.87 },
            { label: "12b", start: 97.12, end: 101.73 },
            { label: "13", start: 101.98, end: 111.47 },
            { label: "14a", start: 111.72, end: 115.81 },
            { label: "14b", start: 116.06, end: 121.15 },
            { label: "15", start: 121.4, end: 130.59 },
            { label: "s2", start: 130.84, end: 134.39 },
            { label: "16", start: 134.64, end: 139.31 },
            { label: "17", start: 139.56, end: 149.57 },
            { label: "18", start: 149.82, end: 156.27 },
            { label: "19a", start: 156.52, end: 164.87 },
            { label: "19b", start: 165.12, end: 167.05 },
            { label: "20a", start: 167.3, end: 168.93 },
            { label: "20b", start: 169.18, end: 170.79 },
            { label: "21", start: 171.04, end: 177.09 },
            { label: "s3", start: 177.34, end: 180.49 },
            { label: "22", start: 180.74, end: 196.75 },
            { label: "23", start: 197, end: 205.89 },
            { label: "24", start: 206.14, end: 216.51 },
            { label: "25a", start: 216.76, end: 221.49 },
            { label: "25b", start: 221.74, end: 224.01 },
            { label: "26a", start: 224.26, end: 225.39 },
            { label: "26b", start: 225.64, end: 235.29 },
            { label: "27a", start: 235.54, end: 246.65 },
            { label: "27b", start: 246.9, end: 251.65 },
            { label: "28a", start: 251.9, end: 253.71 },
            { label: "28b", start: 253.96, end: 257.39 },
            { label: "29a", start: 257.64, end: 259.27 },
            { label: "29b", start: 259.52, end: 266.29 },
            { label: "30a", start: 266.54, end: 267.59 },
            { label: "30b", start: 267.84, end: 272.15 },
            { label: "30c", start: 272.4, end: 273.23 },
            { label: "31a", start: 273.48, end: 281.13 },
            { label: "31b", start: 281.38, end: 284.89 },
            { label: "32a", start: 285.14, end: 286.45 },
            { label: "32b", start: 286.7, end: 296.93 },
            { label: "33", start: 297.18, end: 303.59 },
            { label: "34a", start: 303.84, end: 305.51 },
            { label: "34b", start: 305.76, end: 308.19 },
            { label: "35a", start: 308.44, end: 309.89 },
            { label: "35b", start: 310.14, end: 319.05 },
            { label: "36", start: 319.3, end: 324.81 },
            { label: "37", start: 325.06, end: 333.79 },
            { label: "38", start: 334.04, end: 343.91 },
            { label: "39", start: 344.16, end: 355.57 },
            { label: "40", start: 355.82, end: 367.27 },
            { label: "41a", start: 367.52, end: 372.65 },
            { label: "41b", start: 372.9, end: 375.87 },
            { label: "42a", start: 376.12, end: 376.97 },
            { label: "42b", start: 377.22, end: 382.29 },
            { label: "42c", start: 382.54, end: 385.71 },
            { label: "43a", start: 385.96, end: 387.63 },
            { label: "43b", start: 387.88, end: 389.83 },
            { label: "44a", start: 390.08, end: 395.97 },
            { label: "44b", start: 396.22, end: 398.51 },
            { label: "45a", start: 398.76, end: 400.85 },
            { label: "45b", start: 401.1, end: 403.17 },
            { label: "45c", start: 403.42, end: 408.61 },
            { label: "46a", start: 408.86, end: 412.63 },
            { label: "46b", start: 412.88, end: 414.29 },
            { label: "47", start: 414.54, end: 420.47 },
            { label: "48", start: 420.72, end: 422.89 },
            { label: "49", start: 423.14, end: 428.97 },
            { label: "50", start: 429.22, end: 434.57 },
            { label: "51a", start: 434.82, end: 439.41 },
            { label: "51b", start: 439.66, end: 442.89 },
            { label: "51c", start: 443.14, end: 447.69 },
            { label: "52a", start: 447.94, end: 452.33 },
            { label: "52b", start: 452.58, end: 455.59 },
            { label: "53a", start: 455.84, end: 457.31 },
            { label: "53b", start: 457.56, end: 466.65 },
            { label: "54", start: 466.9, end: 475.37 },
            { label: "55", start: 475.62, end: 481.21 },
            { label: "56", start: 481.46, end: 488.57 },
            { label: "57", start: 488.82, end: 497.79 },
            { label: "58a", start: 498.04, end: 505.71 },
            { label: "58b", start: 505.96, end: 510.15 },
            { label: "59", start: 510.4, end: 516.89 },
            { label: "s4", start: 517.14, end: 521.53 },
            { label: "60a", start: 521.78, end: 525.97 },
            { label: "60b", start: 526.22, end: 527.93 },
            { label: "60c", start: 528.18, end: 529.07 },
            { label: "61a", start: 529.32, end: 535.99 },
            { label: "61b", start: 536.24, end: 537.59 },
            { label: "61c", start: 537.84, end: 540.31 },
            { label: "62", start: 540.56, end: 546.37 },
            { label: "63a", start: 546.62, end: 548.29 },
            { label: "63b", start: 548.54, end: 550.45 },
            { label: "63c", start: 550.7, end: 555.13 },
            { label: "64a", start: 555.38, end: 558.75 },
            { label: "64b", start: 559, end: 566.17 },
            { label: "65a", start: 566.42, end: 567.65 },
            { label: "65b", start: 567.9, end: 576.15 },
            { label: "66", start: 576.4, end: 582.07 },
            { label: "67a", start: 582.32, end: 587.45 },
            { label: "67b", start: 587.7, end: 589.63 },
            { label: "68a", start: 589.88, end: 591.77 },
            { label: "68b", start: 592.02, end: 594.05 },
            { label: "68c", start: 594.3, end: 597.03 },
            { label: "69", start: 597.28, end: 603.61 },
            { label: "70a", start: 603.86, end: 606.05 },
            { label: "70b", start: 606.3, end: 609.37 },
            { label: "70c", start: 609.62, end: 612.85 },
            { label: "71", start: 613.1, end: 624.95 }
        ],
        "ch7":[
            { label: "s1", start: 0, end: 3.17 },
            { label: "1", start: 3.42, end: 12.71 },
            { label: "2", start: 12.96, end: 17.57 },
            { label: "3a", start: 17.82, end: 19.79 },
            { label: "3b", start: 20.04, end: 25.41 },
            { label: "4a", start: 25.66, end: 29.67 },
            { label: "4b", start: 29.92, end: 32.71 },
            { label: "5", start: 32.96, end: 37.09 },
            { label: "6a", start: 37.34, end: 39.27 },
            { label: "6b", start: 39.52, end: 43.41 },
            { label: "7", start: 43.66, end: 51.15 },
            { label: "8a", start: 51.4, end: 53.55 },
            { label: "8b", start: 53.8, end: 57.49 },
            { label: "9", start: 57.74, end: 60.73 },
            { label: "10", start: 60.98, end: 67.87 },
            { label: "11a", start: 68.12, end: 72.73 },
            { label: "11b", start: 72.98, end: 74.45 },
            { label: "12a", start: 74.7, end: 77.93 },
            { label: "12b", start: 78.18, end: 79.25 },
            { label: "12c", start: 79.5, end: 82.75 },
            { label: "12d", start: 83, end: 85.39 },
            { label: "13", start: 85.64, end: 92.99 },
            { label: "s2", start: 93.24, end: 97.43 },
            { label: "14", start: 97.68, end: 103.93 },
            { label: "15a", start: 104.18, end: 107.17 },
            { label: "15b", start: 107.42, end: 111.93 },
            { label: "16a", start: 112.18, end: 114.05 },
            { label: "16b", start: 114.3, end: 118.99 },
            { label: "17", start: 119.24, end: 127.89 },
            { label: "18", start: 128.14, end: 139.59 },
            { label: "19a", start: 139.84, end: 142.93 },
            { label: "19b", start: 143.18, end: 146.23 },
            { label: "19c", start: 146.48, end: 148.21 },
            { label: "20a", start: 148.46, end: 149.79 },
            { label: "20b", start: 150.04, end: 153.29 },
            { label: "21a", start: 153.54, end: 155.33 },
            { label: "21b", start: 155.58, end: 160.33 },
            { label: "22", start: 160.58, end: 173.03 },
            { label: "23", start: 173.28, end: 184.89 },
            { label: "24", start: 185.14, end: 190.69 },
            { label: "s3", start: 190.94, end: 195.47 },
            { label: "25a", start: 195.72, end: 198.85 },
            { label: "25b", start: 199.1, end: 202.43 },
            { label: "26a", start: 202.68, end: 206.23 },
            { label: "26b", start: 206.48, end: 213.01 },
            { label: "27", start: 213.26, end: 219.53 },
            { label: "28a", start: 219.78, end: 224.53 },
            { label: "28b", start: 224.78, end: 230.43 },
            { label: "28c", start: 230.68, end: 234.91 },
            { label: "29", start: 235.16, end: 240.63 },
            { label: "30", start: 240.88, end: 249.95 },
            { label: "31a", start: 250.2, end: 253.27 },
            { label: "31b", start: 253.52, end: 254.67 },
            { label: "31c", start: 254.92, end: 258.79 },
            { label: "32a", start: 259.04, end: 264.69 },
            { label: "32b", start: 264.94, end: 272.37 },
            { label: "33a", start: 272.62, end: 274.19 },
            { label: "33b", start: 274.44, end: 280.85 },
            { label: "34", start: 281.1, end: 287.95 },
            { label: "35a", start: 288.2, end: 290.45 },
            { label: "35b", start: 290.7, end: 293.63 },
            { label: "35c", start: 293.88, end: 298.23 },
            { label: "36", start: 298.48, end: 310.95 },
            { label: "s4", start: 311.2, end: 316.25 },
            { label: "37a", start: 316.5, end: 322.67 },
            { label: "37b", start: 322.92, end: 326.31 },
            { label: "38", start: 326.56, end: 334.23 },
            { label: "39a", start: 334.48, end: 341.23 },
            { label: "39b", start: 341.48, end: 346.95 },
            { label: "s5", start: 347.2, end: 352.65 },
            { label: "40a", start: 352.9, end: 356.97 },
            { label: "40b", start: 357.22, end: 360.45 },
            { label: "41a", start: 360.7, end: 361.97 },
            { label: "41b", start: 362.22, end: 363.43 },
            { label: "41c", start: 363.68, end: 365.71 },
            { label: "41d", start: 365.96, end: 367.63 },
            { label: "42", start: 367.88, end: 377.73 },
            { label: "43", start: 377.98, end: 381.93 },
            { label: "44", start: 382.18, end: 388.27 },
            { label: "s6", start: 388.52, end: 391.71 },
            { label: "45a", start: 391.96, end: 397.03 },
            { label: "45b", start: 397.28, end: 400.69 },
            { label: "45c", start: 400.94, end: 402.31 },
            { label: "46a", start: 402.56, end: 403.97 },
            { label: "46b", start: 404.22, end: 407.11 },
            { label: "47a", start: 407.36, end: 408.81 },
            { label: "47b", start: 409.06, end: 410.85 },
            { label: "48", start: 411.1, end: 415.19 },
            { label: "49a", start: 415.44, end: 416.45 },
            { label: "49b", start: 416.7, end: 421.17 },
            { label: "50", start: 421.42, end: 426.15 },
            { label: "51", start: 426.4, end: 433.59 },
            { label: "52a", start: 433.84, end: 435.35 },
            { label: "52b", start: 435.6, end: 437.09 },
            { label: "52c", start: 437.34, end: 440.95 },
            { label: "53", start: 441.2, end: 444.39 }
        ],
        "ch8":[
            { label: "1", start: 0, end: 1.57 },
            { label: "s1", start: 1.82, end: 5.39 },
            { label: "2a", start: 5.64, end: 10.11 },
            { label: "2b", start: 10.36, end: 16.21 },
            { label: "3", start: 16.46, end: 24.89 },
            { label: "4a", start: 25.14, end: 26.49 },
            { label: "4b", start: 26.74, end: 29.85 },
            { label: "5a", start: 30.1, end: 35.31 },
            { label: "5b", start: 35.56, end: 36.81 },
            { label: "6a", start: 37.06, end: 42.69 },
            { label: "6b", start: 42.94, end: 48.25 },
            { label: "7a", start: 48.5, end: 54.13 },
            { label: "7b", start: 54.38, end: 59.09 },
            { label: "8", start: 59.34, end: 63.23 },
            { label: "9", start: 63.48, end: 73.53 },
            { label: "10a", start: 73.78, end: 76.77 },
            { label: "10b", start: 77.02, end: 79.31 },
            { label: "10c", start: 79.56, end: 81.39 },
            { label: "11a", start: 81.64, end: 82.53 },
            { label: "11b", start: 82.78, end: 83.99 },
            { label: "11c", start: 84.24, end: 85.39 },
            { label: "11d", start: 85.64, end: 87.07 },
            { label: "11e", start: 87.32, end: 90.09 },
            { label: "s2", start: 90.34, end: 93.59 },
            { label: "12a", start: 93.84, end: 98.31 },
            { label: "12b", start: 98.56, end: 108.11 },
            { label: "13a", start: 108.36, end: 110.43 },
            { label: "13b", start: 110.68, end: 114.73 },
            { label: "14a", start: 114.98, end: 116.89 },
            { label: "14b", start: 117.14, end: 128.89 },
            { label: "15", start: 129.14, end: 134.99 },
            { label: "16", start: 135.24, end: 143.69 },
            { label: "17", start: 143.94, end: 149.25 },
            { label: "18", start: 149.5, end: 154.93 },
            { label: "19a", start: 155.18, end: 157.23 },
            { label: "19b", start: 157.48, end: 159.09 },
            { label: "19c", start: 159.34, end: 160.79 },
            { label: "19d", start: 161.04, end: 163.35 },
            { label: "19e", start: 163.6, end: 166.45 },
            { label: "20", start: 166.7, end: 176.85 },
            { label: "s3", start: 177.1, end: 181.13 },
            { label: "21a", start: 181.38, end: 184.47 },
            { label: "21b", start: 184.72, end: 190.49 },
            { label: "21c", start: 190.74, end: 193.61 },
            { label: "22a", start: 193.86, end: 195.51 },
            { label: "22b", start: 195.76, end: 198.59 },
            { label: "22c", start: 198.84, end: 201.21 },
            { label: "23a", start: 201.46, end: 202.77 },
            { label: "23b", start: 203.02, end: 205.55 },
            { label: "23c", start: 205.8, end: 209.05 },
            { label: "24a", start: 209.3, end: 213.27 },
            { label: "24b", start: 213.52, end: 218.33 },
            { label: "25a", start: 218.58, end: 219.79 },
            { label: "25b", start: 220.04, end: 221.19 },
            { label: "25c", start: 221.44, end: 222.85 },
            { label: "25d", start: 223.1, end: 225.09 },
            { label: "26", start: 225.34, end: 237.13 },
            { label: "27", start: 237.38, end: 241.39 },
            { label: "28a", start: 241.64, end: 243.81 },
            { label: "28b", start: 244.06, end: 256.23 },
            { label: "29a", start: 256.48, end: 259.67 },
            { label: "29b", start: 259.92, end: 266.95 },
            { label: "30", start: 267.2, end: 271.37 },
            { label: "s4", start: 271.62, end: 274.47 },
            { label: "31a", start: 274.72, end: 278.93 },
            { label: "31b", start: 279.18, end: 283.35 },
            { label: "32", start: 283.6, end: 287.73 },
            { label: "33a", start: 287.98, end: 289.25 },
            { label: "33b", start: 289.5, end: 293.57 },
            { label: "33c", start: 293.82, end: 296.35 },
            { label: "34a", start: 296.6, end: 297.77 },
            { label: "34b", start: 298.02, end: 302.79 },
            { label: "35", start: 303.04, end: 309.53 },
            { label: "36", start: 309.78, end: 314.79 },
            { label: "s5", start: 315.04, end: 317.95 },
            { label: "37a", start: 318.2, end: 320.53 },
            { label: "37b", start: 320.78, end: 326.85 },
            { label: "38", start: 327.1, end: 334.67 },
            { label: "39a", start: 334.92, end: 335.77 },
            { label: "39b", start: 336.02, end: 337.79 },
            { label: "39c", start: 338.04, end: 339.13 },
            { label: "39d", start: 339.38, end: 343.73 },
            { label: "40a", start: 343.98, end: 352.49 },
            { label: "40b", start: 352.74, end: 354.75 },
            { label: "41", start: 355, end: 356.31 },
            { label: "s6", start: 356.56, end: 358.93 },
            { label: "41a", start: 359.18, end: 360.47 },
            { label: "41b", start: 360.72, end: 362.53 },
            { label: "41c", start: 362.78, end: 364.91 },
            { label: "42a", start: 365.16, end: 366.55 },
            { label: "42b", start: 366.8, end: 373.41 },
            { label: "42c", start: 373.66, end: 377.25 },
            { label: "43a", start: 377.5, end: 379.57 },
            { label: "43b", start: 379.82, end: 382.43 },
            { label: "44a", start: 382.68, end: 388.43 },
            { label: "44b", start: 388.68, end: 394.97 },
            { label: "44c", start: 395.22, end: 398.67 },
            { label: "44d", start: 398.92, end: 401.33 },
            { label: "45", start: 401.58, end: 406.55 },
            { label: "46a", start: 406.8, end: 409.89 },
            { label: "46b", start: 410.14, end: 414.31 },
            { label: "47", start: 414.56, end: 421.31 },
            { label: "s7", start: 421.56, end: 423.35 },
            { label: "48a", start: 423.6, end: 425.01 },
            { label: "48b", start: 425.26, end: 429.29 },
            { label: "49a", start: 429.54, end: 430.85 },
            { label: "49b", start: 431.1, end: 434.63 },
            { label: "49c", start: 434.88, end: 437.31 },
            { label: "50a", start: 437.56, end: 439.45 },
            { label: "50b", start: 439.7, end: 444.51 },
            { label: "51", start: 444.76, end: 450.51 },
            { label: "52a", start: 450.76, end: 452.27 },
            { label: "52b", start: 452.52, end: 455.61 },
            { label: "52c", start: 455.86, end: 459.21 },
            { label: "52d", start: 459.46, end: 462.13 },
            { label: "53a", start: 462.38, end: 465.63 },
            { label: "53b", start: 465.88, end: 469.77 },
            { label: "54a", start: 470.02, end: 471.23 },
            { label: "54b", start: 471.48, end: 475.29 },
            { label: "54c", start: 475.54, end: 481.37 },
            { label: "55a", start: 481.62, end: 485.85 },
            { label: "55b", start: 486.1, end: 490.27 },
            { label: "55c", start: 490.52, end: 494.39 },
            { label: "56a", start: 494.64, end: 498.49 },
            { label: "56b", start: 498.74, end: 501.55 },
            { label: "57a", start: 501.8, end: 503.27 },
            { label: "57b", start: 503.52, end: 506.69 },
            { label: "58a", start: 506.94, end: 508.43 },
            { label: "58b", start: 508.68, end: 514.31 },
            { label: "59a", start: 514.56, end: 517.37 },
            { label: "59b", start: 517.62, end: 522.71 }
        ],
        "ch9":[
            { label: "s1", start: 0, end: 2.55 },
            { label: "1", start: 2.8, end: 6.95 },
            { label: "2a", start: 7.2, end: 9.27 },
            { label: "2b", start: 9.52, end: 13.67 },
            { label: "2c", start: 13.92, end: 16.49 },
            { label: "3a", start: 16.74, end: 17.79 },
            { label: "3b", start: 18.04, end: 25.23 },
            { label: "4a", start: 25.48, end: 30.19 },
            { label: "4b", start: 30.44, end: 34.51 },
            { label: "5", start: 34.76, end: 38.81 },
            { label: "6", start: 39.06, end: 47.37 },
            { label: "7a", start: 47.62, end: 48.89 },
            { label: "7b", start: 49.14, end: 55.65 },
            { label: "7c", start: 55.9, end: 61.29 },
            { label: "8a", start: 61.54, end: 67.13 },
            { label: "8b", start: 67.38, end: 70.75 },
            { label: "9a", start: 71, end: 72.43 },
            { label: "9b", start: 72.68, end: 73.43 },
            { label: "9c", start: 73.68, end: 74.63 },
            { label: "9d", start: 74.88, end: 76.05 },
            { label: "9e", start: 76.3, end: 79.09 },
            { label: "9f", start: 79.34, end: 80.23 },
            { label: "10a", start: 80.48, end: 81.91 },
            { label: "10b", start: 82.16, end: 84.03 },
            { label: "11a", start: 84.28, end: 85.25 },
            { label: "11b", start: 85.5, end: 93.31 },
            { label: "11c", start: 93.56, end: 97.17 },
            { label: "12a", start: 97.42, end: 98.59 },
            { label: "12b", start: 98.84, end: 99.89 },
            { label: "12c", start: 100.14, end: 100.87 },
            { label: "12d", start: 101.12, end: 101.89 },
            { label: "13", start: 102.14, end: 106.63 },
            { label: "14", start: 106.88, end: 112.17 },
            { label: "15a", start: 112.42, end: 117.15 },
            { label: "15b", start: 117.4, end: 118.23 },
            { label: "15c", start: 118.48, end: 123.37 },
            { label: "16a", start: 123.62, end: 125.99 },
            { label: "16b", start: 126.24, end: 127.23 },
            { label: "16c", start: 127.48, end: 129.91 },
            { label: "16d", start: 130.16, end: 131.63 },
            { label: "16e", start: 131.88, end: 135.59 },
            { label: "16f", start: 135.84, end: 138.31 },
            { label: "17a", start: 138.56, end: 141.07 },
            { label: "17b", start: 141.32, end: 143.19 },
            { label: "17c", start: 143.44, end: 145.99 },
            { label: "17d", start: 146.24, end: 147.11 },
            { label: "17e", start: 147.36, end: 148.47 },
            { label: "18", start: 148.72, end: 156.75 },
            { label: "19a", start: 157, end: 158.35 },
            { label: "19b", start: 158.6, end: 162.61 },
            { label: "19c", start: 162.86, end: 164.89 },
            { label: "20a", start: 165.14, end: 166.57 },
            { label: "20b", start: 166.82, end: 170.03 },
            { label: "21a", start: 170.28, end: 175.95 },
            { label: "21b", start: 176.2, end: 177.41 },
            { label: "21c", start: 177.66, end: 181.57 },
            { label: "22a", start: 181.82, end: 186.67 },
            { label: "22b", start: 186.92, end: 193.71 },
            { label: "23", start: 193.96, end: 199.97 },
            { label: "24a", start: 200.22, end: 205.21 },
            { label: "24b", start: 205.46, end: 207.77 },
            { label: "24c", start: 208.02, end: 210.59 },
            { label: "25a", start: 210.84, end: 211.91 },
            { label: "25b", start: 212.16, end: 214.43 },
            { label: "25c", start: 214.68, end: 217.79 },
            { label: "26a", start: 218.04, end: 218.93 },
            { label: "26b", start: 219.18, end: 220.35 },
            { label: "26c", start: 220.6, end: 222.15 },
            { label: "27a", start: 222.4, end: 223.21 },
            { label: "27b", start: 223.46, end: 227.89 },
            { label: "27c", start: 228.14, end: 230.17 },
            { label: "28a", start: 230.42, end: 232.63 },
            { label: "28b", start: 232.88, end: 234.39 },
            { label: "28c", start: 234.64, end: 236.13 },
            { label: "29a", start: 236.38, end: 239.31 },
            { label: "29b", start: 239.56, end: 241.55 },
            { label: "30a", start: 241.8, end: 244.11 },
            { label: "30b", start: 244.36, end: 249.25 },
            { label: "31", start: 249.5, end: 259.41 },
            { label: "32", start: 259.66, end: 266.13 },
            { label: "33", start: 266.38, end: 270.51 },
            { label: "34a", start: 270.76, end: 272.57 },
            { label: "34b", start: 272.82, end: 275.13 },
            { label: "34c", start: 275.38, end: 277.09 },
            { label: "34d", start: 277.34, end: 279.23 },
            { label: "35a", start: 279.48, end: 285.33 },
            { label: "35b", start: 285.58, end: 288.07 },
            { label: "36a", start: 288.32, end: 289.43 },
            { label: "36b", start: 289.68, end: 292.97 },
            { label: "37a", start: 293.22, end: 294.27 },
            { label: "37b", start: 294.52, end: 295.49 },
            { label: "37c", start: 295.74, end: 298.71 },
            { label: "38a", start: 298.96, end: 300.05 },
            { label: "38b", start: 300.3, end: 302.11 },
            { label: "38c", start: 302.36, end: 303.99 },
            { label: "39a", start: 304.24, end: 305.29 },
            { label: "39b", start: 305.54, end: 310.71 },
            { label: "40a", start: 310.96, end: 315.17 },
            { label: "40b", start: 315.42, end: 316.95 },
            { label: "41a", start: 317.2, end: 318.65 },
            { label: "41b", start: 318.9, end: 320.81 },
            { label: "41c", start: 321.06, end: 326.07 }
        ],
        "ch10":[
            { label: "s1", start: 0, end: 1.99 },
            { label: "1", start: 2.24, end: 11.39 },
            { label: "2", start: 11.64, end: 15.19 },
            { label: "3a", start: 15.44, end: 20.83 },
            { label: "3b", start: 21.08, end: 25.57 },
            { label: "4", start: 25.82, end: 36.45 },
            { label: "5", start: 36.7, end: 44.31 },
            { label: "6", start: 44.56, end: 50.47 },
            { label: "7a", start: 50.72, end: 52.61 },
            { label: "7b", start: 52.86, end: 57.93 },
            { label: "8", start: 58.18, end: 67.03 },
            { label: "9a", start: 67.28, end: 68.19 },
            { label: "9b", start: 68.44, end: 76.93 },
            { label: "10a", start: 77.18, end: 81.89 },
            { label: "10b", start: 82.14, end: 89.33 },
            { label: "11a", start: 89.58, end: 91.79 },
            { label: "11b", start: 92.04, end: 95.35 },
            { label: "12a", start: 95.6, end: 100.27 },
            { label: "12b", start: 100.52, end: 108.97 },
            { label: "13", start: 109.22, end: 114.63 },
            { label: "14a", start: 114.88, end: 117.05 },
            { label: "14b", start: 117.3, end: 122.39 },
            { label: "15a", start: 122.64, end: 125.73 },
            { label: "15b", start: 125.98, end: 128.41 },
            { label: "16a", start: 128.66, end: 132.09 },
            { label: "16b", start: 132.34, end: 135.71 },
            { label: "16c", start: 135.96, end: 138.07 },
            { label: "17", start: 138.32, end: 144.33 },
            { label: "18a", start: 144.58, end: 149.67 },
            { label: "18b", start: 149.92, end: 154.45 },
            { label: "18c", start: 154.7, end: 157.23 },
            { label: "19", start: 157.48, end: 161.89 },
            { label: "20a", start: 162.14, end: 163.97 },
            { label: "20b", start: 164.22, end: 166.37 },
            { label: "20c", start: 166.62, end: 168.57 },
            { label: "21a", start: 168.82, end: 170.35 },
            { label: "21b", start: 170.6, end: 172.63 },
            { label: "21c", start: 172.88, end: 176.41 },
            { label: "s2", start: 176.66, end: 180.43 },
            { label: "22a", start: 180.68, end: 186.03 },
            { label: "22b", start: 186.28, end: 187.45 },
            { label: "23", start: 187.7, end: 191.97 },
            { label: "24a", start: 192.22, end: 194.93 },
            { label: "24b", start: 195.18, end: 197.33 },
            { label: "24c", start: 197.58, end: 200.37 },
            { label: "25a", start: 200.62, end: 201.71 },
            { label: "25b", start: 201.96, end: 204.59 },
            { label: "25c", start: 204.84, end: 208.51 },
            { label: "26", start: 208.76, end: 213.39 },
            { label: "27a", start: 213.64, end: 215.95 },
            { label: "27b", start: 216.2, end: 219.63 },
            { label: "28", start: 219.88, end: 227.37 },
            { label: "29", start: 227.62, end: 233.95 },
            { label: "30", start: 234.2, end: 236.27 },
            { label: "31", start: 236.52, end: 240.89 },
            { label: "32a", start: 241.14, end: 242.71 },
            { label: "32b", start: 242.96, end: 248.49 },
            { label: "33a", start: 248.74, end: 249.85 },
            { label: "33b", start: 250.1, end: 255.85 },
            { label: "33c", start: 256.1, end: 258.97 },
            { label: "34a", start: 259.22, end: 260.65 },
            { label: "34b", start: 260.9, end: 265.21 },
            { label: "35a", start: 265.46, end: 269.43 },
            { label: "35b", start: 269.68, end: 273.93 },
            { label: "36a", start: 274.18, end: 278.99 },
            { label: "36b", start: 279.24, end: 283.15 },
            { label: "37", start: 283.4, end: 287.67 },
            { label: "38", start: 287.92, end: 298.69 },
            { label: "39", start: 298.94, end: 304.67 },
            { label: "40", start: 304.92, end: 312.23 },
            { label: "41a", start: 312.48, end: 314.55 },
            { label: "41b", start: 314.8, end: 315.59 },
            { label: "41c", start: 315.84, end: 322.83 },
            { label: "42", start: 323.08, end: 326.83 }
        ],
        "ch11":[
            { label: "s1", start: 0, end: 1.97 },
            { label: "1a", start: 2.22, end: 4.91 },
            { label: "1b", start: 5.16, end: 11.49 },
            { label: "2a", start: 11.74, end: 18.05 },
            { label: "2b", start: 18.3, end: 20.91 },
            { label: "3a", start: 21.16, end: 25.49 },
            { label: "3b", start: 25.74, end: 28.91 },
            { label: "4a", start: 29.16, end: 31.91 },
            { label: "4b", start: 32.16, end: 41.29 },
            { label: "5", start: 41.54, end: 45.67 },
            { label: "6", start: 45.92, end: 52.13 },
            { label: "7a", start: 52.38, end: 54.45 },
            { label: "7b", start: 54.7, end: 55.55 },
            { label: "8a", start: 55.8, end: 58.41 },
            { label: "8b", start: 58.66, end: 64.59 },
            { label: "9a", start: 64.84, end: 66.07 },
            { label: "9b", start: 66.32, end: 68.97 },
            { label: "9c", start: 69.22, end: 73.91 },
            { label: "10", start: 74.16, end: 78.99 },
            { label: "11a", start: 79.24, end: 81.47 },
            { label: "11b", start: 81.72, end: 86.33 },
            { label: "12a", start: 86.58, end: 88.55 },
            { label: "12b", start: 88.8, end: 92.27 },
            { label: "13", start: 92.52, end: 99.35 },
            { label: "14a", start: 99.6, end: 102.55 },
            { label: "14b", start: 102.8, end: 104.13 },
            { label: "15a", start: 104.38, end: 107.73 },
            { label: "15b", start: 107.98, end: 109.65 },
            { label: "16a", start: 109.9, end: 113.59 },
            { label: "16b", start: 113.84, end: 117.47 },
            { label: "s2", start: 117.72, end: 121.27 },
            { label: "17", start: 121.52, end: 127.05 },
            { label: "18", start: 127.3, end: 132.55 },
            { label: "19", start: 132.8, end: 139.59 },
            { label: "20a", start: 139.84, end: 145.35 },
            { label: "20b", start: 145.6, end: 147.95 },
            { label: "21a", start: 148.2, end: 149.79 },
            { label: "21b", start: 150.04, end: 153.37 },
            { label: "22", start: 153.62, end: 157.69 },
            { label: "23a", start: 157.94, end: 159.15 },
            { label: "23b", start: 159.4, end: 161.01 },
            { label: "24a", start: 161.26, end: 162.87 },
            { label: "24b", start: 163.12, end: 165.29 },
            { label: "25a", start: 165.54, end: 166.79 },
            { label: "25b", start: 167.04, end: 169.39 },
            { label: "25c", start: 169.64, end: 174.51 },
            { label: "26a", start: 174.76, end: 179.91 },
            { label: "26b", start: 180.16, end: 181.79 },
            { label: "27a", start: 182.04, end: 183.25 },
            { label: "27b", start: 183.5, end: 190.49 },
            { label: "28a", start: 190.74, end: 196.77 },
            { label: "28b", start: 197.02, end: 199.87 },
            { label: "29", start: 200.12, end: 205.29 },
            { label: "30", start: 205.54, end: 211.57 },
            { label: "31", start: 211.82, end: 227.21 },
            { label: "32a", start: 227.46, end: 234.17 },
            { label: "32b", start: 234.42, end: 238.15 },
            { label: "33", start: 238.4, end: 248.23 },
            { label: "34a", start: 248.48, end: 249.31 },
            { label: "34b", start: 249.56, end: 251.07 },
            { label: "34c", start: 251.32, end: 252.31 },
            { label: "34d", start: 252.56, end: 254.39 },
            { label: "35", start: 254.64, end: 256.83 },
            { label: "36a", start: 257.08, end: 259.05 },
            { label: "36b", start: 259.3, end: 261.49 },
            { label: "37a", start: 261.74, end: 263.47 },
            { label: "37b", start: 263.72, end: 270.51 },
            { label: "s3", start: 270.76, end: 274.07 },
            { label: "38a", start: 274.32, end: 279.51 },
            { label: "38b", start: 279.76, end: 283.43 },
            { label: "39a", start: 283.68, end: 284.51 },
            { label: "39b", start: 284.76, end: 285.97 },
            { label: "39c", start: 286.22, end: 288.61 },
            { label: "39d", start: 288.86, end: 293.23 },
            { label: "40a", start: 293.48, end: 294.77 },
            { label: "40b", start: 295.02, end: 299.17 },
            { label: "41a", start: 299.42, end: 301.07 },
            { label: "41b", start: 301.32, end: 304.73 },
            { label: "41c", start: 304.98, end: 308.29 },
            { label: "42a", start: 308.54, end: 311.01 },
            { label: "42b", start: 311.26, end: 318.59 },
            { label: "43a", start: 318.84, end: 322.91 },
            { label: "43b", start: 323.16, end: 325.35 },
            { label: "44a", start: 325.6, end: 333.99 },
            { label: "44b", start: 334.24, end: 335.63 },
            { label: "44c", start: 335.88, end: 338.85 },
            { label: "s4", start: 339.1, end: 341.93 },
            { label: "45", start: 342.18, end: 348.77 },
            { label: "46", start: 349.02, end: 355.35 },
            { label: "47a", start: 355.6, end: 359.71 },
            { label: "47b", start: 359.96, end: 360.57 },
            { label: "47c", start: 360.82, end: 361.73 },
            { label: "47d", start: 361.98, end: 364.51 },
            { label: "48", start: 364.76, end: 373.73 },
            { label: "49a", start: 373.98, end: 379.79 },
            { label: "49b", start: 380.04, end: 381.27 },
            { label: "50", start: 381.52, end: 387.85 },
            { label: "51", start: 388.1, end: 397.91 },
            { label: "52", start: 398.16, end: 405.41 },
            { label: "53", start: 405.66, end: 408.91 },
            { label: "54", start: 409.16, end: 422.69 },
            { label: "55", start: 422.94, end: 434.93 },
            { label: "56a", start: 435.18, end: 442.13 },
            { label: "56b", start: 442.38, end: 443.57 },
            { label: "56c", start: 443.82, end: 445.95 },
            { label: "57", start: 446.2, end: 455.79 }
        ],
        "ch12":[
            { label: "s1", start: 0, end: 2.95 },
            { label: "1a", start: 3.2, end: 9.27 },
            { label: "1b", start: 9.52, end: 12.55 },
            { label: "2a", start: 12.8, end: 15.87 },
            { label: "2b", start: 16.12, end: 22.51 },
            { label: "3", start: 22.76, end: 38.93 },
            { label: "4", start: 39.18, end: 46.69 },
            { label: "5", start: 46.94, end: 51.95 },
            { label: "6a", start: 52.2, end: 58.33 },
            { label: "6b", start: 58.58, end: 63.79 },
            { label: "7a", start: 64.04, end: 65.89 },
            { label: "7b", start: 66.14, end: 71.35 },
            { label: "8", start: 71.6, end: 76.49 },
            { label: "9", start: 76.74, end: 87.15 },
            { label: "10", start: 87.4, end: 91.31 },
            { label: "11", start: 91.56, end: 99.33 },
            { label: "s2", start: 99.58, end: 105.37 },
            { label: "12", start: 105.62, end: 110.37 },
            { label: "13a", start: 110.62, end: 114.63 },
            { label: "13b", start: 114.88, end: 116.39 },
            { label: "13c", start: 116.64, end: 117.37 },
            { label: "13d", start: 117.62, end: 120.35 },
            { label: "13e", start: 120.6, end: 122.63 },
            { label: "14a", start: 122.88, end: 127.23 },
            { label: "14b", start: 127.48, end: 129.03 },
            { label: "15a", start: 129.28, end: 129.95 },
            { label: "15b", start: 130.2, end: 131.25 },
            { label: "15c", start: 131.5, end: 133.07 },
            { label: "15d", start: 133.32, end: 137.43 },
            { label: "16", start: 137.68, end: 150.01 },
            { label: "17a", start: 150.26, end: 158.03 },
            { label: "17b", start: 158.28, end: 161.65 },
            { label: "18", start: 161.9, end: 169.27 },
            { label: "19a", start: 169.52, end: 171.87 },
            { label: "19b", start: 172.12, end: 174.83 },
            { label: "19c", start: 175.08, end: 179.23 },
            { label: "s3", start: 179.48, end: 183.29 },
            { label: "20", start: 183.54, end: 188.35 },
            { label: "21a", start: 188.6, end: 193.49 },
            { label: "21b", start: 193.74, end: 194.73 },
            { label: "21c", start: 194.98, end: 197.55 },
            { label: "22", start: 197.8, end: 203.53 },
            { label: "23a", start: 203.78, end: 205.51 },
            { label: "23b", start: 205.76, end: 209.45 },
            { label: "24", start: 209.7, end: 218.97 },
            { label: "25", start: 219.22, end: 229.61 },
            { label: "26a", start: 229.86, end: 238.73 },
            { label: "26b", start: 238.98, end: 244.09 },
            { label: "s4", start: 244.34, end: 248.23 },
            { label: "27a", start: 248.48, end: 252.03 },
            { label: "27b", start: 252.28, end: 256.17 },
            { label: "27c", start: 256.42, end: 259.11 },
            { label: "28a", start: 259.36, end: 261.91 },
            { label: "28b", start: 262.16, end: 265.09 },
            { label: "28c", start: 265.34, end: 267.65 },
            { label: "29a", start: 267.9, end: 271.87 },
            { label: "29b", start: 272.12, end: 272.97 },
            { label: "29c", start: 273.22, end: 274.29 },
            { label: "29d", start: 274.54, end: 276.99 },
            { label: "30a", start: 277.24, end: 278.43 },
            { label: "30b", start: 278.68, end: 281.79 },
            { label: "31a", start: 282.04, end: 285.49 },
            { label: "31b", start: 285.74, end: 288.59 },
            { label: "32", start: 288.84, end: 295.43 },
            { label: "33", start: 295.68, end: 298.79 },
            { label: "34a", start: 299.04, end: 300.25 },
            { label: "34b", start: 300.5, end: 309.15 },
            { label: "34c", start: 309.4, end: 312.11 },
            { label: "35a", start: 312.36, end: 314.13 },
            { label: "35b", start: 314.38, end: 317.09 },
            { label: "35c", start: 317.34, end: 323.71 },
            { label: "35d", start: 323.96, end: 327.45 },
            { label: "36a", start: 327.7, end: 333.09 },
            { label: "36b", start: 333.34, end: 339.07 },
            { label: "s5", start: 339.32, end: 342.39 },
            { label: "37", start: 342.64, end: 349.01 },
            { label: "38a", start: 349.26, end: 353.73 },
            { label: "38b", start: 353.98, end: 357.69 },
            { label: "38c", start: 357.94, end: 361.39 },
            { label: "39", start: 361.64, end: 367.49 },
            { label: "40a", start: 367.74, end: 369.59 },
            { label: "40b", start: 369.84, end: 371.55 },
            { label: "40c", start: 371.8, end: 374.09 },
            { label: "40d", start: 374.34, end: 376.51 },
            { label: "40e", start: 376.76, end: 379.71 },
            { label: "41", start: 379.96, end: 386.43 },
            { label: "42", start: 386.68, end: 397.43 },
            { label: "43", start: 397.68, end: 403.23 },
            { label: "44a", start: 403.48, end: 406.33 },
            { label: "44b", start: 406.58, end: 413.33 },
            { label: "45", start: 413.58, end: 417.15 },
            { label: "46", start: 417.4, end: 423.11 },
            { label: "47", start: 423.36, end: 436.61 },
            { label: "48a", start: 436.86, end: 442.63 },
            { label: "48b", start: 442.88, end: 447.05 },
            { label: "49", start: 447.3, end: 456.13 },
            { label: "50a", start: 456.38, end: 459.97 },
            { label: "50b", start: 460.22, end: 465.87 }
        ],
        "ch13":[
            { label: "s1", start: 0, end: 2.81 },
            { label: "1", start: 3.06, end: 16.39 },
            { label: "2", start: 16.64, end: 25.15 },
            { label: "3", start: 25.4, end: 31.91 },
            { label: "4", start: 32.16, end: 38.95 },
            { label: "5", start: 39.2, end: 50.17 },
            { label: "6a", start: 50.42, end: 54.35 },
            { label: "6b", start: 54.6, end: 56.97 },
            { label: "7a", start: 57.22, end: 58.41 },
            { label: "7b", start: 58.66, end: 63.37 },
            { label: "8a", start: 63.62, end: 64.91 },
            { label: "8b", start: 65.16, end: 65.99 },
            { label: "8c", start: 66.24, end: 67.83 },
            { label: "8d", start: 68.08, end: 69.31 },
            { label: "8e", start: 69.56, end: 72.41 },
            { label: "9a", start: 72.66, end: 75.07 },
            { label: "9b", start: 75.32, end: 79.73 },
            { label: "10a", start: 79.98, end: 81.19 },
            { label: "10b", start: 81.44, end: 87.33 },
            { label: "10c", start: 87.58, end: 90.79 },
            { label: "11a", start: 91.04, end: 95.75 },
            { label: "11b", start: 96, end: 97.83 },
            { label: "12a", start: 98.08, end: 104.67 },
            { label: "12b", start: 104.92, end: 106.31 },
            { label: "12c", start: 106.56, end: 110.09 },
            { label: "13", start: 110.34, end: 116.11 },
            { label: "14", start: 116.36, end: 123.37 },
            { label: "15", start: 123.62, end: 130.23 },
            { label: "16", start: 130.48, end: 137.75 },
            { label: "17", start: 138, end: 144.49 },
            { label: "s2", start: 144.74, end: 148.33 },
            { label: "18a", start: 148.58, end: 152.39 },
            { label: "18b", start: 152.64, end: 155.21 },
            { label: "18c", start: 155.46, end: 158.67 },
            { label: "18d", start: 158.92, end: 161.69 },
            { label: "19", start: 161.94, end: 169.57 },
            { label: "20", start: 169.82, end: 181.51 },
            { label: "21a", start: 181.76, end: 187.19 },
            { label: "21b", start: 187.44, end: 192.49 },
            { label: "22", start: 192.74, end: 199.21 },
            { label: "23", start: 199.46, end: 204.75 },
            { label: "24", start: 205, end: 212.47 },
            { label: "25a", start: 212.72, end: 215.99 },
            { label: "25b", start: 216.24, end: 218.17 },
            { label: "26a", start: 218.42, end: 219.71 },
            { label: "26b", start: 219.96, end: 224.27 },
            { label: "26c", start: 224.52, end: 230.83 },
            { label: "27a", start: 231.08, end: 235.89 },
            { label: "27b", start: 236.14, end: 237.67 },
            { label: "27c", start: 237.92, end: 241.03 },
            { label: "28", start: 241.28, end: 246.75 },
            { label: "29", start: 247, end: 258.19 },
            { label: "30", start: 258.44, end: 264.75 },
            { label: "s3", start: 265, end: 268.45 },
            { label: "31a", start: 268.7, end: 272.75 },
            { label: "31b", start: 273, end: 278.95 },
            { label: "32", start: 279.2, end: 287.49 },
            { label: "33a", start: 287.74, end: 290.97 },
            { label: "33b", start: 291.22, end: 299.89 },
            { label: "34a", start: 300.14, end: 305.63 },
            { label: "34b", start: 305.88, end: 310.47 },
            { label: "35", start: 310.72, end: 316.47 },
            { label: "36a", start: 316.72, end: 318.49 },
            { label: "36b", start: 318.74, end: 321.11 },
            { label: "36c", start: 321.36, end: 322.99 },
            { label: "36d", start: 323.24, end: 329.57 },
            { label: "37a", start: 329.82, end: 331.21 },
            { label: "37b", start: 331.46, end: 334.53 },
            { label: "37c", start: 334.78, end: 337.49 },
            { label: "38a", start: 337.74, end: 339.21 },
            { label: "38b", start: 339.46, end: 341.97 },
            { label: "38c", start: 342.22, end: 349.19 }
        ],
        "ch14":[
            { label: "s1", start: 0, end: 3.59 },
            { label: "1a", start: 3.84, end: 5.99 },
            { label: "1b", start: 6.24, end: 9.77 },
            { label: "2a", start: 10.02, end: 14.35 },
            { label: "2b", start: 14.6, end: 17.33 },
            { label: "3", start: 17.58, end: 26.55 },
            { label: "4", start: 26.8, end: 30.09 },
            { label: "5a", start: 30.34, end: 31.99 },
            { label: "5b", start: 32.24, end: 36.27 },
            { label: "6a", start: 36.52, end: 38.07 },
            { label: "6b", start: 38.32, end: 45.17 },
            { label: "7a", start: 45.42, end: 48.65 },
            { label: "7b", start: 48.9, end: 53.75 },
            { label: "8a", start: 54, end: 55.73 },
            { label: "8b", start: 55.98, end: 59.91 },
            { label: "9a", start: 60.16, end: 61.71 },
            { label: "9b", start: 61.96, end: 66.65 },
            { label: "9c", start: 66.9, end: 69.57 },
            { label: "9d", start: 69.82, end: 73.37 },
            { label: "10a", start: 73.62, end: 77.85 },
            { label: "10b", start: 78.1, end: 87.91 },
            { label: "11a", start: 88.16, end: 93.77 },
            { label: "11b", start: 94.02, end: 99.85 },
            { label: "12", start: 100.1, end: 112.79 },
            { label: "13", start: 113.04, end: 120.41 },
            { label: "14", start: 120.66, end: 125.11 },
            { label: "s2", start: 125.36, end: 128.25 },
            { label: "15", start: 128.5, end: 132.05 },
            { label: "16", start: 132.3, end: 139.11 },
            { label: "17", start: 139.36, end: 155.01 },
            { label: "18", start: 155.26, end: 158.21 },
            { label: "19a", start: 158.46, end: 163.15 },
            { label: "19b", start: 163.4, end: 167.03 },
            { label: "20", start: 167.28, end: 173.55 },
            { label: "21a", start: 173.8, end: 179.93 },
            { label: "21b", start: 180.18, end: 190.33 },
            { label: "22a", start: 190.58, end: 195.43 },
            { label: "22b", start: 195.68, end: 201.97 },
            { label: "23a", start: 202.22, end: 203.59 },
            { label: "23b", start: 203.84, end: 214.19 },
            { label: "24", start: 214.44, end: 225.27 },
            { label: "25", start: 225.52, end: 229.95 },
            { label: "26", start: 230.2, end: 243.43 },
            { label: "27a", start: 243.68, end: 248.43 },
            { label: "27b", start: 248.68, end: 253.13 },
            { label: "27c", start: 253.38, end: 256.13 },
            { label: "28a", start: 256.38, end: 259.79 },
            { label: "28b", start: 260.04, end: 262.55 },
            { label: "28c", start: 262.8, end: 272.17 },
            { label: "29", start: 272.42, end: 280.21 },
            { label: "30", start: 280.46, end: 289.23 },
            { label: "31a", start: 289.48, end: 296.51 },
            { label: "31b", start: 296.76, end: 299.43 }
        ],
        "ch15":[
            { label: "s1", start: 0, end: 2.37 },
            { label: "1", start: 2.62, end: 7.25 },
            { label: "2", start: 7.5, end: 17.21 },
            { label: "3", start: 17.46, end: 21.47 },
            { label: "4a", start: 21.72, end: 24.59 },
            { label: "4b", start: 24.84, end: 33.83 },
            { label: "5a", start: 34.08, end: 40.23 },
            { label: "5b", start: 40.48, end: 45.77 },
            { label: "5c", start: 46.02, end: 50.33 },
            { label: "6a", start: 50.58, end: 56.27 },
            { label: "6b", start: 56.52, end: 63.25 },
            { label: "7", start: 63.5, end: 71.87 },
            { label: "8", start: 72.12, end: 78.79 },
            { label: "9a", start: 79.04, end: 83.45 },
            { label: "9b", start: 83.7, end: 85.59 },
            { label: "10", start: 85.84, end: 94.07 },
            { label: "11", start: 94.32, end: 100.61 },
            { label: "12", start: 100.86, end: 107.37 },
            { label: "13", start: 107.62, end: 113.65 },
            { label: "14", start: 113.9, end: 119.83 },
            { label: "15", start: 120.08, end: 133.73 },
            { label: "16", start: 133.98, end: 148.19 },
            { label: "17", start: 148.44, end: 153.53 },
            { label: "s2", start: 153.78, end: 158.55 },
            { label: "18", start: 158.8, end: 165.13 },
            { label: "19a", start: 165.38, end: 171.41 },
            { label: "19b", start: 171.66, end: 179.17 },
            { label: "20a", start: 179.42, end: 182.07 },
            { label: "20b", start: 182.32, end: 184.97 },
            { label: "20c", start: 185.22, end: 193.93 },
            { label: "21", start: 194.18, end: 202.69 },
            { label: "22", start: 202.94, end: 212.15 },
            { label: "23", start: 212.4, end: 216.81 },
            { label: "24", start: 217.06, end: 229.63 },
            { label: "25a", start: 229.88, end: 234.51 },
            { label: "25b", start: 234.76, end: 238.79 },
            { label: "26", start: 239.04, end: 248.43 },
            { label: "27", start: 248.68, end: 253.83 }
        ],
        "ch16":[
            { label: "1", start: 0, end: 3.47 },
            { label: "2a", start: 3.72, end: 6.59 },
            { label: "2b", start: 6.84, end: 12.73 },
            { label: "3", start: 12.98, end: 17.57 },
            { label: "4a", start: 17.82, end: 24.77 },
            { label: "4b", start: 25.02, end: 30.51 },
            { label: "s1", start: 30.76, end: 34.19 },
            { label: "5", start: 34.44, end: 41.97 },
            { label: "6", start: 42.22, end: 46.05 },
            { label: "7", start: 46.3, end: 58.65 },
            { label: "8", start: 58.9, end: 66.71 },
            { label: "9", start: 66.96, end: 69.83 },
            { label: "10", start: 70.08, end: 75.89 },
            { label: "11", start: 76.14, end: 80.55 },
            { label: "12", start: 80.8, end: 87.03 },
            { label: "13a", start: 87.28, end: 92.23 },
            { label: "13b", start: 92.48, end: 101.41 },
            { label: "14", start: 101.66, end: 108.01 },
            { label: "15a", start: 108.26, end: 111.55 },
            { label: "15b", start: 111.8, end: 118.01 },
            { label: "s2", start: 118.26, end: 122.09 },
            { label: "16", start: 122.34, end: 129.43 },
            { label: "17a", start: 129.68, end: 133.29 },
            { label: "17b", start: 133.54, end: 135.69 },
            { label: "17c", start: 135.94, end: 141.85 },
            { label: "17d", start: 142.1, end: 145.35 },
            { label: "18a", start: 145.6, end: 147.59 },
            { label: "18b", start: 147.84, end: 150.47 },
            { label: "18c", start: 150.72, end: 152.85 },
            { label: "19a", start: 153.1, end: 157.05 },
            { label: "19b", start: 157.3, end: 158.59 },
            { label: "19c", start: 158.84, end: 168.93 },
            { label: "20a", start: 169.18, end: 175.67 },
            { label: "20b", start: 175.92, end: 180.33 },
            { label: "21a", start: 180.58, end: 185.67 },
            { label: "21b", start: 185.92, end: 194.71 },
            { label: "22", start: 194.96, end: 204.51 },
            { label: "23a", start: 204.76, end: 207.17 },
            { label: "23b", start: 207.42, end: 213.65 },
            { label: "24a", start: 213.9, end: 216.99 },
            { label: "24b", start: 217.24, end: 221.43 },
            { label: "25", start: 221.68, end: 232.19 },
            { label: "26", start: 232.44, end: 239.11 },
            { label: "27", start: 239.36, end: 249.89 },
            { label: "28", start: 250.14, end: 257.67 },
            { label: "29a", start: 257.92, end: 262.33 },
            { label: "29b", start: 262.58, end: 265.51 },
            { label: "30a", start: 265.76, end: 273.43 },
            { label: "30b", start: 273.68, end: 277.63 },
            { label: "31a", start: 277.88, end: 279.89 },
            { label: "31b", start: 280.14, end: 283.07 },
            { label: "32a", start: 283.32, end: 293.85 },
            { label: "32b", start: 294.1, end: 297.63 },
            { label: "33a", start: 297.88, end: 305.39 },
            { label: "33b", start: 305.64, end: 312.03 }
        ],
        "ch17":[
            { label: "s1", start: 0, end: 3.59 },
            { label: "1a", start: 3.84, end: 8.03 },
            { label: "1b", start: 8.28, end: 10.45 },
            { label: "1c", start: 10.7, end: 14.83 },
            { label: "2", start: 15.08, end: 25.01 },
            { label: "3", start: 25.26, end: 34.49 },
            { label: "4", start: 34.74, end: 42.19 },
            { label: "5", start: 42.44, end: 52.35 },
            { label: "s2", start: 52.6, end: 55.91 },
            { label: "6a", start: 56.16, end: 62.07 },
            { label: "6b", start: 62.32, end: 67.91 },
            { label: "7", start: 68.16, end: 75.71 },
            { label: "8", start: 75.96, end: 88.89 },
            { label: "9a", start: 89.14, end: 91.51 },
            { label: "9b", start: 91.76, end: 97.69 },
            { label: "9c", start: 97.94, end: 99.79 },
            { label: "10", start: 100.04, end: 110.01 },
            { label: "11a", start: 110.26, end: 116.15 },
            { label: "11b", start: 116.4, end: 117.71 },
            { label: "11c", start: 117.96, end: 127.97 },
            { label: "12a", start: 128.22, end: 137.63 },
            { label: "12b", start: 137.88, end: 147.73 },
            { label: "13", start: 147.98, end: 158.15 },
            { label: "14a", start: 158.4, end: 166.67 },
            { label: "14b", start: 166.92, end: 169.25 },
            { label: "15", start: 169.5, end: 176.05 },
            { label: "16", start: 176.3, end: 181.51 },
            { label: "17a", start: 181.76, end: 185.45 },
            { label: "17b", start: 185.7, end: 187.91 },
            { label: "18", start: 188.16, end: 194.11 },
            { label: "19", start: 194.36, end: 201.97 },
            { label: "s3", start: 202.22, end: 206.01 },
            { label: "20", start: 206.26, end: 215.93 },
            { label: "21", start: 216.18, end: 229.95 },
            { label: "22", start: 230.2, end: 239.39 },
            { label: "23", start: 239.64, end: 253.17 },
            { label: "24a", start: 253.42, end: 264.89 },
            { label: "24b", start: 265.14, end: 269.71 },
            { label: "25", start: 269.96, end: 279.33 },
            { label: "26", start: 279.58, end: 293.19 }
        ],
        "ch18":[
            { label: "s1", start: 0, end: 2.31 },
            { label: "1a", start: 2.56, end: 8.51 },
            { label: "1b", start: 8.76, end: 12.99 },
            { label: "2", start: 13.24, end: 22.45 },
            { label: "3", start: 22.7, end: 36.23 },
            { label: "4a", start: 36.48, end: 41.23 },
            { label: "4b", start: 41.48, end: 42.79 },
            { label: "5a", start: 43.04, end: 44.11 },
            { label: "5b", start: 44.36, end: 45.87 },
            { label: "5c", start: 46.12, end: 46.79 },
            { label: "5d", start: 47.04, end: 47.73 },
            { label: "5e", start: 47.98, end: 50.67 },
            { label: "6", start: 50.92, end: 56.37 },
            { label: "7a", start: 56.62, end: 59.21 },
            { label: "7b", start: 59.46, end: 60.73 },
            { label: "7c", start: 60.98, end: 61.83 },
            { label: "7d", start: 62.08, end: 63.43 },
            { label: "8a", start: 63.68, end: 64.33 },
            { label: "8b", start: 64.58, end: 67.45 },
            { label: "8c", start: 67.7, end: 70.89 },
            { label: "9a", start: 71.14, end: 75.49 },
            { label: "9b", start: 75.74, end: 80.09 },
            { label: "10a", start: 80.34, end: 90.27 },
            { label: "10b", start: 90.52, end: 93.33 },
            { label: "11a", start: 93.58, end: 95.77 },
            { label: "11b", start: 96.02, end: 97.73 },
            { label: "11c", start: 97.98, end: 102.79 },
            { label: "s2", start: 103.04, end: 105.55 },
            { label: "12", start: 105.8, end: 116.63 },
            { label: "13a", start: 116.88, end: 119.61 },
            { label: "13b", start: 119.86, end: 124.67 },
            { label: "14", start: 124.92, end: 128.71 },
            { label: "s3", start: 128.96, end: 130.91 },
            { label: "15a", start: 131.16, end: 139.47 },
            { label: "15b", start: 139.72, end: 145.13 },
            { label: "15c", start: 145.38, end: 150.59 },
            { label: "16a", start: 150.84, end: 153.49 },
            { label: "16b", start: 153.74, end: 163.51 },
            { label: "17a", start: 163.76, end: 166.03 },
            { label: "17b", start: 166.28, end: 168.71 },
            { label: "17c", start: 168.96, end: 170.13 },
            { label: "17d", start: 170.38, end: 171.33 },
            { label: "18a", start: 171.58, end: 173.07 },
            { label: "18b", start: 173.32, end: 179.77 },
            { label: "18c", start: 180.02, end: 184.37 },
            { label: "s4", start: 184.62, end: 188.83 },
            { label: "19", start: 189.08, end: 195.29 },
            { label: "20a", start: 195.54, end: 197.31 },
            { label: "20b", start: 197.56, end: 206.89 },
            { label: "21a", start: 207.14, end: 208.47 },
            { label: "21b", start: 208.72, end: 211.35 },
            { label: "21c", start: 211.6, end: 214.67 },
            { label: "22a", start: 214.92, end: 221.01 },
            { label: "22b", start: 221.26, end: 223.47 },
            { label: "23a", start: 223.72, end: 225.09 },
            { label: "23b", start: 225.34, end: 232.23 },
            { label: "24", start: 232.48, end: 240.35 },
            { label: "s5", start: 240.6, end: 244.89 },
            { label: "25a", start: 245.14, end: 251.67 },
            { label: "25b", start: 251.92, end: 255.37 },
            { label: "25c", start: 255.62, end: 257.33 },
            { label: "25d", start: 257.58, end: 257.83 },
            { label: "25e", start: 258.08, end: 258.81 },
            { label: "26a", start: 259.06, end: 262.47 },
            { label: "26b", start: 262.72, end: 267.99 },
            { label: "26c", start: 268.24, end: 271.47 },
            { label: "27a", start: 271.72, end: 274.03 },
            { label: "27b", start: 274.28, end: 278.29 },
            { label: "s6", start: 278.54, end: 282.85 },
            { label: "28a", start: 283.1, end: 287.95 },
            { label: "28b", start: 288.2, end: 290.39 },
            { label: "28c", start: 290.64, end: 296.95 },
            { label: "29a", start: 297.2, end: 300.83 },
            { label: "29b", start: 301.08, end: 303.53 },
            { label: "30a", start: 303.78, end: 305.37 },
            { label: "30b", start: 305.62, end: 308.91 },
            { label: "31a", start: 309.16, end: 310.95 },
            { label: "31b", start: 311.2, end: 316.13 },
            { label: "31c", start: 316.38, end: 317.69 },
            { label: "31d", start: 317.94, end: 320.65 },
            { label: "32", start: 320.9, end: 329.39 },
            { label: "33a", start: 329.64, end: 333.53 },
            { label: "33b", start: 333.78, end: 336.39 },
            { label: "34a", start: 336.64, end: 337.99 },
            { label: "34b", start: 338.24, end: 342.55 },
            { label: "35a", start: 342.8, end: 344.09 },
            { label: "35b", start: 344.34, end: 345.71 },
            { label: "35c", start: 345.96, end: 349.53 },
            { label: "35d", start: 349.78, end: 351.07 },
            { label: "36a", start: 351.32, end: 352.89 },
            { label: "36b", start: 353.14, end: 355.37 },
            { label: "36c", start: 355.62, end: 362.47 },
            { label: "36d", start: 362.72, end: 364.91 },
            { label: "37a", start: 365.16, end: 367.45 },
            { label: "37b", start: 367.7, end: 369.45 },
            { label: "37c", start: 369.7, end: 371.03 },
            { label: "37d", start: 371.28, end: 373.83 },
            { label: "37e", start: 374.08, end: 378.11 },
            { label: "37f", start: 378.36, end: 382.89 },
            { label: "38a", start: 383.14, end: 384.65 },
            { label: "38b", start: 384.9, end: 387.99 },
            { label: "s7", start: 388.24, end: 391.67 },
            { label: "38c", start: 391.92, end: 398.81 },
            { label: "38d", start: 399.06, end: 402.29 },
            { label: "39a", start: 402.54, end: 408.43 },
            { label: "39b", start: 408.68, end: 411.61 },
            { label: "40a", start: 411.86, end: 413.53 },
            { label: "40b", start: 413.78, end: 414.99 },
            { label: "40c", start: 415.24, end: 417.05 },
            { label: "40d", start: 417.3, end: 420.19 }
        ],
        "ch19":[
            { label: "1", start: 0, end: 5.21 },
            { label: "2", start: 5.46, end: 12.59 },
            { label: "3a", start: 12.84, end: 15.21 },
            { label: "3b", start: 15.46, end: 20.31 },
            { label: "4a", start: 20.56, end: 25.47 },
            { label: "4b", start: 25.72, end: 32.27 },
            { label: "5a", start: 32.52, end: 36.97 },
            { label: "5b", start: 37.22, end: 38.75 },
            { label: "5c", start: 39, end: 40.27 },
            { label: "6a", start: 40.52, end: 46.33 },
            { label: "6b", start: 46.58, end: 47.43 },
            { label: "6c", start: 47.68, end: 48.67 },
            { label: "6d", start: 48.92, end: 50.49 },
            { label: "6e", start: 50.74, end: 58.71 },
            { label: "7a", start: 58.96, end: 61.67 },
            { label: "7b", start: 61.92, end: 69.19 },
            { label: "8", start: 69.44, end: 73.35 },
            { label: "9a", start: 73.6, end: 77.01 },
            { label: "9b", start: 77.26, end: 78.69 },
            { label: "9c", start: 78.94, end: 81.19 },
            { label: "10a", start: 81.44, end: 83.71 },
            { label: "10b", start: 83.96, end: 85.67 },
            { label: "10c", start: 85.92, end: 91.77 },
            { label: "11a", start: 92.02, end: 93.29 },
            { label: "11b", start: 93.54, end: 102.47 },
            { label: "12a", start: 102.72, end: 108.85 },
            { label: "12b", start: 109.1, end: 112.13 },
            { label: "12c", start: 112.38, end: 116.39 },
            { label: "13", start: 116.64, end: 129.81 },
            { label: "14a", start: 130.06, end: 135.25 },
            { label: "14b", start: 135.5, end: 137.85 },
            { label: "14c", start: 138.1, end: 139.57 },
            { label: "15a", start: 139.82, end: 141.17 },
            { label: "15b", start: 141.42, end: 142.57 },
            { label: "15c", start: 142.82, end: 143.89 },
            { label: "15d", start: 144.14, end: 145.57 },
            { label: "15e", start: 145.82, end: 147.03 },
            { label: "15f", start: 147.28, end: 149.77 },
            { label: "15g", start: 150.02, end: 151.91 },
            { label: "15h", start: 152.16, end: 154.93 },
            { label: "16", start: 155.18, end: 161.65 },
            { label: "s1", start: 161.9, end: 165.21 },
            { label: "16", start: 165.46, end: 169.31 },
            { label: "17", start: 169.56, end: 178.81 },
            { label: "18a", start: 179.06, end: 181.37 },
            { label: "18b", start: 181.62, end: 188.71 },
            { label: "19a", start: 188.96, end: 196.19 },
            { label: "19b", start: 196.44, end: 200.27 },
            { label: "20", start: 200.52, end: 214.55 },
            { label: "21a", start: 214.8, end: 219.15 },
            { label: "21b", start: 219.4, end: 227.27 },
            { label: "22a", start: 227.52, end: 228.91 },
            { label: "22b", start: 229.16, end: 231.93 },
            { label: "23a", start: 232.18, end: 240.73 },
            { label: "23b", start: 240.98, end: 243.61 },
            { label: "23c", start: 243.86, end: 246.33 },
            { label: "23d", start: 246.58, end: 253.99 },
            { label: "24a", start: 254.24, end: 256.13 },
            { label: "24b", start: 256.38, end: 261.17 },
            { label: "24c", start: 261.42, end: 265.69 },
            { label: "24d", start: 265.94, end: 268.95 },
            { label: "24e", start: 269.2, end: 272.13 },
            { label: "24f", start: 272.38, end: 275.73 },
            { label: "25", start: 275.98, end: 285.63 },
            { label: "26a", start: 285.88, end: 294.19 },
            { label: "26b", start: 294.44, end: 296.81 },
            { label: "27a", start: 297.06, end: 299.79 },
            { label: "27b", start: 300.04, end: 301.67 },
            { label: "27c", start: 301.92, end: 307.77 },
            { label: "s2", start: 308.02, end: 310.87 },
            { label: "28a", start: 311.12, end: 315.53 },
            { label: "28b", start: 315.78, end: 316.59 },
            { label: "28c", start: 316.84, end: 318.03 },
            { label: "28d", start: 318.28, end: 323.57 },
            { label: "29a", start: 323.82, end: 327.65 },
            { label: "29b", start: 327.9, end: 335.33 },
            { label: "30a", start: 335.58, end: 338.59 },
            { label: "30b", start: 338.84, end: 340.63 },
            { label: "30c", start: 340.88, end: 346.45 },
            { label: "31a", start: 346.7, end: 352.29 },
            { label: "31b", start: 352.54, end: 367.15 },
            { label: "32", start: 367.4, end: 377.23 },
            { label: "33", start: 377.48, end: 384.05 },
            { label: "34", start: 384.3, end: 393.39 },
            { label: "35a", start: 393.64, end: 399.11 },
            { label: "35b", start: 399.36, end: 404.13 },
            { label: "36a", start: 404.38, end: 409.05 },
            { label: "36b", start: 409.3, end: 412.29 },
            { label: "37a", start: 412.54, end: 416.07 },
            { label: "37b", start: 416.32, end: 420.91 },
            { label: "s3", start: 421.16, end: 424.07 },
            { label: "38a", start: 424.32, end: 431.43 },
            { label: "38b", start: 431.68, end: 435.69 },
            { label: "38c", start: 435.94, end: 438.91 },
            { label: "38d", start: 439.16, end: 440.75 },
            { label: "38e", start: 441, end: 444.19 },
            { label: "39", start: 444.44, end: 455.41 },
            { label: "40", start: 455.66, end: 464.47 },
            { label: "41", start: 464.72, end: 474.75 },
            { label: "42", start: 475, end: 483.71 }
        ],
        "ch20":[
            { label: "s1", start: 0, end: 1.71 },
            { label: "1", start: 1.96, end: 14.07 },
            { label: "2a", start: 14.32, end: 21.23 },
            { label: "2b", start: 21.48, end: 23.15 },
            { label: "2c", start: 23.4, end: 27.25 },
            { label: "3", start: 27.5, end: 33.03 },
            { label: "4", start: 33.28, end: 42.91 },
            { label: "5a", start: 43.16, end: 47.49 },
            { label: "5b", start: 47.74, end: 49.55 },
            { label: "6", start: 49.8, end: 57.83 },
            { label: "7", start: 58.08, end: 67.45 },
            { label: "8", start: 67.7, end: 74.97 },
            { label: "9", start: 75.22, end: 82.33 },
            { label: "10", start: 82.58, end: 87.61 },
            { label: "s2", start: 87.86, end: 93.43 },
            { label: "11a", start: 93.68, end: 97.55 },
            { label: "11b", start: 97.8, end: 100.67 },
            { label: "12", start: 100.92, end: 114.35 },
            { label: "13a", start: 114.6, end: 116.41 },
            { label: "13b", start: 116.66, end: 118.87 },
            { label: "13c", start: 119.12, end: 120.25 },
            { label: "13d", start: 120.5, end: 124.53 },
            { label: "14", start: 124.78, end: 131.47 },
            { label: "15a", start: 131.72, end: 133.21 },
            { label: "15b", start: 133.46, end: 135.89 },
            { label: "15c", start: 136.14, end: 137.87 },
            { label: "15d", start: 138.12, end: 141.59 },
            { label: "15e", start: 141.84, end: 148.73 },
            { label: "16a", start: 148.98, end: 150.49 },
            { label: "16b", start: 150.74, end: 151.85 },
            { label: "16c", start: 152.1, end: 156.69 },
            { label: "16d", start: 156.94, end: 158.03 },
            { label: "16e", start: 158.28, end: 160.25 },
            { label: "17a", start: 160.5, end: 162.25 },
            { label: "17b", start: 162.5, end: 166.81 },
            { label: "17c", start: 167.06, end: 177.97 },
            { label: "18a", start: 178.22, end: 182.21 },
            { label: "18b", start: 182.46, end: 184.05 },
            { label: "18c", start: 184.3, end: 188.35 },
            { label: "s3", start: 188.6, end: 192.87 },
            { label: "19a", start: 193.12, end: 206.03 },
            { label: "19b", start: 206.28, end: 207.83 },
            { label: "20a", start: 208.08, end: 212.67 },
            { label: "20b", start: 212.92, end: 216.85 },
            { label: "21a", start: 217.1, end: 218.77 },
            { label: "21b", start: 219.02, end: 220.27 },
            { label: "21c", start: 220.52, end: 224.91 },
            { label: "22a", start: 225.16, end: 229.89 },
            { label: "22b", start: 230.14, end: 231.87 },
            { label: "23", start: 232.12, end: 241.25 },
            { label: "s4", start: 241.5, end: 245.09 },
            { label: "24", start: 245.34, end: 253.97 },
            { label: "25a", start: 254.22, end: 257.15 },
            { label: "25b", start: 257.4, end: 258.89 },
            { label: "25c", start: 259.14, end: 261.09 },
            { label: "25d", start: 261.34, end: 271.53 },
            { label: "26a", start: 271.78, end: 278.95 },
            { label: "26b", start: 279.2, end: 285.41 },
            { label: "26c", start: 285.66, end: 287.09 },
            { label: "27a", start: 287.34, end: 289.29 },
            { label: "27b", start: 289.54, end: 293.05 },
            { label: "27c", start: 293.3, end: 297.45 },
            { label: "27d", start: 297.7, end: 300.69 },
            { label: "28a", start: 300.94, end: 302.21 },
            { label: "28b", start: 302.46, end: 303.51 },
            { label: "28c", start: 303.76, end: 304.57 },
            { label: "29a", start: 304.82, end: 306.03 },
            { label: "29b", start: 306.28, end: 309.15 },
            { label: "29c", start: 309.4, end: 314.81 },
            { label: "s5", start: 315.06, end: 318.23 },
            { label: "30", start: 318.48, end: 325.47 },
            { label: "31", start: 325.72, end: 340.31 }
        ],
        "ch21":[
            { label: "s1", start: 0, end: 3.89 },
            { label: "1a", start: 4.14, end: 13.33 },
            { label: "1b", start: 13.58, end: 16.23 },
            { label: "2", start: 16.48, end: 28.93 },
            { label: "3a", start: 29.18, end: 31.51 },
            { label: "3b", start: 31.76, end: 33.91 },
            { label: "3c", start: 34.16, end: 35.21 },
            { label: "3d", start: 35.46, end: 36.83 },
            { label: "3e", start: 37.08, end: 40.73 },
            { label: "3f", start: 40.98, end: 45.53 },
            { label: "4", start: 45.78, end: 53.97 },
            { label: "5a", start: 54.22, end: 55.25 },
            { label: "5b", start: 55.5, end: 58.33 },
            { label: "5c", start: 58.58, end: 59.51 },
            { label: "5d", start: 59.76, end: 60.33 },
            { label: "6a", start: 60.58, end: 61.33 },
            { label: "6b", start: 61.58, end: 65.75 },
            { label: "6c", start: 66, end: 73.09 },
            { label: "7a", start: 73.34, end: 77.51 },
            { label: "7b", start: 77.76, end: 79.65 },
            { label: "7c", start: 79.9, end: 90.07 },
            { label: "8a", start: 90.32, end: 100.17 },
            { label: "8b", start: 100.42, end: 104.67 },
            { label: "9", start: 104.92, end: 114.77 },
            { label: "10a", start: 115.02, end: 116.55 },
            { label: "10b", start: 116.8, end: 119.85 },
            { label: "11a", start: 120.1, end: 126.51 },
            { label: "11b", start: 126.76, end: 131.91 },
            { label: "11c", start: 132.16, end: 137.25 },
            { label: "12a", start: 137.5, end: 138.97 },
            { label: "12b", start: 139.22, end: 140.63 },
            { label: "12c", start: 140.88, end: 145.13 },
            { label: "12d", start: 145.38, end: 148.55 },
            { label: "13", start: 148.8, end: 154.41 },
            { label: "14", start: 154.66, end: 159.51 },
            { label: "s2", start: 159.76, end: 163.31 },
            { label: "15a", start: 163.56, end: 167.17 },
            { label: "15b", start: 167.42, end: 173.13 },
            { label: "15c", start: 173.38, end: 174.53 },
            { label: "15d", start: 174.78, end: 175.87 },
            { label: "15e", start: 176.12, end: 178.41 },
            { label: "15f", start: 178.66, end: 180.07 },
            { label: "15g", start: 180.32, end: 183.11 },
            { label: "16a", start: 183.36, end: 186.03 },
            { label: "16b", start: 186.28, end: 190.39 },
            { label: "16c", start: 190.64, end: 191.75 },
            { label: "16d", start: 192, end: 192.95 },
            { label: "16e", start: 193.2, end: 194.55 },
            { label: "16f", start: 194.8, end: 195.97 },
            { label: "16g", start: 196.22, end: 199.01 },
            { label: "17a", start: 199.26, end: 201.13 },
            { label: "17b", start: 201.38, end: 205.35 },
            { label: "17c", start: 205.6, end: 210.45 },
            { label: "17d", start: 210.7, end: 212.65 },
            { label: "17e", start: 212.9, end: 213.63 },
            { label: "17f", start: 213.88, end: 215.51 },
            { label: "17g", start: 215.76, end: 217.55 },
            { label: "17h", start: 217.8, end: 219.01 },
            { label: "17i", start: 219.26, end: 222.01 },
            { label: "18a", start: 222.26, end: 229.23 },
            { label: "18b", start: 229.48, end: 239.29 },
            { label: "19a", start: 239.54, end: 246.99 },
            { label: "19b", start: 247.24, end: 249.63 },
            { label: "19c", start: 249.88, end: 251.55 },
            { label: "20a", start: 251.8, end: 260.83 },
            { label: "20b", start: 261.08, end: 266.81 },
            { label: "20c", start: 267.06, end: 269.97 },
            { label: "21a", start: 270.22, end: 273.49 },
            { label: "21b", start: 273.74, end: 275.09 },
            { label: "22a", start: 275.34, end: 276.81 },
            { label: "22b", start: 277.06, end: 281.43 },
            { label: "22c", start: 281.68, end: 283.91 },
            { label: "23a", start: 284.16, end: 294.13 },
            { label: "23b", start: 294.38, end: 299.97 },
            { label: "24", start: 300.22, end: 308.29 },
            { label: "s3", start: 308.54, end: 310.45 },
            { label: "25", start: 310.7, end: 323.15 }
        ]
    },
    "/GMV/GMV-45-ACT.html": {
        "ch1":[],
        "ch2":[],
        "ch3":[],
        "ch4":[],
        "ch5":[],
        "ch6":[],
        "ch7":[],
        "ch8":[],
        "ch9":[],
        "ch10":[],
        "ch11":[],
        "ch12":[],
        "ch13":[],
        "ch14":[],
        "ch15":[],
        "ch16":[],
        "ch17":[],
        "ch18":[],
        "ch19":[],
        "ch20":[],
        "ch21":[],
        "ch22":[],
        "ch23":[],
        "ch24":[],
        "ch25":[],
        "ch26":[],
        "ch27":[],
        "ch28":[]
    }
}