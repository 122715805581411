//PolyFills
import "core-js-bundle";
import "whatwg-fetch";

import * as caching from "/modules/caching";
import * as search from "/modules/search";
import { bookSelector, toggleChapters } from "/modules/bookSelector.js";
import { checkURL, getChapterLink } from "./modules/checkURL";
import { getCommentary } from "./modules/getCommentary";
import { showNextPrevButtons } from "./modules/showNextPrevButtons";
import { landingPageSwitcher } from "./modules/landingPageSwitcher";
import {
  toggleClass,
  hasClass,
  addClass,
  removeClass
} from "./modules/helperFunctions";
//import { highlightVerse } from "./modules/highlightVerse";
import { toggleNote } from "./modules/toggleNote";
import { minimizeAudioButton } from "./modules/audio";
if (window.location.pathname.includes("landing")) {
  landingPageSwitcher();
} else {
  if ("serviceWorker" in navigator) {
    caching.ServiceWorker();
  } 
}

/***
 *
 * Toggle Book and chapter selectors
 *
 ***/
let bookSelectorSelector = document.querySelector("#bookListToggle");
let bookSelectorOff = document.querySelector("#closeBookSelector");
if (bookSelectorSelector) {
  bookSelectorSelector.addEventListener("click", bookSelector, false);
  bookSelectorOff.addEventListener("click", bookSelector, false);
}

var bookLinks = document.querySelectorAll(".bookLink");
bookLinks.forEach(function(bookLink) {
  bookLink.addEventListener("click", toggleChapters, false);
});
/***
 *
 * Set up all event Listeners
 *
 ***/
window.onload = checkURL();
window.addEventListener("hashchange", function(e) {
  checkURL();
  getCommentary();
});

//check if this is launching in standalone mode because it is installed on the home screen.
if (
  window.matchMedia("(display-mode: standalone)").matches ||
  window.navigator.standalone === true
) {
  // ga("set", "campaignName", "C2-2020");
  // ga("set", "campaignSource", "Psyphon");
  // ga("set", "campaignMedium", "homescreen");
}

//get the ChapterLists and the chapter Links then loop through to add the event listener to each link.
var chapterLists = document.querySelectorAll(".chapterList");
chapterLists.forEach(function(chapterList) {
  var chapterLinks = chapterList.querySelectorAll("li a");
  chapterLinks.forEach(function(chapterLink) {
    // console.log(chapterLists);
    chapterLink.addEventListener("click", getChapterLink, false);
  });
});

var prevButton = document.querySelector(".prev");
var nextButton = document.querySelector(".next");
var nextButton_Commentary = document.querySelector(".commentaryChapterButton");
if (prevButton) {
  prevButton.addEventListener("click", getChapterLink, false);
}

if (nextButton) {
  nextButton.addEventListener("click", getChapterLink, false);
}

if (nextButton_Commentary) {
  nextButton_Commentary.addEventListener("click", getChapterLink, false);
}

/***
 *
 * Show Next/Prev on scroll
 *
 ***/

var ticking = false;

window.addEventListener("scroll", function(e) {
  if (!ticking) {
    window.requestAnimationFrame(function() {
      let scroll_pos = window.scrollY
      showNextPrevButtons(scroll_pos);
      minimizeAudioButton(scroll_pos);
      ticking = false;
    });
  }
  ticking = true;
});

/**
 * Save location
 */
export function saveLocation(url) {
  // console.log(url);
  localStorage.setItem("previousLocation", url);
}

/**
 * Nav Drawer
 */
// console.log("Nav Drawer Setup function has run");
var drawer = document.querySelector("#demo-drawer");
var menuButton = document.querySelector(".hamburger-menu");
var scrim = document.querySelector(".mdc-drawer-scrim");

var cssClasses = {
  ROOT: "mdc-drawer",
  DISMISSIBLE: "mdc-drawer--dismissible",
  MODAL: "mdc-drawer--modal",
  OPEN: "mdc-drawer--open",
  ANIMATE: "mdc-drawer--animate",
  OPENING: "mdc-drawer--opening",
  CLOSING: "mdc-drawer--closing"
};

menuButton.addEventListener("click", function() {
  // console.log("hamburger clicked");
  // drawer.classList.add(cssClasses.ANIMATE);
  toggleClass(menuButton, "animate");
  drawer.classList.remove(cssClasses.CLOSING);
  drawer.classList.add(cssClasses.OPEN);
  drawer.classList.add(cssClasses.OPENING);
});

scrim.addEventListener("click", function() {
  toggleClass(menuButton, "animate");
  drawer.classList.add(cssClasses.CLOSING);
  drawer.classList.remove(cssClasses.OPENING);
  drawer.classList.remove(cssClasses.OPEN);
});
