import { removeClass, addClass } from "./helperFunctions";
/**
 * Commentary
 */
export function getCommentary() {
  // console.log("getCommentary");
  var commentarySection = document.querySelector(".commentary");
  // console.log("getting Commentary");
  fetch("../commentary.json")
    .then(function(response) {
      return response.json();
    })
    .then(function(commentary) {
      if (document.location.pathname !== "/") {
        var thisBook = document.location.pathname;
      } else {
        var thisBook = "/GMV/GMV-44-JHN.html";
      }
      var chapterHash = document.location.hash;
      if (chapterHash.includes(".")) {
        chapterHash = chapterHash.split('.')[0];
      }
      if (document.location.hash === "") {
        chapterHash = "#ch1";
      }
      var commentaryData = commentary[thisBook.split("/").pop()][chapterHash];
      if (commentaryData.content) {
        document.querySelector(".commentary .commentary-content").innerHTML =
          commentaryData.content;
        removeClass(commentarySection, "hide");
      } else {
        document.querySelector(".commentary .commentary-content").innerHTML =
          "";
        addClass(commentarySection, "hide");
      }
    });
}
