import {
  addClass,
  removeClass,
  toggleClass,
  hasClass
} from "./helperFunctions";
/***
 *
 * OnBoarding Stuff
 *
 */
//Check localStorage for onboarding, and if the onboarding screens have not been completed or canceled show it.
export function onboard(args) {
  // console.log("onboarding Fired");
  // console.log(args);
  if (!localStorage.onboarding_book && args.book) {
    // // console.log("onboarding not complete");
    // var onboardingModal = document.querySelector("#onboarding");
    // removeClass(onboardingModal, "none");

    // //this uses checks the argument to see if we should show the onboarding modal about the books of the bible or about the chat button.
    // if (args.book) {
    //   addClass(onboardingModal, "books");
    // }
    // var button = onboardingModal.querySelector(".onboarding-next");
    // onboardingModal.addEventListener("click", onboardingCancel(), false);
    // button.addEventListener("click", onboardingModalToggle(), false);
  } else {
    var onboardingModal = document.querySelector("#onboarding");
    if (onboardingModal) {
      removeClass(onboardingModal, onboardingModal.className);
      addClass(onboardingModal, "none");
    }
  }

  function onboardingCancel() {
    return function(event) {
      event.stopPropagation();
      // console.log("modal closed");
      if (args.book) {
        localStorage.setItem("onboarding_book", "canceled");
      }

      removeClass(onboardingModal, onboardingModal.className);
      addClass(onboardingModal, "onboarded");
    };
  }

  function onboardingModalToggle() {
    return function(event) {
      event.stopPropagation();
      if (args.book) {
        localStorage.setItem("onboarding_book", "complete");
      } else if (args.chat) {
        localStorage.setItem("onboarding_chat", "complete");
      }
      removeClass(onboardingModal, onboardingModal.className);
      addClass(onboardingModal, "none");
    };
  }
}
export function ChatonBoardingCheck() {
  // console.log("checking Pageviews");
  if ((localStorage.pageViews = (+localStorage.pageViews || 0) + 1) == 2) {
    onboard({ chat: true });
  }
  if (localStorage.pageViews % 10 === 0) {
    // console.log("multiples of 10 chapters read");
    // ga(
    //   "send",
    //   "event",
    //   "10 chapters read",
    //   "10 chapters read",
    //   "Multiple of 10 Chapters Read",
    //   localStorage.pageViews
    // );
  }
}
